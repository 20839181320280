import produce from "immer";
import { WizardStep } from "../constants";

import {
  SHOP_ID_LOADED,
  FETCH_SHOP_DETAILS_PENDING,
  FETCH_SHOP_DETAILS_FULFILLED,
  BACK_TO_DETAILS,
  CONTINUE_TO_CONTACT,
  CONTINUE_TO_VEHICLE_AND_SERVICE,
  BACK_TO_VEHICLE,
  APPOINTMENT_TYPE_CHANGED,
  STEP_CHANGED,
  DATE_CHANGED,
  DATE_RESET,
  TIME_CHANGED,
  MONTH_CHANGED,
  VEHICLE_VALUE_CHANGED,
  FETCH_VEHICLE_YEARS_PENDING,
  FETCH_VEHICLE_YEARS_FULFILLED,
  FETCH_VEHICLE_MAKES_PENDING,
  FETCH_VEHICLE_MAKES_FULFILLED,
  FETCH_VEHICLE_MODELS_PENDING,
  FETCH_VEHICLE_MODELS_FULFILLED,
  FETCH_SERVICES_PENDING,
  FETCH_SERVICES_FULFILLED,
  FETCH_TIME_SLOTS_PENDING,
  FETCH_TIME_SLOTS_FULFILLED,
  FORM_UPDATED,
  REQUEST_APPOINTMENT_PENDING,
  REQUEST_APPOINTMENT_SUCCESS,
  FETCH_TIME_FOR_MONTH,
  COMMENT_ADDED,
  ADD_SERVICE,
  REMOVE_SERVICE,
  INIT,
  REQUEST_APPOINTMENT_ERROR,
  REDIRECT_TO_SUCCESS_PAGE,
  SHOW_LOCATION,
  FETCH_ALL_SHOP_DETAILS_FULFILLED,
  FETCH_ALL_SHOP_DETAILS_PENDING,
  SHOP_ID_CHANGED,
  SHOW_FORM_ERROR,
  REDIRECT_TO_ERROR_PAGE,
  SHOW_APPOINTMENT,
  SHOW_REVIEWS,
  FETCH_WIDGET_SETTINGS_PENDING,
  FETCH_WIDGET_SETTINGS_FULFILLED,
  FETCH_WIDGET_SETTINGS_ERROR,
  CONTINUE_TO_APPOINTMENT,
  TOGGLE_APPOINTMENT_SUMMARY,
  SAVE_SELECTED_CUSTOMER,
  SAVE_LOOKUP_CUSTOMER,
  SET_DISCLAIMER_TEXT_WITH_TOKENS,
  SET_VEHICLES_LIST,
  SET_VEHICLE,
  SET_CONTACT_INFO,
  SET_GUEST_FLOW,
  MATCH_CUSTOMERS_FULFILLED,
  SET_NEW_VEHICLE_SELECTED,
  SET_VERIFICATION_METHOD,
  DECRYPT_TOKEN_PENDING,
  DECRYPT_TOKEN_FULFILLED,
  DECRYPT_TOKEN_ERROR,
  HIDE_CONTACT_CONSENT, SAVE_CUSTOMER_DATA_TO_VERIFY,
} from "./actions";

export function reducer(prevState, action) {
  const newState = produce(prevState, (state) => {
    const { type, payload } = action;
    switch (type) {
      case SHOW_APPOINTMENT:
        state.isShowingAppointment = true;
        state.isShowingReviews = false;
        break;
      case SHOW_REVIEWS:
        state.isShowingAppointment = false;
        state.isShowingReviews = true;
        break;
      case SHOW_LOCATION:
        state.isLocationShowing = true;
        break;
      case INIT:
        console.log("init");
        break;
      case SHOP_ID_LOADED: {
        state.shopId = payload.shopId;
        state.availableShopIds = payload.shops;
        break;
      }
      case SHOP_ID_CHANGED: {
        state.shopId = payload.shopId;
        state.isLocationShowing = false;
        break;
      }
      case FETCH_SHOP_DETAILS_PENDING:
        state.shopDetails = null;
        state.shopOptions.timeSlots = [];
        state.areSlotsLoaded = false;
        state.areServicesLoaded = false;
        state.areShopDetailsLoaded = false;
        state.areShopDetailsLoading = true;
        break;
      case FETCH_SHOP_DETAILS_FULFILLED:
        state.shopDetails = payload.shopDetails;
        state.areShopDetailsLoaded = true;
        state.areShopDetailsLoading = false;
        break;
      case FETCH_ALL_SHOP_DETAILS_PENDING:
        state.areLocationShopDetailsLoading = true;
        break;
      case FETCH_ALL_SHOP_DETAILS_FULFILLED:
        state.availableShopDetails = payload.shopDetails;
        state.areLocationShopDetailsLoading = false;
        break;
      case STEP_CHANGED: {
        state.step = payload.step;
        break;
      }
      case VEHICLE_VALUE_CHANGED: {
        const { field, value } = payload;
        const { vehicle } = state.appointmentDetails;
        if (field === "year" || field === "make") {
          vehicle.make = null;
          vehicle.model = null;
        }
        if (field === "year") {
          state.isMakeFieldDisabled = false;
          state.isModelFieldDisabled = true;
        }
        if (field === "make") {
          state.isModelFieldDisabled = false;
        }
        state.appointmentDetails.vehicle[field] = value;
        break;
      }
      case DATE_CHANGED:
        state.appointmentDetails.dateTime.date = payload.date;
        break;
      case DATE_RESET:
        delete state.appointmentDetails.dateTime.date;
        delete state.appointmentDetails.dateTime.timeSlot;
        break;
      case TIME_CHANGED:
        state.appointmentDetails.dateTime.timeSlot = payload.timeSlot;
        break;
      case COMMENT_ADDED:
        state.appointmentDetails.servicesComments[payload.comments.id] = payload.comments.value;
        break;
      case ADD_SERVICE:
        state.appointmentDetails.services.push(payload.id);
        break;
      case REMOVE_SERVICE:
        const filteredServices = state.appointmentDetails.services.filter(
          (id) => id !== payload.id,
        );
        state.appointmentDetails.services = filteredServices;
        break;
      case APPOINTMENT_TYPE_CHANGED:
        state.appointmentDetails.appointmentType = payload.type;
        break;
      case MONTH_CHANGED:
        state.appointmentDetails.selectedMonth = payload.month;
        break;
      case FETCH_TIME_FOR_MONTH:
        state.shopOptions.timeSlots = payload.timeSlots.AvailableIntervals;
        break;
      case CONTINUE_TO_APPOINTMENT:
        state.step = WizardStep.Appointment;
        state.isError = false;
        state.isLoadingAppointmentRequest = false;
        break;
      case CONTINUE_TO_CONTACT:
        state.step = WizardStep.Contact;
        state.isError = false;
        state.isLoadingAppointmentRequest = false;
        break;
      case CONTINUE_TO_VEHICLE_AND_SERVICE:
        state.step = WizardStep.Vehicle;
        break;
      case BACK_TO_DETAILS:
        state.step = WizardStep.Appointment;
        state.isLoadingAppointmentRequest = false;
        break;
      case BACK_TO_VEHICLE:
        state.step = WizardStep.Vehicle;
        break;
      case FETCH_VEHICLE_YEARS_PENDING:
        // TODO: loading state
        break;
      case FETCH_VEHICLE_YEARS_FULFILLED:
        state.shopOptions.vehicle.years = payload.years.map((year) => ({
          value: year,
          label: year,
        }));
        break;
      case FETCH_VEHICLE_MAKES_PENDING:
        state.shopOptions.vehicle.models = [];
        state.areMakesLoaded = false;
        break;
      case FETCH_VEHICLE_MAKES_FULFILLED:
        state.shopOptions.vehicle.makes = payload.makes.map((make) => ({
          value: make,
          label: make,
        }));
        state.areMakesLoaded = true;
        break;
      case FETCH_VEHICLE_MODELS_PENDING:
        state.shopOptions.vehicle.models = [];
        state.areModelsLoaded = false;
        break;
      case FETCH_VEHICLE_MODELS_FULFILLED:
        state.shopOptions.vehicle.models = payload.models.map((model) => ({
          value: model,
          label: model,
        }));
        state.areModelsLoaded = true;
        break;
      case FETCH_SERVICES_PENDING:
        state.areServicesLoaded = false;
        break;
      case FETCH_SERVICES_FULFILLED:
        state.shopOptions.services = payload.services;
        state.areServicesLoaded = true;
        break;
      case FETCH_TIME_SLOTS_PENDING:
        state.shopOptions.timeSlots = [];
        state.areSlotsLoaded = false;
        break;
      case FETCH_TIME_SLOTS_FULFILLED:
        state.areSlotsLoaded = true;
        break;
      case REQUEST_APPOINTMENT_PENDING:
        state.isLoadingAppointmentRequest = true;
        break;
      case REQUEST_APPOINTMENT_SUCCESS:
        state.isLoadingAppointmentRequest = false;
        break;
      case REQUEST_APPOINTMENT_ERROR:
        state.isLoadingAppointmentRequest = false;
        break;
      case REDIRECT_TO_SUCCESS_PAGE:
        state.isFinished = true;
        break;
      case REDIRECT_TO_ERROR_PAGE:
        state.isError = true;
        break;
      case FORM_UPDATED:
        const { field, value } = payload;
        state.contactInfo[field] = value;
        break;
      case SHOW_FORM_ERROR:
        state.shouldShowValidationErrors = payload.value;
        break;
      case FETCH_WIDGET_SETTINGS_PENDING:
        state.isWidgetSettingsError = false;
        state.areWidgetSettingsLoaded = false;
        break;
      case FETCH_WIDGET_SETTINGS_FULFILLED:
        state.widgetSettings = payload.data;
        state.availableShopIds = payload.data?.shopIds || [];
        state.areWidgetSettingsLoaded = true;
        break;
      case FETCH_WIDGET_SETTINGS_ERROR:
        state.areWidgetSettingsLoaded = true;
        state.isWidgetSettingsError = true;
        break;

      case DECRYPT_TOKEN_PENDING:
        state.isDecryptTokenError = false;
        state.isDecryptTokenLoaded = false;
        break;
      case DECRYPT_TOKEN_FULFILLED:
        state.clusterId = payload.data?.clusterId;
        state.tenantId = payload.data?.tenantId;
        state.shopId = payload.data?.shopId;
        state.customerId = payload.data?.customerId;
        state.serviceObjectId = payload.data?.serviceObjectId;
        state.campaignId = payload.data?.campaignId;
        state.campaignName = payload.data?.campaignName;
        state.marketingTypeId = payload.data?.marketingTypeId;
        state.isDecryptTokenLoaded = true;
        break;
      case DECRYPT_TOKEN_ERROR:
        state.isDecryptTokenLoaded = true;
        state.isDecryptTokenError = true;
        break;
      case TOGGLE_APPOINTMENT_SUMMARY:
        state.isAppointmentSummaryExpanded = !state.isAppointmentSummaryExpanded;
        break;

      case SAVE_SELECTED_CUSTOMER:
        state.selectedCustomer = payload;
        break;
      case SAVE_LOOKUP_CUSTOMER:
        state.lookupCustomer = payload;
        break;
      case SAVE_CUSTOMER_DATA_TO_VERIFY:
        state.customerDataToVerify = payload;
        break;
      case SET_NEW_VEHICLE_SELECTED:
        state.isNewVehicle = payload;
        break;
      case MATCH_CUSTOMERS_FULFILLED:
        state.matchedCustomers = payload;
        break;
      case SET_DISCLAIMER_TEXT_WITH_TOKENS:
        state.disclaimerTextWithReplacedTokens = payload;
        break;
      case SET_VEHICLES_LIST:
        state.vehicles = payload;
        break;
      case SET_VEHICLE:
        state.appointmentDetails.vehicle = payload;
        break;
      case HIDE_CONTACT_CONSENT:
        state.isContactConsentShown = false;
        break;
      case SET_CONTACT_INFO:
        state.appointmentDetails.contactInfo = payload;
        break;
      case SET_GUEST_FLOW:
        state.isGuestFlow = payload;
        break;
      case SET_VERIFICATION_METHOD:
        state.otpVerificationChannel = payload;
        break;

      default:
        console.warn(`Unknown action type: ${type}`);
    }
  });

  return newState;
}
