import React, { Fragment, useContext } from "react";
import cn from "classnames";
import styles from "./WizardStepper.scss";
import Step from "./components/Step";
import PropTypes from "prop-types";
import { WizardContext } from "../../scenes/Wizard/Wizard";

export default function WizardStepper({ stepsCount = 3 }) {
  const { changeStep, activeStep, steps } = useContext(WizardContext);

  const handleStepClick = (index) => {
    const stepIndex = steps.findIndex(
      ({ headerWizardPosition, shouldBeIgnored }) =>
        !shouldBeIgnored && headerWizardPosition === index,
    );
    changeStep(stepIndex);
  };

  return (
    <>
      <div id="wizardStepper" className={styles.wizardStepper}>
        {Array(stepsCount)
          .fill("")
          .map((step, index) => {
            const currentIndex = index + 1;
            return (
              <Fragment key={index}>
                {index !== 0 && (
                  <div
                    className={cn(styles.line, {
                      [styles.highlighted]: currentIndex <= activeStep.headerWizardPosition,
                    })}
                  />
                )}

                <Step
                  onClick={() => handleStepClick(currentIndex)}
                  value={currentIndex}
                  isAvailable={index < activeStep.headerWizardPosition}
                  isCurrent={activeStep.headerWizardPosition === currentIndex}
                />
              </Fragment>
            );
          })}
      </div>
    </>
  );
}

WizardStepper.propTypes = {
  stepsCount: PropTypes.number,
};
