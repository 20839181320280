import React, { Fragment } from "react";
import cn from "classnames";

import { CharactersCounter } from "./components";
import styles from "./InputWrapper.scss";
import Typography from "../../Typography/Typography";
import { CrossIcon } from "../../../svg-icons";

const InputWrapper = (props) => {
  const {
    children: input,
    label,
    value,
    disabled,
    isError,
    additionalLabel,
    withButton,
    buttonComponent,
    withInformationBadge,
    informationBadgeComponent,
    isCharacterCounter,
    maxCharactersCount = 50,
    labelClassName,
    characterCounterClassName,
    onClear,
    className,
    closeIconClassName,
  } = props;

  return (
    <div className={cn(styles.InputWrapper, className)}>
      {label && (
        <div className={cn(styles.InputWrapper__controls)}>
          <Typography
            color="tertiary"
            fontWeight="semibold"
            className={cn(labelClassName, {
              [styles.InputWrapper__label_disabled]: disabled,
            })}
          >
            {label}
          </Typography>
          {additionalLabel && (
            <span className={styles["InputWrapper__additionalLabel"]}>({additionalLabel})</span>
          )}
          {withButton && <div className={styles.buttonComponent}>{buttonComponent}</div>}
          {withInformationBadge && (
            <span className={styles.InputWrapper__information}>{informationBadgeComponent}</span>
          )}
          {isCharacterCounter && (
            <CharactersCounter
              maxCharactersCount={maxCharactersCount}
              value={value}
              className={characterCounterClassName}
            />
          )}
        </div>
      )}
      {input}
      {value && onClear && (
        <CrossIcon
          className={cn(styles.closeIcon, closeIconClassName, {
            [styles.closeIconMoved]: isError,
          })}
          onClick={onClear}
        />
      )}
    </div>
  );
};

export default InputWrapper;
