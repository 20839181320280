import styles from "./GroupedWorkingHours.scss";
import { DAYS_NAME } from "../../../../../../../../constants";
import { formatHoursToAmPm } from "../../../../../../../../utils";
import React from "react";

const GroupedWorkingHours = ({ groupedDays }) =>
  groupedDays.map((day) => {
    const closed = !day.timeIntervals.length;

    return (
      <div key={day.dayOfWeekFrom}>
        {!closed && (
          <div className={styles.dayHours}>
            <div>
              {day.dayOfWeekFrom === day.dayOfWeekTo
                ? DAYS_NAME[day.dayOfWeekFrom]
                : `${DAYS_NAME[day.dayOfWeekFrom]} - ${DAYS_NAME[day.dayOfWeekTo]}`}
            </div>
            {day.timeIntervals.map((slot) => {
              return (
                <div key={slot.from}>
                  {formatHoursToAmPm(slot.from)} - {formatHoursToAmPm(slot.to)}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  });

export default GroupedWorkingHours;
