import React, { useEffect, useState } from "react";
import styles from "./VehiclesList.scss";
import Typography from "../../../../components/Typography/Typography";
import SearchInput from "../../../../components/Inputs/Searchinput/SearchInput";
import SelectCard from "../../../../components/SelectCard/SelectCard";
import VehicleImage from "../../../../components/VehicleImage/VehicleImage";
import { ErrorIcon, PlusCircleIcon } from "../../../../svg-icons";
import { VehiclesSkeleton } from "../../../../components/Skeletons/Skeletons";
import ReactPlaceholder from "react-placeholder";
import PropTypes from "prop-types";
import RequestResult from "../../../../components/RequestResult/RequestResult";
import { fetchCustomerVehicles } from "../../../../services/mechanic-api.client";
import useAsync from "../../../../hooks/useAsync";
import { getLookupCustomer, getVehicles, setVehiclesList, useRedux } from "../../../../redux";
import { debounce, numberWithCommas } from "../../../../utils";
import { format } from "date-fns";
const debouncedSearch = debounce((fn, args) => fn(args), 1000);

const VehiclesList = ({ onOpenNewVehicle, onSelectVehicle, store }) => {
  const [state, dispatch] = useRedux(store);
  const customer = getLookupCustomer(state);
  const selectedVehicle = state.appointmentDetails.vehicle;
  const [searchValue, setSearchValue] = useState("");
  const vehicles = getVehicles(state);
  const [isFirstFetch, setFirstFetch] = useState(true);
  const { firstName, companyName } = customer;
  const customerName = companyName || firstName;

  const handleSelectVehicle = (vehicle) => {
    onSelectVehicle(vehicle);
  };

  const handleFetchVehicles = async (searchValue) => {
    const result = await fetchCustomerVehicles({
      customerId: customer.customerId,
      searchQuery: searchValue,
    });
    dispatch(setVehiclesList(result.data?.serviceObjects));
  };

  const { execute, isLoading, errorStatusCode } = useAsync({ asyncFunction: handleFetchVehicles });

  useEffect(() => {
    if (!isFirstFetch) {
      debouncedSearch(execute, searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isFirstFetch) {
      execute();
      setFirstFetch(false);
    }
  }, []);

  if (errorStatusCode) {
    return (
      <div className={styles.errorState}>
        <RequestResult
          title="Something Went Wrong"
          message="Please refresh the page or contact support."
          image={<ErrorIcon />}
        />
        <div className={styles.retryButton}>Try again</div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <div>
          <Typography fontWeight="bold" fontSize="xl">
            Welcome Back{customerName ? "," : ""} {customerName}!
          </Typography>
          <Typography color="tertiary">Please, select your vehicle.</Typography>
        </div>
        <SearchInput
          placeholder="Search..."
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onCloseClick={() => setSearchValue("")}
        />
      </div>

      <ReactPlaceholder ready={!isLoading} customPlaceholder={VehiclesSkeleton()}>
        <div className={styles.vehiclesList}>
          {vehicles.map((vehicle) => {
            const { displayName, odometer, id, lastServiceAt } = vehicle;

            return (
              <SelectCard
                key={id}
                isSelected={selectedVehicle?.serviceObjectId === id}
                onSelect={() => handleSelectVehicle(vehicle)}
                className={styles.vehicleCard}
              >
                <VehicleImage vehicle={vehicle} width={64} height={64} />
                <div className={styles.vehicleInfo}>
                  <Typography
                    fontWeight="bold"
                    fontSize="xl"
                    className={styles.vehicleName}
                    ellipsis={true}
                  >
                    {displayName}
                  </Typography>
                  <Typography color="tertiary" className={styles.vehicleInfoRow}>
                    <span>Odometer</span>
                    <Typography tag="span" color="tertiary" fontWeight="bold">
                      {odometer ? numberWithCommas(odometer) : "-"}
                    </Typography>
                  </Typography>
                  <Typography color="tertiary" className={styles.vehicleInfoRow}>
                    <span>Last visit</span>
                    <Typography tag="span" color="tertiary" fontWeight="bold">
                      {lastServiceAt ? format(new Date(lastServiceAt), "MMM d, yyyy") : "-"}
                    </Typography>
                  </Typography>
                </div>
              </SelectCard>
            );
          })}
          <div className={styles.addVehicle} onClick={onOpenNewVehicle}>
            <PlusCircleIcon />
            <Typography fontWeight="bold">Add a New Vehicle</Typography>
          </div>
        </div>
      </ReactPlaceholder>
    </div>
  );
};

VehiclesList.propTypes = {
  onSelectVehicle: PropTypes.object,
  onOpenNewVehicle: PropTypes.func,
};

export default VehiclesList;
