import React, { useEffect, useState } from "react";
import SelectCard from "../../../../../../components/SelectCard/SelectCard";
import styles from "../../SelectProfile.scss";
import Typography from "../../../../../../components/Typography/Typography";
import { CarIcon, EmailIcon2, PhoneIcon2 } from "../../../../../../svg-icons";
import { getLookupCustomer, useRedux } from "../../../../../../redux";
import { fetchCustomerVehicles } from "../../../../../../services/mechanic-api.client";
import useAsync from "../../../../../../hooks/useAsync";
import PropTypes from "prop-types";
import { ProfileSkeleton } from "../../../../../../components/Skeletons/Skeletons";
import ReactPlaceholder from "react-placeholder";

const ProfileCard = ({ customer, store, onSelect }) => {
  const [state] = useRedux(store);
  const lookupCustomer = getLookupCustomer(state);
  const lookupCustomerId = lookupCustomer?.customerId;
  const { primaryEmail, primaryPhoneNumber, customerId } = customer;
  const [vehiclesCount, setVehiclesCount] = useState(0);
  const [lastVisitVehicleName, setLastVisitVehicleName] = useState("");

  const handleFetchVehicles = async () => {
    const result = await fetchCustomerVehicles({ customerId });
    const { serviceObjects = [] } = result.data;
    if (serviceObjects?.length) {
      setVehiclesCount(serviceObjects?.length);
      const lastVisitVehicle = serviceObjects.reduce((a, b) => {
        return new Date(a.lastServiceAt) > new Date(b.lastServiceAt) ? a : b;
      });
      setLastVisitVehicleName(lastVisitVehicle.displayName);
    }
  };

  const { execute, isLoading } = useAsync({ asyncFunction: handleFetchVehicles });

  useEffect(() => {
    execute();
  }, []);

  return (
    <ReactPlaceholder ready={!isLoading} customPlaceholder={ProfileSkeleton()}>
      <SelectCard
        isSelected={lookupCustomerId === customerId}
        className={styles.card}
        onSelect={() => onSelect(customer)}
      >
        <div className={styles.infoItem}>
          <EmailIcon2 width={16} height={16} />
          <Typography color="tertiary">{primaryEmail || "-"}</Typography>
        </div>
        <div className={styles.infoItem}>
          <PhoneIcon2 width={16} height={16} />
          <Typography color="tertiary">{primaryPhoneNumber || "-"}</Typography>
        </div>
        <div className={styles.infoItem}>
          <CarIcon width={16} height={16} />
          <div>
            <Typography color="tertiary">
              {vehiclesCount} {vehiclesCount === 1 ? "Vehicle" : "Vehicles"}
            </Typography>
            {vehiclesCount > 0 ? (
              <Typography color="tertiary">{lastVisitVehicleName} (last visit)</Typography>
            ) : null}
          </div>
        </div>
      </SelectCard>
    </ReactPlaceholder>
  );
};

ProfileCard.propTypes = {
  customer: PropTypes.object,
  store: PropTypes.object,
  onSelect: PropTypes.func,
};

export default ProfileCard;
