import React from "react";
import cn from "classnames";
import { YellowAttentionIcon } from "../../svg-icons";
import Typography from "../Typography/Typography";
import styles from "./Alert.scss";
import { PROP_TYPES_TEMPLATES } from "../../constants";

const Alert = ({ title, description, className }) => {
  return (
    <div className={cn(styles.alert, className)}>
      <YellowAttentionIcon />
      <div>
        <Typography color="warning" fontWeight="bold" fontSize="lg">
          {title}
        </Typography>
        <Typography color="warning">{description}</Typography>
      </div>
    </div>
  );
};

Alert.propTypes = {
  title: PROP_TYPES_TEMPLATES.TITLE,
  description: PROP_TYPES_TEMPLATES.TITLE,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default Alert;
