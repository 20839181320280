import { ENV_TYPES } from "../constants";

const isDev = process.env.NODE_ENV === "development";

let API_ROOT;
let STEER_API_ROOT;
let DEPLOY_PATH;
let TRACK_JS_KEY;
let TRACK_JS_ENV;
let ENV_TYPE;
let GOOGLE_BOOK_TRACK_URL;
let GOOGLE_BOOK_PARTNER_ID;

if (isDev) {
  API_ROOT = "http://localhost:3001";
  STEER_API_ROOT = "http://localhost:3001";
  DEPLOY_PATH = "http://localhost:3001";
  ENV_TYPE = ENV_TYPES.DEVELOPMENT;
  GOOGLE_BOOK_TRACK_URL = "http://localhost:3001";
  GOOGLE_BOOK_PARTNER_ID = "123";
} else {
  // ___SOME_VARIABLE___ is being replaced when system is deployed running via container.
  // Environment variable is used for development purpose. It's taken from .env or .env.local file
  API_ROOT = "https://widget-api.app.steercrm.com";
  STEER_API_ROOT = "https://app.steercrm.com";
  DEPLOY_PATH = "https://widget.app.steercrm.com";
  TRACK_JS_KEY = "2bb7e633e7994803a9e19b6cebe98f99";
  TRACK_JS_ENV = "steer-production-widget";
  ENV_TYPE = "production";
  GOOGLE_BOOK_TRACK_URL = "https://widget.app.steercrm.com/maps/conversion/collect";
  GOOGLE_BOOK_PARTNER_ID = "20002101";
}

export {
  API_ROOT,
  STEER_API_ROOT,
  DEPLOY_PATH,
  TRACK_JS_KEY,
  TRACK_JS_ENV,
  ENV_TYPE,
  GOOGLE_BOOK_TRACK_URL,
  GOOGLE_BOOK_PARTNER_ID,
};
