import React, { createContext, useEffect, useLayoutEffect, useRef } from "react";
import cn from "classnames";
import {
  getClosePopupOnEsc,
  getEnabledTwoFactorAuthentication,
  getIsWidgetPublicPage,
  getMatchingOnly,
  getWidgetInPopup,
  showAppointmentSummary,
  showShopInfo,
} from "../../services/config.service";
import { useMultistepForm } from "../../hooks/useMultistepForm";
import { generateSteps } from "./utils/stepsGenerator";
import styles from "./Wizard.scss";
import Summary from "../Summary/Summary";
import ShopContacts from "../ShopContacts/ShopContacts";
import Section from "../../components/Section/Section";
import Header from "../../templates/Header/Header";
import Footer from "../../templates/Footer/Footer";
import useWizardPopupHeight from "./hooks/useWizardPopupHeight";
import { onScroll, onScrollAreaTouchStart } from "../../utils/scrollUtils";
import { getEmbeddedIos } from "../../utils";
import { CUSTOM_EVENTS, registerCustomMawEvent } from "../../services/analytics.service";
import { setGuestFlow, useRedux } from "../../redux";
import SuccessPage from "../SuccessPage/SuccessPage";
import ErrorPage from "../ErrorPage/ErrorPage";

export const WizardContext = createContext({});
export const StoreContext = createContext({});

const Wizard = ({ store, msoLocations, onSelectMsoShop }) => {
  const [state, dispatch] = useRedux(store);
  const isWidgetPopup = getWidgetInPopup();
  const isShowShopInfo = showShopInfo();
  const isShowSummary = showAppointmentSummary();
  const isWidgetPublicPage = getIsWidgetPublicPage();
  const isEmbeddedIOS = getEmbeddedIos(isWidgetPublicPage);
  const isClosePopupOnEsc = getClosePopupOnEsc();
  const initialSteps = generateSteps({ store });
  const isEnabledTwoFactorAuthentication = getEnabledTwoFactorAuthentication();
  const isMatchingOnly = getMatchingOnly();

  // Defining popup wizard height is needed to show correct scroll and avoid fixed positions of header and footer
  const popupWizardHeight = useWizardPopupHeight();
  const refWrapper = useRef(null);

  const {
    ActiveStepComponent,
    activeStep,
    next,
    back,
    updateCurrentWizardStep,
    changeStep,
    updateWizardStepById,
    steps,
  } = useMultistepForm({
    initialSteps,
  });

  useEffect(() => {
    if (!!msoLocations?.length) {
      dispatch(setGuestFlow(true));
      return;
    }
    if (isMatchingOnly) {
      dispatch(setGuestFlow(false));
      return;
    }
    if (isEnabledTwoFactorAuthentication) {
      dispatch(setGuestFlow(false));
      return;
    }
    dispatch(setGuestFlow(true));
  }, [isEnabledTwoFactorAuthentication, isMatchingOnly]);

  useEffect(() => {
    if (refWrapper?.current) {
      refWrapper.current.scrollTop = 1;
    }
  }, [refWrapper?.current]);

  useEffect(() => {
    if (isEmbeddedIOS && refWrapper?.current) {
      refWrapper?.current?.addEventListener("scroll", onScroll);
      return () => refWrapper?.current?.removeEventListener("scroll", onScroll);
    }
  }, [refWrapper?.current]);

  useLayoutEffect(() => {
    window.addEventListener("keyup", keyPressHandle);

    return () => {
      window.removeEventListener("keyup", keyPressHandle);
    };
  }, []);

  const keyPressHandle = (event) => {
    if (isClosePopupOnEsc && isWidgetPopup && event.keyCode === 27) {
      registerCustomMawEvent(CUSTOM_EVENTS.hide_widget);
    }
  };

  const isShowAdditionalSections = activeStep?.showSummary || activeStep?.showShopInfo;

  const renderHeader = () => {
    const HeaderComponent = activeStep?.header || Header;

    return <HeaderComponent />;
  };

  const renderFooter = () => {
    return (
      <Footer
        nextButtonLabel={activeStep?.nextButtonLabel}
        backButtonLabel={activeStep?.backButtonLabel}
        backButtonIcon={activeStep?.backButtonIcon}
        summary={activeStep.footerSummary}
        isSuccessIcon={activeStep.isValid}
        isNextDisabled={!activeStep.isValid}
        showBackButton={!!activeStep.showBackButton}
        showNextButton={!!activeStep.showNextButton}
        isLoading={activeStep?.isLoading}
        onBackClick={async () => {
          if (!activeStep.isPreviousStepBlocked) {
            back();
            return;
          }
          updateCurrentWizardStep({
            backButtonClickListener: new Date(),
          });
        }}
        onNextClick={async () => {
          if (activeStep?.onNext) {
            activeStep?.onNext();
          }
          if (!activeStep.isNextStepBlocked) {
            next();
            return;
          }
          updateCurrentWizardStep({
            nextButtonClickListener: new Date(),
          });
        }}
      />
    );
  };

  const renderActiveStep = () => (
    <ActiveStepComponent
      store={store}
      msoLocations={msoLocations}
      onSelectMsoShop={onSelectMsoShop}
    />
  );

  const renderAdditionalSections = () => {
    if (isShowAdditionalSections)
      return (
        <div
          className={cn(styles.additionalSections, {
            [styles.additionalSectionsPopup]: isWidgetPopup,
          })}
        >
          {isShowSummary && activeStep?.showSummary && (
            <Summary store={store} onChangeStep={changeStep} steps={steps} />
          )}
          {isShowShopInfo && activeStep?.showShopInfo && (
            <ShopContacts store={store} onChangeStep={changeStep} steps={steps} />
          )}
        </div>
      );
  };

  const renderView = () => {
    if (isWidgetPublicPage) {
      return (
        <div
          className={cn(styles.wizard, {
            [styles.wizardFlex]: isShowAdditionalSections,
          })}
          id="wizard"
          ref={refWrapper}
          onTouchStart={() => onScrollAreaTouchStart(isEmbeddedIOS)}
        >
          <Section>
            {renderHeader()}
            <div className={styles.stepWrapper}>{renderActiveStep()}</div>
            {renderFooter()}
          </Section>
          {renderAdditionalSections()}
        </div>
      );
    }
    if (isWidgetPopup) {
      return (
        <div className={styles.popupWrapper}>
          {renderHeader()}
          <div
            className={cn(styles.wizard, {
              [styles.popupWizard]: isWidgetPopup,
            })}
            style={{ height: `${popupWizardHeight}px` }}
            id="wizard"
            ref={refWrapper}
            onTouchStart={() => onScrollAreaTouchStart(isEmbeddedIOS)}
          >
            <Section>{renderActiveStep()}</Section>
            {renderAdditionalSections()}
          </div>
          {renderFooter()}
        </div>
      );
    } else {
      return (
        <div className={styles.embeddedWrapper}>
          {renderHeader()}
          <div
            className={cn(styles.wizard, styles.wizardFlexHeight, styles.embeddedWizard, {
              [styles.wizardFlex]: isShowAdditionalSections,
            })}
            id="wizard"
            ref={refWrapper}
            onTouchStart={() => onScrollAreaTouchStart(isEmbeddedIOS)}
          >
            <Section>
              <div className={styles.stepWrapper}>{renderActiveStep()}</div>
            </Section>
            {renderAdditionalSections()}
          </div>
          {renderFooter()}
        </div>
      );
    }
  };

  if (state.isFinished) {
    return <SuccessPage store={store} />;
  }

  if (state.isError) {
    return <ErrorPage onChangeStep={changeStep} steps={steps} />;
  }

  return (
    <StoreContext.Provider value={store}>
      <WizardContext.Provider
        value={{
          activeStep,
          next,
          back,
          updateCurrentWizardStep,
          updateWizardStepById,
          changeStep,
          steps,
          initialSteps,
        }}
      >
        {renderView()}
      </WizardContext.Provider>
    </StoreContext.Provider>
  );
};

export default Wizard;
