import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { v4 } from "uuid";

import { TOAST_TYPES } from "../constants";
import { showToast } from "../components/Toast/Toast";

export const useErrorToast = ({ errorStatusCode, errors, singleError, showWarnings = true }) => {
  const toastId = useRef(v4());
  const isToastActive = toast.isActive(toastId.current);
  const closeToast = () => {
    toast.dismiss(toastId.current);
    toastId.current = v4();
  };

  const handleShowToast = ({ type, message }) => {
    showToast({
      type,
      message,
      toastId: toastId.current,
      autoClose: false,
      onClose: closeToast,
    });
  };

  useEffect(() => {
    if (!isToastActive) {
      toast.clearWaitingQueue();
      if (errorStatusCode >= 500) {
        handleShowToast({
          type: TOAST_TYPES.ERROR,
          message: "Internal server error",
        });
      }

      if (errorStatusCode === 400 && showWarnings) {
        if (singleError) {
          handleShowToast({
            type: TOAST_TYPES.WARNING,
            message: singleError.message,
          });
        } else {
          const errorMessages = [];
          Object.keys(errors).forEach((key) => {
            errorMessages.push(...errors[key]);
          });
          if (errorMessages.length) {
            handleShowToast({
              type: TOAST_TYPES.WARNING,
              message: errorMessages.join(","),
            });
          }
        }
      }
    }
  }, [errorStatusCode, errors, isToastActive, singleError]);

  useEffect(() => {
    return () => {
      closeToast();
    };
  }, []);

  return { closeToast };
};
