// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RequestResult__noDataWrapper--BZ7GF{display:flex;align-items:center;justify-content:center}.RequestResult__noData--w9Hry{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;max-height:420px}.RequestResult__noData--w9Hry .RequestResult__title--gQuxD{font-size:20px;font-weight:700;color:#adb1c9;margin-top:30px;margin-bottom:10px}.RequestResult__noData--w9Hry .RequestResult__message--lqgPy{white-space:pre-line;width:100%;font-size:14px;font-weight:500;line-height:22px;text-align:center;color:#adb1c9}", "",{"version":3,"sources":["webpack://./src/components/RequestResult/RequestResult.scss"],"names":[],"mappings":"AAAA,qCACE,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,8BACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,gBAAA,CAEA,2DACE,cAAA,CACA,eAAA,CACA,aAAA,CACA,eAAA,CACA,kBAAA,CAGF,6DACE,oBAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACA,aAAA","sourcesContent":[".noDataWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.noData {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  max-height: 420px;\n\n  .title {\n    font-size: 20px;\n    font-weight: 700;\n    color: #adb1c9;\n    margin-top: 30px;\n    margin-bottom: 10px;\n  }\n\n  .message {\n    white-space: pre-line;\n    width: 100%;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 22px;\n    text-align: center;\n    color: #adb1c9;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noDataWrapper": "RequestResult__noDataWrapper--BZ7GF",
	"noData": "RequestResult__noData--w9Hry",
	"title": "RequestResult__title--gQuxD",
	"message": "RequestResult__message--lqgPy"
};
export default ___CSS_LOADER_EXPORT___;
