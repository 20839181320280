import styles from "./RepeatRow.scss";

const RepeatRow = ({ number }) => {
  let rows = [];
  for (let i = 0; i < number; i++) {
    rows.push(<div key={i} className={styles.row} />);
  }
  return rows;
};

export default RepeatRow;
