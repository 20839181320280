import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { TrackJS } from "trackjs";
import { reducer, initialState } from "./";
import { raiseEventMiddleware } from "./eventsMiddleware";

const TrackJSLoggerMiddleware = (store) => (next) => (action) => {
  const isDev = process.env.NODE_ENV === "development";
  try {
    // log every action so they appear in the TrackJS telemetry timeline
    TrackJS.console.log(action);
    return next(action);
  } catch (err) {
    // Something bad happened, lets log out the entire state so we can see it in the timeline
    console.warn(store.getState());
    // NOTE: this assumes TrackJS was initialized previously, at app startup.
    TrackJS.track(err);

    if (isDev) {
      throw new Error(err);
    }
  }
};

export function createReduxStore() {
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: "FlatWidget",
        })
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(thunk, raiseEventMiddleware, TrackJSLoggerMiddleware),
  );

  const store = createStore(reducer, initialState, enhancer);
  return store;
}
