import React, { useRef, useEffect } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import CountryFlag from "../CountryFlag/CountryFlag";
import styles from "./CountriesList.scss";

function CountriesList(props) {
  const { countriesList, onSelectCountry, selectedCountry, onOutsideClick } = props;
  const containerRef = useRef(null);
  const selectedCountryRef = useRef(null);

  useEffect(() => {
    const containerTop = containerRef?.current?.getBoundingClientRect()?.top || 0;
    const selectedCountryTop = selectedCountryRef?.current?.getBoundingClientRect()?.top || 0;
    const distanceToSelectedCountry = selectedCountryTop - containerTop;

    containerRef.current.scrollTop = distanceToSelectedCountry - 81;
  }, []);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        onOutsideClick(false);
      }}
    >
      <ul ref={containerRef} className={cn(styles.CountriesList)}>
        {countriesList.map(({ name, iso2, countryCode }) => {
          return (
            <li
              ref={selectedCountry === iso2 ? selectedCountryRef : null}
              key={iso2}
              className={cn(styles.CountriesList__country, {
                [styles.isSelected]: selectedCountry === iso2,
              })}
              onClick={() => onSelectCountry(iso2)}
            >
              <CountryFlag countryIso2={iso2} />
              <span className={cn(styles.CountriesList__countryName)}>{name}</span>
              <span className="text-color-quaternary">+{countryCode}</span>
            </li>
          );
        })}
      </ul>
    </OutsideClickHandler>
  );
}

export default CountriesList;
