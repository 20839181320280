import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import VerificationFlow from "./components/VerificationFlow/VerificationFlow";
import styles from "./VerificationScene.scss";
import VerificationMethod from "./components/VerificationMethod/VerificationMethod";
import VerificationCode from "./components/VerificationCode/VerificationCode";
import { AppCase, AppSwitch } from "../../components/AppSwitch";
import { WizardContext } from "../Wizard/Wizard";
import SelectProfile from "./components/SelectProfile/SelectProfile";
import { getMatchedCustomers, saveLookupCustomer, useRedux } from "../../redux";
import { VERIFICATION_SUB_STEP_IDS } from "../Wizard/utils/stepsGenerator";

const VerificationScene = ({
  store,
  onVerificationSuccess,
  onLookupCustomer,
  onNextSubStep,
  onPreviousSubStep,
  onProfileNext,
}) => {
  const [state, dispatch] = useRedux(store);
  const customers = getMatchedCustomers(state);
  const { updateCurrentWizardStep, activeStep } = useContext(WizardContext);

  const {
    subSteps = [
      VERIFICATION_SUB_STEP_IDS.VERIFICATION_FLOW,
      VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE,
      VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE,
    ],
    activeSubStepIndex = 0,
  } = activeStep;
  const [verificationResendData, setVerificationResendData] = useState(null);

  const handleSelectProfile = (customer) => {
    dispatch(saveLookupCustomer(customer));
    updateCurrentWizardStep({
      isValid: true,
    });
  };

  return (
    <div className={styles.verificationStep}>
      <AppSwitch test={subSteps[activeSubStepIndex]}>
        <AppCase value={VERIFICATION_SUB_STEP_IDS.VERIFICATION_FLOW}>
          <VerificationFlow
            onLookupCustomer={onLookupCustomer}
            store={store}
            onNextSubStep={onNextSubStep}
          />
        </AppCase>
        <AppCase value={VERIFICATION_SUB_STEP_IDS.VERIFICATION_PROFILE}>
          <SelectProfile
            store={store}
            onSelect={handleSelectProfile}
            profilesList={customers}
            onNext={onProfileNext}
          />
        </AppCase>
        <AppCase value={VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE}>
          <VerificationMethod
            store={store}
            onNextSubStep={onNextSubStep}
            setVerificationResendData={setVerificationResendData}
            handlePreviousSubStep={onPreviousSubStep}
          />
        </AppCase>
        <AppCase value={VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE}>
          <VerificationCode
            verificationResendData={verificationResendData}
            store={store}
            handlePreviousSubStep={onPreviousSubStep}
            onVerificationSuccess={onVerificationSuccess}
          />
        </AppCase>
      </AppSwitch>
    </div>
  );
};

VerificationScene.propTypes = {
  store: PropTypes.object,
};

export default VerificationScene;
