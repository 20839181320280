import React, { useContext, useEffect } from "react";
import Typography from "../../../../components/Typography/Typography";
import styles from "./SelectProfile.scss";
import PropTypes from "prop-types";
import { WizardContext } from "../../../Wizard/Wizard";
import ProfileCard from "./components/ProfileCard/ProfileCard";

const SelectProfile = ({ profilesList, onSelect, store, onNext }) => {
  const { activeStep } = useContext(WizardContext);
  const { nextButtonClickListener } = activeStep;

  useEffect(() => {
    if (nextButtonClickListener) {
      onNext();
    }
  }, [nextButtonClickListener]);

  return (
    <div>
      <Typography fontWeight="bold" fontSize="xl">
        Please, select your profile
      </Typography>
      <div className={styles.list}>
        {profilesList.map((customer) => {
          return (
            <ProfileCard
              customer={customer}
              key={customer.customerId}
              onSelect={onSelect}
              store={store}
            />
          );
        })}
      </div>
    </div>
  );
};

SelectProfile.propTypes = {
  profilesList: PropTypes.array,
  onSelect: PropTypes.func,
  onNextSubStep: PropTypes.func,
};

export default SelectProfile;
