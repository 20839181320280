// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CountrySearch__CountrySearch--TVFJC{height:45px;border:1px solid #ededf6;display:flex;margin:18px;border-radius:25px}.CountrySearch__CountrySearch--TVFJC:hover{border-color:#108af9;box-shadow:0 0 0 1px #108af9}.CountrySearch__CountrySearch--TVFJC:focus{border-color:#108af9;box-shadow:0 0 0 1px #108af9}.CountrySearch__CountrySearch__icon--7vaV5{width:56px;display:flex;justify-content:center;align-items:center}.CountrySearch__CountrySearch__input--uq7NC{padding-top:3px;height:100%;border-radius:0 50% 50% 0}.CountrySearch__CountrySearch__input--uq7NC::placeholder{color:#adb1c9;font-weight:500}", "",{"version":3,"sources":["webpack://./src/components/Inputs/PhoneInput/components/CountrySearch/CountrySearch.scss"],"names":[],"mappings":"AAAA,qCACE,WAAA,CACA,wBAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CAEA,2CACE,oBAAA,CACA,4BAAA,CAGF,2CACE,oBAAA,CACA,4BAAA,CAGF,2CACE,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,4CACE,eAAA,CACA,WAAA,CACA,yBAAA,CAEA,yDACE,aAAA,CACA,eAAA","sourcesContent":[".CountrySearch {\n  height: 45px;\n  border: 1px solid #ededf6;\n  display: flex;\n  margin: 18px;\n  border-radius: 25px;\n\n  &:hover {\n    border-color: #108af9;\n    box-shadow: 0 0 0 1px #108af9;\n  }\n\n  &:focus {\n    border-color: #108af9;\n    box-shadow: 0 0 0 1px #108af9;\n  }\n\n  &__icon {\n    width: 56px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__input {\n    padding-top: 3px;\n    height: 100%;\n    border-radius: 0 50% 50% 0;\n\n    &::placeholder {\n      color: #ADB1C9;\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CountrySearch": "CountrySearch__CountrySearch--TVFJC",
	"CountrySearch__icon": "CountrySearch__CountrySearch__icon--7vaV5",
	"CountrySearch__input": "CountrySearch__CountrySearch__input--uq7NC"
};
export default ___CSS_LOADER_EXPORT___;
