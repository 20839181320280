import PropTypes from "prop-types";
import { Slide } from "react-toastify";

export const AppointmentType = {
  Unknown: "-1",
  Waiting: "0",
  Dropoff: "1",
};

export const waitOrDropoff = {
  both: "1",
  dropoff: "2",
  waitfor: "0",
};

export const WizardStep = {
  Vehicle: 1,
  Appointment: 2,
  Contact: 3,
};

export const EventNames = {
  DateTime: "DATE_TIME_COMPLETED",
  VehicleService: "VEHICLE_SERVICE_COMPLETED",
  RequestAppointment: "REQUEST_APPOINTMENT_SUCCESS",
};

export const PROP_TYPES_TEMPLATES = {
  CHILDREN: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.oneOf([null, undefined]),
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.node,
  ]),
  INPUT_VALUE: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  CLASS_NAME: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  LABEL: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  ERROR: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  ICON: PropTypes.object,
  DATE: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  COMPONENT: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
  TITLE: PropTypes.string,
  COLOR: PropTypes.string,
  REF: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]),
};

export const VALIDATION_MESSAGES = {
  REQUIRED: "This field is required",
  EMAIL_FAILED: "Email is not valid",
  INVALID_PHONE: "Invalid phone number, please try again.",
  INVALID_EMAIL: "Invalid email, please try again.",
};

export const PLAZA_TIRE_SERVICE = "Tire Purchase (no appointment necessary)";

export const ENV_TYPES = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
  /*TODO should be updated after updated helm chart constants*/
  DEV2: "development",
  STAGE: "development",
  /*---------------------------------------------------------*/
};

export const DAYS_NAME = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const DAYS = ["su", "mo", "tu", "we", "th", "fr", "sa"];

export const SOURCES = {
  GOOGLE: "google",
  MAIN: "Main",
  PUBLIC_VEHICLE: "PublicVehiclePage",
  CAMPAIGNS: "Campaigns",
};

// Screen resolutions
export const SCREEN_SMALL_MOBILE = 360;
export const SCREEN_MOBILE = 640;
export const SCREEN_TABLET = 1024;
export const SCREEN_LARGE = 1200;
export const SCREEN_EXTRA_LARGE = 1600;
export const SCREEN_VIEW_SIZES = {
  MOBILE: "mobile",
  TABLET: "tablet",
  MIDDLE_PC: "middlePC",
  PERSONAL_COMPUTER: "personalComputer",
  LARGE_PERSONAL_COMPUTER: "largePersonalComputer",
};

export const VERIFICATION_METHOD_TYPES = {
  PHONE: "Sms",
  EMAIL: "Email",
};

export const TOAST_TYPES = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const TOAST_LABELS = {
  [TOAST_TYPES.SUCCESS]: "SUCCESS",
  [TOAST_TYPES.ERROR]: "ERROR",
  [TOAST_TYPES.WARNING]: "WARNING",
  [TOAST_TYPES.INFO]: "INFO",
};

export const TOAST_CONFIG = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: false,
  draggable: true,
  rtl: false,
  closeButton: false,
  transition: Slide,
};
