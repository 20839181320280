import cn from "classnames";
import React from "react";
import PropTypes from "prop-types";
import styles from "./Step.scss";

const Step = ({ isAvailable, value, onClick, isCurrent }) => {
  return (
    <div
      className={cn(styles.step, {
        [styles.active]: isAvailable,
        [styles.inactive]: !isAvailable || isCurrent,
      })}
      onClick={() => isAvailable && onClick()}
    >
      <div>{value}</div>
    </div>
  );
};

Step.propTypes = {
  isAvailable: PropTypes.bool,
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.number,
};

export default Step;
