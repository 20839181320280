import React from "react";
import { PointIcon } from "../../svg-icons";
import styles from "./RadioButton.scss";
import Typography from "../Typography/Typography";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../constants";

const RadioButton = ({ isSelected, onSelect, label }) => {
  return (
    <div onClick={onSelect} className={styles.radioButton}>
      {isSelected ? (
        <PointIcon width={20} height={20} className={styles.active} />
      ) : (
        <div className={styles.default}></div>
      )}
      <Typography>{label}</Typography>
    </div>
  );
};

RadioButton.propTypes = {
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  label: PROP_TYPES_TEMPLATES.LABEL,
};

export default RadioButton;
