import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "../../../../components/Typography/Typography";
import SelectCard from "../../../../components/SelectCard/SelectCard";
import { EmailIcon, MobilePhoneIcon } from "../../../../svg-icons";
import styles from "./VerificationMethod.scss";
import { VERIFICATION_METHOD_TYPES } from "../../../../constants";
import {
  getShopId,
  getLookupCustomer,
  useRedux,
  setVerificationMethod,
  getVerificationMethod,
  getCustomerDataToVerify, getSelectedCustomer,
} from "../../../../redux";
import { sendVerificationCode } from "../../../../services/mechanic-api.client";
import { WizardContext } from "../../../Wizard/Wizard";

const VerificationMethod = ({ store, onNextSubStep, setVerificationResendData }) => {
  const { updateCurrentWizardStep, activeStep } = useContext(WizardContext);
  const [state, dispatch] = useRedux(store);
  const shopId = getShopId(state);
  const customer = getLookupCustomer(state); // data from lookup response to check for available method and id
  const customerFromForm = getSelectedCustomer(state)  // data from contact form input
  const customerDataToVerify = getCustomerDataToVerify(state); // data lookup form input to set correct email;
  const verificationMethod = getVerificationMethod(state);

  const primaryEmail = customer?.primaryEmail || customerFromForm?.primaryEmail
  const primaryPhoneNumber = customer?.primaryPhoneNumber || customerFromForm?.primaryPhoneNumber

  const handleSendCode = async () => {
    const { customerId } = customer;
    // pick data from contact form or from matching form;
    const email = customerFromForm?.email || customerDataToVerify.email;
    const phone = customerFromForm?.phone || customerDataToVerify.phoneNumber; // have no clue why different naming
    const requestData =
      verificationMethod === VERIFICATION_METHOD_TYPES.EMAIL
        ? { channel: "Email", to: email, customerId }
        : { channel: "Sms", to: phone, customerId };
    onNextSubStep();
    const result = await sendVerificationCode({ shopId, data: requestData });
    setVerificationResendData({ requestId: result?.data?.id, data: requestData });
  };

  useEffect(() => {
    if (activeStep?.nextButtonClickListener) {
      handleSendCode();
    }
  }, [activeStep?.nextButtonClickListener]);

  useEffect(() => {
    updateCurrentWizardStep({
      nextButtonLabel: "Next",
    });
    updateCurrentWizardStep({
      isValid: !!verificationMethod,
    });
  }, []);

  const handleSelectType = (type) => {
    dispatch(setVerificationMethod(type));
    updateCurrentWizardStep({
      isValid: true,
    });
  };

  return (
    <div>
      <div>
        <Typography fontWeight="bold" fontSize="xl">
          Welcome Back!
        </Typography>
        <Typography color="tertiary">
          Your appointment is not yet confirmed. First please select a verification method.
        </Typography>
      </div>

      <div className={styles.cards}>
        {primaryEmail && (
          <SelectCard
            className={styles.card}
            isSelected={verificationMethod === VERIFICATION_METHOD_TYPES.EMAIL}
            onSelect={() => {
              handleSelectType(VERIFICATION_METHOD_TYPES.EMAIL);
            }}
          >
            <EmailIcon />
            <div>
              <Typography fontWeight="bold" fontSize="xl">
                Email
              </Typography>
              <Typography color="tertiary">{primaryEmail}</Typography>
            </div>
          </SelectCard>
        )}
        {primaryPhoneNumber && (
          <SelectCard
            className={styles.card}
            isSelected={verificationMethod === VERIFICATION_METHOD_TYPES.PHONE}
            onSelect={() => {
              handleSelectType(VERIFICATION_METHOD_TYPES.PHONE);
            }}
          >
            <MobilePhoneIcon height={32} />
            <div>
              <Typography fontWeight="bold" fontSize="xl">
                Phone Number
              </Typography>
              <Typography color="tertiary">{primaryPhoneNumber}</Typography>
            </div>
          </SelectCard>
        )}
      </div>
    </div>
  );
};

VerificationMethod.propTypes = {
  store: PropTypes.object,
  onNextSubStep: PropTypes.func,
  setVerificationResendData: PropTypes.func,
};

export default VerificationMethod;
