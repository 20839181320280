import { format } from "date-fns";
import { AppointmentType } from "../constants";
import { isSameDay } from "date-fns";
import { getAdditionalComment, getServComments } from "../services/comments.service";
import { formatPhoneNumberToUsLocale, joinFullName } from "../utils";

export function getSummary(state) {
  return {
    location: getShopLocation(state),
    vehicle: getVehicleSectionValue(state),
    service: getServicesSelectedValue(state),
    dateTime: getDateTimeSectionValue(state),
    dropoffType: getDropoffSectionValue(state),
    servicesComments: getServicesComments(state),
  };
}
export function getServicesComments(state) {
  return state.appointmentDetails.servicesComments;
}

export function getShopLocation(state) {
  const { city, address1, stateAbbr, zipCode } = state.shopDetails || {};

  const addressString = address1 ? `${address1}\n` : "";
  const cityString = city ? `${city}, ` : "";
  const stateAbbrString = stateAbbr ? `${stateAbbr} ` : "";
  const zipCodeString = zipCode ? zipCode : "";
  const location = `${addressString}${cityString}${stateAbbrString}${zipCodeString}`;

  if (location.trim()) {
    return location;
  } else {
    return null;
  }
}
export function getAvailableSlots(state) {
  if (isDropoffSectionCompleted(state) && isDateSelected(state)) {
    const { date } = state.appointmentDetails.dateTime;
    const timeSlots = state.shopOptions.timeSlots;
    // time slots not loaded yet
    if (!timeSlots) return null;
    const timeSlotsForDay = timeSlots.filter((slot) => {
      return isSameDay(new Date(slot.start), date);
    });
    return timeSlotsForDay;
  }
}

export function isDateSelected(state) {
  return Boolean(state.appointmentDetails.dateTime.date);
}

export function isVehicleSectionCompleted(state) {
  const { year, make, model } = state.appointmentDetails.vehicle;
  return year && make && model;
}

export function isServicesSectionCompleted(state) {
  return state.appointmentDetails.services.length > 0;
}

export function isDropoffSectionCompleted(state) {
  return Boolean(state.appointmentDetails.appointmentType !== "-1");
}

export function isDateTimeSectionCompleted(state) {
  return Boolean(state.appointmentDetails.dateTime.timeSlot);
}

export function getVehicleSectionValue(state) {
  const { year, make, model } = state.appointmentDetails.vehicle;
  return `${year || ""} ${make || ""} ${model || ""}`;
}
export function getShopDetailsValue(state) {
  return state.shopDetails;
}

export function getAppointmentAllowedStatuses(state) {
  return [state.shopDetails?.isWaitingEnabled, state.shopDetails?.isDroppingOffEnabled];
}

export function getServicesSectionValue(state) {
  return `${state.appointmentDetails.services.length}`;
}

export function getServicesSelectedValue(state) {
  const allAvailableServices = state.shopOptions.services;
  const allSelectedServices = state.appointmentDetails.services;

  const selectedList = allAvailableServices.filter((serv) =>
    allSelectedServices.some((id) => id == serv.serviceId),
  );
  return selectedList;
}

export function getDropoffSectionValue(state) {
  switch (state.appointmentDetails.appointmentType) {
    case AppointmentType.Dropoff:
      return "I will drop off my vehicle";
    case AppointmentType.Waiting:
      return "I will wait for my vehicle";
  }
}

export function getDateTimeSectionValue(state) {
  const { timeSlot } = state.appointmentDetails.dateTime;
  return (
    timeSlot &&
    `${format(new Date(timeSlot.start), "MMMM d, yyyy")} at ${format(
      new Date(timeSlot.start),
      "hh:mm aa",
    )}`
  );
}

export function getAppointmentTimeSlot(state) {
  return state.appointmentDetails.dateTime.timeSlot;
}

export function getSectionState(state, sectionName) {
  let isComplete, getValue;

  switch (sectionName) {
    case "vehicle":
      isComplete = isVehicleSectionCompleted;
      getValue = getVehicleSectionValue;
      break;

    case "services":
      isComplete = isServicesSectionCompleted;
      getValue = getServicesSectionValue;
      break;

    case "dropoff":
      isComplete = isDropoffSectionCompleted;
      getValue = getDropoffSectionValue;
      break;

    case "datetime":
      isComplete = isDateTimeSectionCompleted;
      getValue = getDateTimeSectionValue;
      break;
    default:
      throw new Error("Unknown section name: ", sectionName);
  }

  return {
    isComplete: isComplete(state),
    value: getValue(state),
  };
}

export function getVehicleInfo(state) {
  return state.appointmentDetails.vehicle;
}

export function getIsLoadedSlotsStatus(state) {
  return state.areSlotsLoaded;
}

export function getIsLoadedServicesStatus(state) {
  return state.areServicesLoaded;
}

export function getAreModelsLoaded(state) {
  return state.areModelsLoaded;
}

export function getAreMakesLoaded(state) {
  return state.areMakesLoaded;
}

export function getIsModelFieldDisabled(state) {
  return state.isModelFieldDisabled;
}

export function getIsMakeFieldDisabled(state) {
  return state.isMakeFieldDisabled;
}

export function getAllShopLocations(state) {
  const locationsList = [];

  const shopDetailsMap = state.availableShopDetails?.reduce((acc, item) => {
    acc[item.shopId] = item;
    return acc;
  }, {});

  state.availableShopIds.forEach((id) => {
    if (!shopDetailsMap[id]) return;
    const { shopId, name, rating, phoneNumber, locationNumber, workTimeSlots, address } =
      shopDetailsMap[id];
    let location = {
      shopId,
      name,
      rating,
      locationNumber,
      phone: phoneNumber,
      workTimeSlots: workTimeSlots,
      latitude: address.coordinates?.latitude || 0,
      longitude: address.coordinates?.longitude || 0,
      address: address.streetAddress,
      country: address.country,
      city: address.city,
      state: address.state,
      zip: address.zipCode,
    };
    locationsList.push(location);
  });
  return locationsList;
}

export function getAllComments(state, contactInfo, isLoanerVehicleAllowed, isLoanerVehicleNeeded) {
  const { appointmentDetails, shopOptions } = state;
  const allAvailableServices = shopOptions.services;
  return (
    `${getServComments(appointmentDetails, allAvailableServices)}` +
    `${getAdditionalComment(contactInfo.comments, isLoanerVehicleAllowed, isLoanerVehicleNeeded)}`
  );
}

export function getAppointmentTitle(state, contactInfo) {
  const { appointmentDetails } = state;
  const {
    vehicle: { year, make, model },
  } = appointmentDetails;
  return `${joinFullName(contactInfo)} / ${year?.value || year} ${make?.value || make} ${model?.value || model}`;
}

export function isOneShopOnly(state) {
  return state.availableShopIds.length === 0 || state.availableShopIds.length === 1;
}

export function getAreShopDetailsLoaded(state) {
  return state.areShopDetailsLoaded;
}

export function getShopId(state) {
  return state.shopId;
}
export function getTenantId(state) {
  return state.tenantId;
}
export function getCustomerId(state) {
  return state.customerId;
}
export function getCampaignId(state) {
  return state.campaignId;
}
export function getCampaignName(state) {
  return state.campaignName;
}
export function getMarketingTypeId(state) {
  return state.marketingTypeId;
}

export function getSelectedCustomer(state) {
  return state.selectedCustomer;
}

export function getLookupCustomer(state) {
  return state.lookupCustomer;
}

export function getCustomerDataToVerify(state) {
  return state.customerDataToVerify;
}

export function getMatchedCustomers(state) {
  return state.matchedCustomers;
}

export function getVehicles(state) {
  return state.vehicles;
}
export function getNewVehicleSelected(state) {
  return state.isNewVehicle;
}
export function getContactInfo(state) {
  return state.appointmentDetails.contactInfo;
}

export function getIsGuestFlow(state) {
  return state.isGuestFlow;
}
export function getIsContactConsentShown(state) {
  return state.isContactConsentShown;
}

export function getDisclaimerTextWithReplacedTokens(state) {
  return state.disclaimerTextWithReplacedTokens;
}
export function getVerificationMethod(state) {
  return state.otpVerificationChannel;
}

export function getContactStepValue(state, contactInfo, formIsValid) {
  const { email = "", phone = "" } = contactInfo;
  const isVehicleSelected = isVehicleSectionCompleted(state);
  const vehicle = `${getVehicleSectionValue(state)}; `;
  const lookupCustomer = getLookupCustomer(state);
  const customerName = joinFullName(contactInfo?.firstName ? contactInfo: lookupCustomer);

  const separator = !formIsValid ? ";" : ".";
  const contacts = `${customerName}
    ${
    customerName && (email || phone) && ", "
  }${email && email}${email && phone && ", "}${phone && formatPhoneNumberToUsLocale(phone)}`;
  return `${isVehicleSelected ? vehicle : ""}${contacts && contacts}${contacts && separator}`;
}
