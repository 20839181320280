import {
  getBrandColor,
  getBackgroudColor,
  getBorderColorForTransparentBackground,
} from "./config.service";

export function setBrandColor() {
  const root = document.documentElement;
  const brandColor = getBrandColor();
  root.style.setProperty("--main-brand-color", brandColor);
  root.style.setProperty("--selection-card-border-background-color", `${brandColor}1A`);
  const backgroudColor = getBackgroudColor();
  root.style.setProperty("--main-background-color", backgroudColor);
  const cardBorder = getBorderColorForTransparentBackground();
  root.style.setProperty("--for-transparent-background-border", cardBorder);
}
