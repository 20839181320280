import { useEffect } from "react";
import cn from "classnames";
import {
  useRedux,
  init,
  getWidgetSettings,
  setDisclaimerTextWithReplacedTokens,
  getShopId,
  decryptToken,
  getCampaignId,
  getCampaignName,
} from "../../redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "../../templates/MainHeader/MainHeader";
import styles from "./App.scss";
import {
  getToken,
  initClusterId,
  initSettings,
  getShowHeader,
  getIsWidgetPublicPage,
  getDisclaimerText,
  getSettingsInitialised,
  initSettingsFromScriptOrDefault,
  getIsPreview,
  getCToken,
  getIsTenantWidget,
} from "../../services/config.service";
import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../services/analytics.service";
import { setBrandColor } from "../../services/color.service";
import Spinner from "../../components/Spinner/Spinner";
import ComponentsRepresentation from "../../templates/ComponentsRepresentation/ComponentsRepresentation";
import Wizard from "../Wizard/Wizard";
import { renderAutoOpsWidget } from "../../utils";
import { replaceStringTokens } from "../../services/mechanic-api.client";
import { TOAST_CONFIG } from "../../constants";

export default function App({ store, msoLocations, onSelectMsoShop, msoToken }) {
  const [state, dispatch] = useRedux(store);
  const { widgetSettings, clusterId, areLocationShopDetailsLoading, shopDetails, areShopDetailsLoading} = state;
  const params = new URLSearchParams(location.search);
  const isShowComponentsPage = params ? params?.get("showComponents") : false;
  const token = getToken();
  const cToken = getCToken();
  const showHeader = getShowHeader();
  const isWidgetPublicPage = getIsWidgetPublicPage();
  const disclaimerText = getDisclaimerText();
  const isPreview = getIsPreview();
  const isSettingsInitialised = getSettingsInitialised();
  const shopId = getShopId(state);
  const campaignId = getCampaignId(state);
  const campaignName = getCampaignName(state);
  const isTenantWidget = getIsTenantWidget();

  useEffect(() => {
    if (token || msoToken || cToken) {
      dispatch(decryptToken(token || msoToken, cToken));
    }
  }, [token, msoToken, cToken]);

  useEffect(() => {
    if (clusterId) {
      initClusterId(clusterId);
      if (!msoLocations) {
        dispatch(getWidgetSettings());
      }
    }
  }, [clusterId]);

  useEffect(() => {
    if (widgetSettings && !msoLocations) {
      initSettings(widgetSettings);
      setBrandColor();
      registerAnalyticsEvent(CUSTOM_EVENTS.widget_loaded);
      dispatch(init());
    }
  }, [widgetSettings]);

  useEffect(() => {
    if (isPreview || msoLocations) {
      initSettingsFromScriptOrDefault();
      setBrandColor();
      registerAnalyticsEvent(CUSTOM_EVENTS.widget_loaded);
      dispatch(init());
    }
  }, [isPreview]);

  useEffect(() => {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const campaignIdParamName= "ao_campaign_id";
    const campaignIdUrlParam = urlParams.get(campaignIdParamName)

    if (campaignId && !campaignIdUrlParam) {
      urlParams.set(campaignIdParamName, campaignId);
      window.location.search = urlParams.toString();
      // for campaign name encoding is needed
      window.location.search = urlParams.toString() + `&ao_campaign=${encodeURIComponent(campaignName)}`;
    }
  }, [campaignId]);

  //disclaimer text, token replacement
  useEffect(async () => {
    if (shopId && disclaimerText) {
      const result = await replaceStringTokens(shopId, disclaimerText);
      dispatch(setDisclaimerTextWithReplacedTokens(result?.data || ""));
    }
  }, [shopId, disclaimerText]);

  const renderContent = () => {
    // render AutoOps Widget for public page if turned on for shop
    if (isWidgetPublicPage && shopDetails?.autoOpsIntegrationDetails?.enabled && shopId) {
      return (
        <>
          <MainHeader shop={state?.shopDetails ? state.shopDetails : {}}></MainHeader>
          <div className={styles.autoOpsWidget}>
            {renderAutoOpsWidget(shopDetails.autoOpsIntegrationDetails.apiKey)}
          </div>
        </>
      );
    }

    return (
      <div className={cn({ [styles.appContainer]: isWidgetPublicPage })}>
        {showHeader && <MainHeader shop={state?.shopDetails ? state.shopDetails : {}}></MainHeader>}
        <Wizard store={store} msoLocations={msoLocations} onSelectMsoShop={onSelectMsoShop} />
      </div>
    );
  };

  if (isShowComponentsPage) {
    return <ComponentsRepresentation store={store} />;
  }

  return (
    <>
      {isSettingsInitialised && !areLocationShopDetailsLoading && (!areShopDetailsLoading || isTenantWidget)
        ? renderContent() : <Spinner />}
      <ToastContainer
        {...TOAST_CONFIG}
        className={styles.toastContainer}
        toastClassName={styles.resetPaddings}
        bodyClassName={styles.resetPaddings}
      />
    </>
  );
}
