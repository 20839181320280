import { TrackJS } from "trackjs";
import { TRACK_JS_ENV, TRACK_JS_KEY } from "./services/env.service";

const isDev = process.env.NODE_ENV === "development";
const errorKeywordsToIgnore = ["ResizeObserver loop limit exceeded", "Warning"];

export const initTrackJs = () => {
  if (!isDev) {
    TrackJS.install({
      token: TRACK_JS_KEY,
      application: TRACK_JS_ENV,
      onError: (payload) => {
        let isLogError = true;

        for (let i = 0; i < errorKeywordsToIgnore.length; i++) {
          if (payload.message.includes(errorKeywordsToIgnore[i])) {
            isLogError = false;
            break;
          }
        }

        return isLogError;
      },
    });
  }
};
