import React from "react";
import cn from "classnames";
import styles from "./Skeleton.scss";
import RepeatRow from "./components/RepeatRow";

const Skeleton = () => {
  return (
    <div className={cn(styles.skeleton)}>
      <div className={styles.block}>
        <RepeatRow number={3} />
      </div>
      <div className={styles.block}>
        <div className={styles.header} />
        <div className={styles.location}>
          <RepeatRow number={3} />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.header} />
        <div className={styles.hours}>
          <RepeatRow number={7} />
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
