import { useEffect, useState } from "react";
import {
  SCREEN_SMALL_MOBILE,
  SCREEN_MOBILE,
  SCREEN_TABLET,
  SCREEN_LARGE,
  SCREEN_EXTRA_LARGE,
  SCREEN_VIEW_SIZES,
} from "../constants";

export function useScreenSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallMobile = windowSize.width <= SCREEN_SMALL_MOBILE;
  const isMobile = windowSize.width < SCREEN_MOBILE;
  const isTablet = windowSize.width >= SCREEN_MOBILE && windowSize.width < SCREEN_TABLET;
  const isMiddlePC = windowSize.width >= SCREEN_TABLET && windowSize.width < SCREEN_LARGE;
  const isPC = windowSize.width >= SCREEN_LARGE && windowSize.width < SCREEN_EXTRA_LARGE;
  const isLargePC = windowSize.width >= SCREEN_EXTRA_LARGE;
  const isDesktop = windowSize.width >= SCREEN_TABLET;

  let currentView;
  if (isMobile) {
    currentView = SCREEN_VIEW_SIZES.MOBILE;
  } else if (isTablet) {
    currentView = SCREEN_VIEW_SIZES.TABLET;
  } else if (isMiddlePC) {
    currentView = SCREEN_VIEW_SIZES.MIDDLE_PC;
  } else if (isPC) {
    currentView = SCREEN_VIEW_SIZES.PERSONAL_COMPUTER;
  } else if (isLargePC) {
    currentView = SCREEN_VIEW_SIZES.LARGE_PERSONAL_COMPUTER;
  }

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isMiddlePC,
    isPC,
    isLargePC,
    isDesktop,
    currentView,
    windowWidth: windowSize.width,
    windowHeight: windowSize.height,
  };
}
