// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectCard__selectCard--bSBb1{position:relative;padding:16px;width:-webkit-fill-available}.SelectCard__selectIcon--3GdJ1{position:absolute;top:16px;right:16px;height:20px;width:20px}.SelectCard__selectIcon--3GdJ1 svg rect:nth-child(1){stroke:var(--main-brand-color)}.SelectCard__selectIcon--3GdJ1 svg rect:nth-child(2){fill:var(--main-brand-color)}", "",{"version":3,"sources":["webpack://./src/components/SelectCard/SelectCard.scss"],"names":[],"mappings":"AACA,+BACE,iBAAA,CACA,YAAA,CACA,4BAAA,CAGF,+BACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CAGE,qDACE,8BAAA,CAGF,qDACE,4BAAA","sourcesContent":["\n.selectCard {\n  position: relative;\n  padding: 16px;\n  width: -webkit-fill-available;\n}\n\n.selectIcon {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  height: 20px;\n  width: 20px;\n\n  svg {\n    rect:nth-child(1) {\n      stroke: var(--main-brand-color);\n    }\n\n    rect:nth-child(2) {\n      fill: var(--main-brand-color);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectCard": "SelectCard__selectCard--bSBb1",
	"selectIcon": "SelectCard__selectIcon--3GdJ1"
};
export default ___CSS_LOADER_EXPORT___;
