import cn from "classnames";
import { getIsClientTheme } from "../../services/config.service";
import styles from "./Button.scss";
import { ButtonLoadingIcon } from "../../svg-icons";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import PropTypes from "prop-types";

export default function Button({ title, onClick, className, variant, isDisabled, isLoading }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  function handleClick() {
    scrollToTop();
    onClick();
  }

  return (
    <button
      className={cn(styles.button, className, styles[`variant-${variant}`], {
        [styles["button--isClientTheme"]]: getIsClientTheme(),
        [styles.disabled]: isDisabled,
      })}
      onClick={() => !isDisabled && !isLoading && handleClick()}
    >
      {isLoading ? <ButtonLoadingIcon className={styles.icon} /> : title}
    </button>
  );
}

Button.propTypes = {
  title: PROP_TYPES_TEMPLATES.LABEL,
  variant: PropTypes.string,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
