import React from "react";
import cn from "classnames";
import { ArrowLeftIcon, ArrowRightMonthPickerIcon } from "../../../../svg-icons";
import styles from "./MonthToggle.module.scss";

import { addMonths, subMonths } from "date-fns";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../../../constants";

const MonthToggle = ({ onChange, currentMonth, isFirstAvailableMonth }) => {
  function moveToNextMonth() {
    const nextMonth = addMonths(currentMonth, 1);
    onChange(nextMonth);
  }

  function moveToPreviousMonth() {
    const previousMonth = subMonths(currentMonth, 1);
    onChange(previousMonth);
  }

  return (
    <div className={styles.toggle}>
      <span
        className={cn(styles.arrow, styles.left, {
          [styles.disabledPrev]: isFirstAvailableMonth,
        })}
        onClick={() => !isFirstAvailableMonth && moveToPreviousMonth()}
      >
        <ArrowLeftIcon />
      </span>
      <span className={cn(styles.arrow, styles.right)} onClick={moveToNextMonth}>
        <ArrowRightMonthPickerIcon />
      </span>
    </div>
  );
};

MonthToggle.propTypes = {
  isFirstAvailableMonth: PropTypes.bool,
  onChange: PropTypes.func,
  currentMonth: PROP_TYPES_TEMPLATES.DATE,
};

export default MonthToggle;
