import React from "react";
import Tooltip from "rc-tooltip";

import "rc-tooltip/assets/bootstrap.css";
import "./Tooltip.scss";

const CustomTooltip = ({
  children,
  content = "",
  useHover,
  placement = "top",
  tipContentClassName,
}) => {
  const triggers = [];
  if (useHover) {
    triggers.push("hover");
  }

  return (
    <Tooltip
      overlayClassName={tipContentClassName}
      placement={placement}
      destroyTooltipOnHide
      mouseEnterDelay={0}
      mouseLeaveDelay={0.1}
      trigger={triggers}
      overlay={content}
      zIndex={100000}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default CustomTooltip;
