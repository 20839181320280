import L, { LatLngBounds } from "leaflet";
import cn from "classnames";
import React, { useEffect } from "react";
import {
  getWidgetInPopup,
  isCallRailSwap,
  showGetDirectionsButton,
  showOverallShopRating,
} from "../../../../services/config.service";
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import { addressStyles, cityStateStyles, shopNameStyles } from "../../PopupStyles";
import { ArrowMapIcon } from "../../../../svg-icons";
import { showInGoogleMaps } from "../../../../utils";
import { buildStarsHTML } from "../../../../components/star-build";
import styles from "./Map.scss";

const Map = ({ markers, onClick, whenCreated, map, url, hoveredShop, focusedShop }) => {
  const isWidgetPopup = getWidgetInPopup();
  const defaultCenter = [32.9312336, -96.4597089];
  const defaultZoom = 4;

  const customMarkerIcon = L.divIcon({
    className: styles.iconPlaceholder,
    iconSize: [28, 33],
    iconAnchor: [14, 29],
    popupAnchor: [0, -30],
  });

  const hoveredMarkerIcon = L.divIcon({
    className: cn(styles.iconPlaceholder, styles.iconPlaceholderHovered),
    iconSize: [28, 33],
    iconAnchor: [14, 33],
    popupAnchor: [0, -30],
  });

  let bounds;
  useEffect(() => {
    if (markers.length > 0 && map) {
      const allMarkersCoord = markers.map((shop) => [shop.latitude, shop.longitude]);
      bounds = new LatLngBounds(allMarkersCoord);
      map.flyToBounds(bounds, { padding: [70, 70] });
    }
  }, [markers, map]);

  const isGetDirectionsButtonVisible = showGetDirectionsButton();
  const isRatingVisible = showOverallShopRating();

  return (
    <MapContainer
      zoom={defaultZoom}
      center={defaultCenter}
      bounds={bounds}
      className={cn(styles.map, {
        [styles.popupMap]: isWidgetPopup,
      })}
      whenCreated={whenCreated}
      zoomControl={false}
      keyboard={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url={url}
        crossOrigin={true}
      />

      <ZoomControl position="bottomright" />
      {markers.map((shop) => (
        <Marker
          key={shop.shopId}
          position={[shop.latitude, shop.longitude]}
          eventHandlers={{
            click: (e) => {
              onClick(shop.shopId);
            },
          }}
          icon={
            shop.shopId === hoveredShop || shop.shopId === focusedShop
              ? hoveredMarkerIcon
              : customMarkerIcon
          }
        >
          <Popup
            className={styles.popup}
            closeButton={false}
            autoPanPadding={[60, 60]}
            onOpen={isCallRailSwap() ? () => window.CallTrk?.swap && window.CallTrk.swap() : null}
          >
            <div style={cityStateStyles}>
              <div style={shopNameStyles}>{shop.name}</div>
              {isGetDirectionsButtonVisible && (
                <div>
                  {" "}
                  <ArrowMapIcon
                    className={styles.arrowMapIcon}
                    data-tip="Get Direction"
                    onClick={() => showInGoogleMaps(shop.latitude, shop.longitude)}
                  />
                </div>
              )}
            </div>

            {isRatingVisible && shop.rating && (
              <div className={styles.rating}>
                <div className={styles.ratingStars}>{buildStarsHTML(shop.rating)}</div>
                <div>{shop.rating?.toFixed(2)}</div>
              </div>
            )}
            <div style={addressStyles}>{shop.address}</div>
            <div style={addressStyles}>
              {shop.city}
              {shop.city ? "," : ""} {shop.state} {shop.zip}
            </div>
          </Popup>{" "}
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
