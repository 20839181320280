import { allSlotsForDay, parseSlotsStartToDate } from "../../../../services/date-service";
import styles from "./TimePicker.scss";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import { PROP_TYPES_TEMPLATES } from "../../../../constants";
import PropTypes from "prop-types";
import { format } from "date-fns";

const TimePicker = ({ date, timeSlots, selectedSlot, onClick, timePickerRef, isMobile }) => {
  const parsedTimeSlots = parseSlotsStartToDate(timeSlots);

  const allSlotsForSelectedDay = allSlotsForDay(parsedTimeSlots, date).map((sl) => {
    return {
      start: sl.start, //format(new Date(sl.start), "yyyy-MM-dd'T'HH:mm:ss"),
      end: sl.end, //format(new Date(sl.end), "yyyy-MM-dd'T'HH:mm:ss"),
      enabled: sl.enabled,
      isTimeBlock: false,
      TimeType: 0,
    };
  });

  return (
    <div className={styles.timePickerContainer}>
      <div className={styles.timePicker} ref={timePickerRef}>
        {timeSlots &&
          allSlotsForSelectedDay.map((timeSlot) => {
            return (
              <ToggleButton
                title={format(new Date(timeSlot.start), "hh:mm aa")}
                key={timeSlot.start}
                id={timeSlot.end}
                isEnabled={timeSlot.enabled}
                tooltipText="This time is not available"
                isSelected={selectedSlot && timeSlot.start === selectedSlot.start}
                onClick={() => onClick(timeSlot)}
                isMobile={isMobile}
                className={styles.timeSlot}
              />
            );
          })}
      </div>
    </div>
  );
};

TimePicker.propTypes = {
  date: PROP_TYPES_TEMPLATES.DATE,
  timeSlots: PropTypes.array,
  selectedSlot: PropTypes.object,
  timePickerRef: PROP_TYPES_TEMPLATES.REF,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default TimePicker;
