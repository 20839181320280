import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import styles from "./CompanyAvatar.scss";
import {LocationIcon} from "../../svg-icons";

const CompanyAvatar = ({
  avatar,
  width = 80,
  height = 80,
  className,
  alt = "Company avatar",
  hasBorder = false,
}) => {
  return (
    <div className={cn(className)}>
        <div
          className={cn(styles.imageContainer, {
            [styles.hasBorder]: hasBorder,
          })}
        >
          {avatar
            ? <img src={avatar} alt={alt} width={width} height={height} className={styles.avatarImg} />
            : <LocationIcon/>}
        </div>
    </div>
  );
};

CompanyAvatar.propTypes = {
  avatar: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  hasBorder: PropTypes.bool,
};

export default CompanyAvatar;
