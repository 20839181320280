import React, { useEffect } from "react";
import Typography from "../../../../../../components/Typography/Typography";
import styles from "./MatchingForm.scss";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import { SOURCES, VERIFICATION_METHOD_TYPES } from "../../../../../../constants";
import { AppCase, AppSwitch } from "../../../../../../components/AppSwitch";
import { Controller } from "react-hook-form";
import PhoneInput from "../../../../../../components/Inputs/PhoneInput/PhoneInput";
import ConsentCheckbox from "../../../../../../templates/ConsentCheckbox/ConsentCheckbox";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import PropTypes from "prop-types";
import { getMatchingOnly, getSource } from "../../../../../../services/config.service";
import { hideContactConsent, useRedux } from "../../../../../../redux";

const MatchingForm = ({ verificationType, onChangeVerificationType, control, store }) => {
  const source = getSource();
  const [state, dispatch] = useRedux(store);
  const isMatchingOnly = getMatchingOnly();

  useEffect(() => {
    // hide consent checkbox on last contact step for sunAuto hack;
    if (isMatchingOnly) {
      dispatch(hideContactConsent());
    }
  }, []);
  const title =
    source === SOURCES.CAMPAIGNS || source === SOURCES.PUBLIC_VEHICLE
      ? "Please confirm your phone number to proceed"
      : "Been Here Before?";
  return (
    <div>
      <div>
        <Typography fontWeight="bold" fontSize="xl" className={styles.title}>
          {title}
        </Typography>
        <Typography color="tertiary">Let us pull up your information.</Typography>
      </div>
      <div className={styles.verificationRadios}>
        <RadioButton
          label="Phone number"
          isSelected={verificationType === VERIFICATION_METHOD_TYPES.PHONE}
          onSelect={() => onChangeVerificationType(VERIFICATION_METHOD_TYPES.PHONE)}
        />
        <RadioButton
          label="Email"
          isSelected={verificationType === VERIFICATION_METHOD_TYPES.EMAIL}
          onSelect={() => onChangeVerificationType(VERIFICATION_METHOD_TYPES.EMAIL)}
        />
      </div>
      <div className={styles.form}>
        <AppSwitch test={verificationType}>
          <AppCase value={VERIFICATION_METHOD_TYPES.PHONE}>
            <div>
              <Controller
                control={control}
                name="phoneNumber"
                shouldUnregister={true}
                render={({ field: { onChange, value }, fieldState: { error } }) => {
                  return <PhoneInput value={value} onChange={onChange} error={error} />;
                }}
              />
              {!isMatchingOnly && (<Controller
                control={control}
                name="consentOptIn"
                shouldUnregister={true}
                render={({ field: { onChange, value } }) => {
                  return (
                    <ConsentCheckbox
                      onChange={onChange}
                      value={value}
                      store={store}
                      className={styles.consentCheckbox}
                    />
                  );
                }}
              />)}

            </div>
          </AppCase>
          <AppCase value={VERIFICATION_METHOD_TYPES.EMAIL}>
            <Controller
              control={control}
              name="email"
              shouldUnregister={true}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return <TextInput value={value} onChange={onChange} error={error} />;
              }}
            />
          </AppCase>
        </AppSwitch>
      </div>
    </div>
  );
};

MatchingForm.propTypes = {
  onChangeVerificationType: PropTypes.func,
  verificationType: PropTypes.string,
  store: PropTypes.object,
  control: PropTypes.object,
};

export default MatchingForm;
