import React from "react";
import cn from "classnames";
import { DEFAULT_BACKGROUND_COLOR, getBackgroudColor } from "../../services/config.service";
import styles from "./ElementWrapper.scss";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import PropTypes from "prop-types";

const ElementWrapper = ({
  children,
  className,
  tag = "div",
  isSelected,
  hasHover = true,
  isTransparent = false,
  isDefaultTheme = false,
  ...props
}) => {
  const backgroundColor = getBackgroudColor();
  const isAppWhiteBackground =
    backgroundColor?.toLowerCase() === DEFAULT_BACKGROUND_COLOR.toLowerCase();

  const Tag = tag;

  return (
    <Tag
      {...props}
      className={cn(
        styles.elementWrapper,
        {
          [styles.selected]: isSelected,
          [styles.hasHover]: hasHover,
          [styles.whiteTheme]: !isDefaultTheme && isAppWhiteBackground,
          [styles.grayTheme]: !isDefaultTheme && !isAppWhiteBackground,
          [styles.transparent]: isTransparent,
        },
        className,
      )}
    >
      {children}
    </Tag>
  );
};

ElementWrapper.propTypes = {
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  tag: PropTypes.string,
  isSelected: PropTypes.bool,
  hasHover: PropTypes.bool,
  isTransparent: PropTypes.bool,
};

export default ElementWrapper;
