// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GroupedWorkingHours__dayHours--QFvHB{display:flex;gap:5px;font-size:14px}", "",{"version":3,"sources":["webpack://./src/scenes/LocationScene/components/ShopList/components/ShopItem/components/GroupedWorkingHours/GroupedWorkingHours.scss"],"names":[],"mappings":"AAAA,sCACE,YAAA,CACA,OAAA,CACA,cAAA","sourcesContent":[".dayHours {\n  display: flex;\n  gap: 5px;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dayHours": "GroupedWorkingHours__dayHours--QFvHB"
};
export default ___CSS_LOADER_EXPORT___;
