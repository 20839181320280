import React from "react";
import ReactDOM from "react-dom";

import App from "./scenes/App/App";
import { createReduxStore } from "./redux";
import { initTrackJs } from "./initTrackJs";
import { initNewRelic } from "./initNewRelic";
import { initPendo } from "./initPendo";
import "./index.css";

const script = document.querySelector("#mechanic-advisor-embed-v2");

const embedRoot = document.createElement("div");
embedRoot.id = "mechanic-advisor-embed-v2-root";
script.parentNode.insertBefore(embedRoot, script);
document.body.style.margin = "0px";

const store = createReduxStore();

initTrackJs();
initNewRelic();
initPendo();

if (window.frameElement) {
  // resize iframe on content change
  const resizeObserver = new ResizeObserver((e) => {
    window.frameElement.style.height = e[0].contentRect.height + "px";
  });
  resizeObserver.observe(embedRoot);
} else {
  // create dataLayer for events pushing in dev mode
  window.dataLayer = window.dataLayer || [];
}

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  embedRoot,
);
