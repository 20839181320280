import React, { useRef } from "react";
import cn from "classnames";
import styles from "./ShopList.scss";
import ShopItem from "./components/ShopItem/ShopItem";
import { getWidgetInPopup } from "../../../../services/config.service";
import PropTypes from "prop-types";
import RequestResult from "../../../../components/RequestResult/RequestResult";
import { NoResultIcon } from "../../../../svg-icons";

const ShopList = ({
  shops,
  searchQuery,
  onSelectLocation,
  onViewLocation,
  onShopHover,
  onShopFocus,
  isMobileListView,
  selectedShopId,
}) => {
  const isWidgetPopup = getWidgetInPopup();
  const shopListRef = useRef(null);

  const searchQueryLowerCase = searchQuery.toLowerCase();
  const filteredShops = searchQuery
    ? shops.filter(
        (shop) =>
          shop.name.toLowerCase().includes(searchQueryLowerCase) ||
          shop.address?.toLowerCase().includes(searchQueryLowerCase) ||
          shop.city?.toLowerCase().includes(searchQueryLowerCase) ||
          shop.zip?.toLowerCase().includes(searchQueryLowerCase) ||
          shop.state?.toLowerCase().includes(searchQueryLowerCase),
      )
    : shops;

  return (
    <div className={styles.shopListWrapper}>
      {!filteredShops.length && (
        <div className={styles.noResult}>
          <RequestResult
            title="No Results Found"
            message="We couldn't find anything matching your search. Please try again."
            image={<NoResultIcon />}
          />
        </div>
      )}
      {!!filteredShops.length && (
        <ul
          className={cn(styles.shopList, {
            [styles.mobileListView]: isMobileListView,
            [styles.popupShopList]: isWidgetPopup,
          })}
          ref={shopListRef}
        >
          {filteredShops.map((shop) => (
            <ShopItem
              key={shop.shopId}
              shop={shop}
              onClick={onSelectLocation}
              onViewLocation={onViewLocation}
              selectedShopId={selectedShopId}
              onShopHover={onShopHover}
              onShopFocus={onShopFocus}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

ShopList.propTypes = {
  onSelectLocation: PropTypes.func,
  onViewLocation: PropTypes.func,
  onShopHover: PropTypes.func,
  onShopFocus: PropTypes.func,
  searchQuery: PropTypes.string,
  selectedShopId: PropTypes.string,
  shops: PropTypes.array,
  isMobileListView: PropTypes.bool,
};

export default ShopList;
