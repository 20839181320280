import { Controller } from "react-hook-form";

import styles from "./ContactForm.scss";
import {
  DEFAULT_BACKGROUND_COLOR,
  getBackgroudColor,
  getIsLoanerVehicleAllow,
  getIsPlazaTire,
  showCouponCode,
} from "../../../../services/config.service";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import TextArea from "../../../../components/Inputs/TextArea/TextArea";
import Typography from "../../../../components/Typography/Typography";
import PhoneInput from "../../../../components/Inputs/PhoneInput/PhoneInput";
import PropTypes from "prop-types";
import ConsentCheckbox from "../../../../templates/ConsentCheckbox/ConsentCheckbox";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";
import React from "react";
import { AppointmentType } from "../../../../constants";
import { getIsContactConsentShown, useRedux } from "../../../../redux";

const ContactForm = ({ control, store, isShowMainInfo }) => {
  const [state] = useRedux(store);
  const backgroundColor = getBackgroudColor();
  const isContactConsentShown = getIsContactConsentShown(state);
  const isAppWhiteBackground =
    backgroundColor.toLowerCase() === DEFAULT_BACKGROUND_COLOR.toLowerCase();
  // fixme isPlazaTire remove/handle in future
  const isPlazaTire = getIsPlazaTire();
  const appointmentType = state.appointmentDetails.appointmentType;
  const isLoanerVehicleAllowed =
    getIsLoanerVehicleAllow() && appointmentType === AppointmentType.Dropoff && !isPlazaTire;

  return (
    <div className={styles.contactForm}>
      {isLoanerVehicleAllowed && (
        <div className={styles.loanerVehicle}>
          <Typography fontSize="xl" fontWeight="bold">
            Would You Like a Loaner Vehicle?
          </Typography>
          <Controller
            control={control}
            name="appointmentCustomer.isLoanerVehicleNeeded"
            render={({ field: { onChange, value } }) => {
              return (
                <div className={styles.yesNo}>
                  <ToggleButton
                    className={styles.loanerButton}
                    title="Yes"
                    isSelected={value}
                    onClick={() => onChange(true)}
                  />
                  <ToggleButton
                    className={styles.loanerButton}
                    title="No"
                    isSelected={!value}
                    onClick={() => onChange(false)}
                  />
                </div>
              );
            }}
          />
        </div>
      )}
      {isShowMainInfo && (
        <div className={styles.mainInfo}>
          <Typography parentClassName={styles.header} fontSize="xl" fontWeight="bold">
            Provide Your Contact Information
          </Typography>
          <div className={styles.row}>
            <Controller
              control={control}
              name="appointmentCustomer.firstName"
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <TextInput
                    id="str-first-name"
                    inputClassName={styles.inputClassName}
                    label="First name"
                    value={value}
                    onChange={onChange}
                    error={error}
                    isGrayTheme={isAppWhiteBackground}
                  />
                );
              }}
            />

            <Controller
              control={control}
              name="appointmentCustomer.lastName"
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <TextInput
                    id="str-last-name"
                    inputClassName={styles.inputClassName}
                    label="Last name"
                    value={value}
                    onChange={onChange}
                    error={error}
                    isGrayTheme={isAppWhiteBackground}
                  />
                );
              }}
            />
          </div>
          <div className={styles.row}>
            <Controller
              control={control}
              name="appointmentCustomer.email"
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <TextInput
                    id="str-email"
                    inputClassName={styles.inputClassName}
                    label="Email address"
                    value={value}
                    onChange={onChange}
                    error={error}
                    isGrayTheme={isAppWhiteBackground}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="appointmentCustomer.phone"
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                  <PhoneInput
                    id="str-phone"
                    label="Mobile number"
                    value={value}
                    onChange={onChange}
                    error={error}
                    isGrayTheme={isAppWhiteBackground}
                  />
                );
              }}
            />
          </div>
          {isContactConsentShown && (
            <div>
              <Controller
                control={control}
                name="appointmentCustomer.consentOptIn"
                render={({ field: { onChange, value } }) => {
                  return (
                    <ConsentCheckbox
                      onChange={onChange}
                      value={value}
                      store={store}
                      className={styles.consentCheckbox}
                    />
                  );
                }}
              />
            </div>
          )}
        </div>
      )}

      <div className={styles.optionalInfo}></div>
      <Controller
        control={control}
        name="appointmentCustomer.comments"
        render={({ field: { onChange, value } }) => {
          return (
            <TextArea
              label="Additional comments"
              additionalLabel="optional"
              isCharacterCounter
              value={value}
              onChange={onChange}
              rows={3}
              maxCharactersCount={350}
              isGrayTheme={isAppWhiteBackground}
            />
          );
        }}
      />
      {showCouponCode() && (
        <Controller
          control={control}
          name="appointmentCustomer.coupon"
          render={({ field: { onChange, value } }) => {
            return (
              <TextInput
                inputClassName={styles.inputClassName}
                label="Coupon code"
                additionalLabel="optional"
                isCharacterCounter
                value={value}
                onChange={onChange}
                className={styles.coupon}
                maxLength={150}
                isGrayTheme={isAppWhiteBackground}
              />
            );
          }}
        />
      )}
    </div>
  );
};

ContactForm.propTypes = {
  control: PropTypes.object,
};

export default ContactForm;
