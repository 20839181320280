import React, { useEffect, useRef } from "react";
import cn from "classnames";

import AddToCalendar from "./components/AddToCalendar/AddToCalendar";
import styles from "./SuccessPage.scss";
import { SuccessIcon, ArrowMapIcon, PhoneIcon2 } from "../../svg-icons";
import {
  getSuccessPageText,
  getFixedTopBarHeight,
  showAddToCalendar,
  isCallRailSwap,
  showGetDirectionsButton,
  getWidgetInPopup,
  getSource,
} from "../../services/config.service";
import { formatPhoneNumber, showInGoogleMaps } from "../../utils";
import SectionSummary from "../Summary/SectionSummary/SectionSummary";
import Button from "../../components/Button/Button";
import ClosePopupButton from "../../components/ClosePopupButton/ClosePopupButton";
import {
  getAllShopLocations,
  getAppointmentTimeSlot,
  getServicesSelectedValue,
  getShopDetailsValue,
  getShopId,
  getSummary,
  getVehicleSectionValue,
  useRedux,
} from "../../redux";
import { SOURCES } from "../../constants";

export default function SuccessPage({ store }) {
  const [state] = useRedux(store);
  const shopId = getShopId(state);
  const shopDetails = getShopDetailsValue(state);
  const timeSlot = getAppointmentTimeSlot(state);
  const selectedServices = getServicesSelectedValue(state);
  const vehicleName = getVehicleSectionValue(state);
  const summary = getSummary(state);
  const source = getSource();
  const selectedServicesNames = selectedServices.map((service) => service.title).join("\n");
  const isGoogleSource = source === SOURCES.GOOGLE;

  const { name, isAutoAcceptEnabled, phone, city, address1, stateAbbr, zipCode } =
    shopDetails || {};
  const { start, end } = timeSlot || {};
  const { longitude, latitude } = getAllShopLocations(state).find((l) => l.shopId === shopId) || {};

  const calendarEvent = {
    name: `Appointment at ${name}`,
    icsLocation: [address1, stateAbbr, city].filter(Boolean).join("\\, "),
    location: [address1, stateAbbr, city].filter(Boolean).join(", "),
    details: `${vehicleName}\n\nServices:\n${selectedServicesNames}`,
    startsAt: start,
    endsAt: end,
  };

  const isConfirmed = isAutoAcceptEnabled || isGoogleSource;
  const isWidgetPopup = getWidgetInPopup();

  const { vehicle, service, dateTime, dropoffType, servicesComments } = summary;
  const title = isConfirmed
    ? "Your Appointment Is Confirmed!"
    : "Your Appointment Request Has Been Received.";
  const defaultSuccessPageText = isConfirmed
    ? "Your appointment request has been confirmed. Please, contact us if anything changes."
    : "Your request has been sent. Please look out for confirmation of your appointment request.";

  const successPageText = getSuccessPageText() || defaultSuccessPageText;
  const subtitle = isConfirmed ? (
    <span>
      Thank you. Looking forward to seeing you on <br />
      <br />
      <span className={styles.dateTime}>{dateTime}</span>
    </span>
  ) : (
    <span>
      Thank you. Your <span className={styles.dateTime}>{dateTime}</span> appointment is not yet
      confirmed.
      <br />
      We will send an email to confirm or find a new time.
    </span>
  );

  const location = `${city || ""}${city ? "," : ""} ${stateAbbr || ""} ${zipCode || ""}`.trim();
  const shopLocation = (
    <span className={styles.shopLocation}>
      {name}
      <br />
      {address1 || ""}
      {address1 && location ? "," : ""}
      <br />
      {location}
    </span>
  );

  useEffect(() => {
    const pageScroll = window.parent.scrollY;
    const frameOffsetTop = window.frameElement
      ? window.frameElement.getBoundingClientRect().top + pageScroll
      : 0;
    const fixedTopBarHeight = getFixedTopBarHeight() || 0;

    if (frameOffsetTop - fixedTopBarHeight < pageScroll) {
      window.parent.scrollTo({
        top: frameOffsetTop - fixedTopBarHeight,
      });
    }
  }, []);

  const refPhoneNumber = useRef(null);
  useEffect(() => {
    if (isCallRailSwap() && window.CallTrk && phone) {
      window.CallTrk.swap(refPhoneNumber.current);
    }
  }, [shopDetails]);

  return (
    <div className={cn(styles.app, { [styles.popupStyles]: isWidgetPopup })}>
      {isWidgetPopup && (
        <div className={styles.closePopupIconWrapper}>
          <ClosePopupButton />
        </div>
      )}

      <div className={styles.container}>
        <div>
          <SuccessIcon className={styles.icon} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>
          <div className={styles.subtitle}>{subtitle}</div>
          <div className={styles.buttons}>
            {!!showGetDirectionsButton() && !!latitude && !!longitude && (
              <Button
                className={styles.directionButton}
                title={
                  <div className={styles.directionButtonTitle}>
                    <ArrowMapIcon />
                    <div>Get Direction</div>
                  </div>
                }
                onClick={() => showInGoogleMaps(latitude, longitude)}
              />
            )}
            {showAddToCalendar() && (
              <AddToCalendar event={calendarEvent} filename="repair-shop-appointment" />
            )}
          </div>
          <div className={styles.shopText}>
            {/*TODO removed by FRAME-1201  - item 18 (4) */}
            {/*{!isConfirmed && <div className={styles.line}></div>}*/}
            {successPageText}
          </div>
        </div>
        <div className={styles.summaryWrapper}>
          <div className={styles.summary}>
            <h3>Appointment Summary:</h3>
            <div className={styles.summaryList}>
              <div className={styles.wrapper}>
                <SectionSummary section="Location" value={shopLocation} />
                <SectionSummary section="Date and Time" value={dateTime} />
              </div>
              <div className={styles.wrapper}>
                <SectionSummary section="Type of Appointment" value={dropoffType} />
              </div>
              <div className={styles.wrapper}>
                <SectionSummary section="Vehicle" value={vehicle} />
                <SectionSummary
                  section="Service(s)"
                  value={service}
                  servicesComments={servicesComments}
                />
              </div>
            </div>
          </div>
        </div>
        {phone && (
          <span className={styles.contact}>
            If you need assistance, please call us at:{" "}
            <span ref={refPhoneNumber} className={styles.phone}>
              <a href={`tel:${formatPhoneNumber(phone)}`}>
                <PhoneIcon2 className={styles.iconPhone} />
                {formatPhoneNumber(phone)}
              </a>
            </span>
          </span>
        )}
      </div>
    </div>
  );
}
