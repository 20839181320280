import cn from "classnames";
import styles from "./MainHeader.scss";
import Typography from "../../components/Typography/Typography";
import { formatPhoneNumberToUsLocale } from "../../utils";
import { MobilePhoneIcon } from "../../svg-icons";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";

export default function MainHeader({ className, shop = {}, title }) {
  const generateAddressString = (address1, city, stateAbbr, zipCode) => {
    const addressString = address1 ? `${address1}, ` : "";
    const cityString = city ? `${city}, ` : "";
    const stateAbbrString = stateAbbr ? `${stateAbbr} ` : "";
    const zipCodeString = zipCode ? zipCode : "";
    let result = `${addressString}${cityString}${stateAbbrString}${zipCodeString}`.trim();
    if (result.at(-1) === ",") {
      result = result.substring(0, result.length - 1);
    }
    return result;
  };

  const { name, stateAbbr, city, zipCode, address1, phone, logoPath } = shop;
  const address = generateAddressString(address1, city, stateAbbr, zipCode);

  return (
    <div className={cn(styles.header, className)}>
      <div className={cn("max-w-1630 m-auto w-full", styles.headerContent)}>
        <div className={styles.left}>
          <CompanyAvatar
            className={styles.companyLogo}
            avatar={logoPath}
            defaultLogoSizePercent={0.6}
            hasBorder
          />
          <h1>{title}</h1>
          <div className={styles.label}>
            <Typography color="primary" fontSize="xl-2" fontWeight="bold" ellipsis>
              {name}
            </Typography>
            <Typography
              className={styles.address}
              fontSize="xs"
              fontWeight="medium"
              color="tertiary"
              ellipsis
            >
              {address}
            </Typography>
            {!!phone && (
              <a href={`tel:${phone}`} className={styles.phoneRef}>
                <div className={styles.phoneMobileScreen}>
                  <Typography className={styles.phoneNumber} fontSize="xs" fontWeight="medium">
                    {formatPhoneNumberToUsLocale(phone)}
                  </Typography>
                </div>
              </a>
            )}
          </div>
        </div>
        {!!phone && (
          <a href={`tel:${phone}`} className={styles.phoneRef}>
            <div className={styles.phone}>
              <MobilePhoneIcon />
              <Typography
                className={styles.phoneNumber}
                color="primary"
                fontSize="xl-2"
                fontWeight="bold"
              >
                {formatPhoneNumberToUsLocale(phone)}
              </Typography>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}
