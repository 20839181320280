import React, { memo } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import styles from "./RequestResult.scss";

const RequestResult = memo(
  ({ image, title, message, className, wrapperClassName, titleClassname, height }) => {
    return (
      <div className={cn(styles.noDataWrapper, wrapperClassName)} style={{ height }}>
        <div className={cn(className, styles.noData)}>
          {typeof image === "string" ? <img src={image} alt="no-data" /> : image}
          {title && <div className={cn(styles.title, titleClassname)}>{title}</div>}
          {message && <div className={styles.message}>{message}</div>}
        </div>
      </div>
    );
  },
);
RequestResult.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleClassname: PROP_TYPES_TEMPLATES.CLASS_NAME,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  wrapperClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  height: PropTypes.number,
};
export default RequestResult;
