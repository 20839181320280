import { useCallback, useState } from "react";

const useAsync = ({ asyncFunction, finallyFunction, successFunction, failedFunction, payload }) => {
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState({});
  const [errorStatusCode, setErrorStatusCode] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const execute = async (...cbPayload) => {
    setErrors({});
    setErrorStatusCode(null);

    try {
      setLoading(true);
      const response = asyncFunction && (await asyncFunction(...cbPayload));
      // if (response.status === "Fail") {
      //   setErrorStatusCode(response.data.status);
      //   setErrors(response.data.errors);
      //   return;
      // }
      setData(response);
      successFunction && successFunction(response, ...cbPayload);
    } catch (error) {
      console.log(error);
      setErrorStatusCode(error.data?.status);
      setErrors(error?.data?.errors);
      failedFunction && failedFunction(error, ...cbPayload);
    } finally {
      setLoading(false);
      finallyFunction && finallyFunction(...cbPayload);
    }
  };

  const refetch = useCallback((...cbPayload) => {
    setLoading(true);
    // if refetch called without payload useFetch uses original payload from closure;
    execute(...(cbPayload || payload));
  }, []);

  return {
    data,
    errors,
    errorStatusCode,
    isLoading,
    execute,
    refetch,
  };
};

export default useAsync;
