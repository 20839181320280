import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";

import {
  getCountryCallingCode,
  getExampleNumber,
  AsYouType,
  getPhoneCode,
} from "libphonenumber-js";

import { CountryFlag, CountriesList } from "./components";
import {
  parseNumberData,
  generateRawCountries,
  formatPhoneNumberToNational,
  getPhoneWithCountryCode,
  keepCursor,
} from "./PhoneInput.utils";
import { ArrowDownIcon } from "../../../svg-icons";
import styles from "./PhoneInput.scss";
import countriesName from "./countriesName.js";
import InputWrapper from "../InputWrapper";
import InputError from "../InputError/InputError";
import { PROP_TYPES_TEMPLATES } from "../../../constants";
import PropTypes from "prop-types";
import useShowInputError from "../../../hooks/useShowInputError";

const examples = { US: "(XXX) XXX-XXXX", CA: "(XXX) XXX-XXXX" };

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};
const PhoneInput = ({
  value: propsValue = "",
  name,
  onChange,
  onBlur,
  onFocus,
  defaultCountry = "US",
  className,
  disabled,
  error,
  label,
  additionalLabel,
  withButton,
  buttonComponent,
  isGrayTheme = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [value, setValue] = useState("");
  const phoneInput = useRef();

  const { isShowError, isShowTooltip, setIsShowError, validationErrorMessage, serverErrorMessage } =
    useShowInputError(error);

  useEffect(() => {
    // allow to reset value from parent component;
    if (!propsValue) {
      setValue("");
      return;
    }

    const tempNumber = typeof propsValue === "number" ? propsValue.toString() : propsValue;
    // parseNumberData() can't parse number with length less than 3
    if (tempNumber && tempNumber.length > 3) {
      const phoneWithPlus = getPhoneWithCountryCode(tempNumber, selectedCountry);
      const { phoneNumber } = parseNumberData(phoneWithPlus);
      const formattedPhoneNumber = formatPhoneNumberToNational(phoneNumber, selectedCountry);

      if (formattedPhoneNumber !== value) {
        setValue(formattedPhoneNumber);
        keepCursor(phoneInput.current, formattedPhoneNumber);
      }
    }
  }, [propsValue]);

  useEffect(() => {
    const tempNumber = typeof propsValue === "number" ? propsValue.toString() : propsValue;
    // parseNumberData() can't parse number with length less than 3
    if (tempNumber && tempNumber.length > 3) {
      const phoneWithPlus = getPhoneWithCountryCode(tempNumber, selectedCountry);
      const { country } = parseNumberData(phoneWithPlus);

      setSelectedCountry(country || defaultCountry);
    }
  }, []);

  const handleFocus = () => {
    setIsShowError(false);
    onFocus && onFocus();
  };
  const handleBlur = () => {
    setIsShowError(true);
    if (!value) {
      onInputChange("");
    }

    if (!isSafari() && !!value && typeof value === "string") onInputChange(value?.trim());
    onBlur && onBlur();
  };

  function onInputChange(value) {
    if (!value) {
      onChange && onChange(value);
      setValue(value);
      return;
    }

    if (value && !value?.match(/^[\d() -]+$/g)) {
      return;
    }

    const formattedIncompleteValue = formatPhoneNumberToNational(value, selectedCountry);

    const formattedValue = `+${getPhoneCode(selectedCountry)}${formattedIncompleteValue}`;

    const asYouType = new AsYouType();
    asYouType.input(formattedValue);

    const plainNumber = asYouType.getNumber()?.number;

    if (plainNumber?.length >= 13) return;

    setValue(formattedIncompleteValue);
    onChange && onChange(plainNumber);

    keepCursor(phoneInput.current, formattedIncompleteValue);
  }

  function onSelectCountry(iso2) {
    setSelectedCountry(iso2);
    setValue("");
    setIsOpen(false);
  }

  const reorderedCountries = generateRawCountries(countriesName);

  return (
    <InputWrapper
      className={className}
      label={label}
      isError={isShowError && (serverErrorMessage || validationErrorMessage)}
      value={value}
      disabled={disabled}
      additionalLabel={additionalLabel}
      withButton={withButton}
      buttonComponent={buttonComponent}
      onClear={() => onChange("")}
    >
      <div className={styles.phoneInputWrapper}>
        <div
          className={cn(styles.phoneInputContainer, {
            [styles.grayTheme]: isGrayTheme,
            [styles.isError]: serverErrorMessage || (isShowError && validationErrorMessage),
            [styles.isDisabled]: disabled,
          })}
        >
          <div
            className={cn(styles.phoneInputButton, "countries-list-toggle")}
            onClick={() => !disabled && setIsOpen(!isOpen)}
          >
            <CountryFlag countryIso2={selectedCountry} />
            <div className={styles.code}>+{getCountryCallingCode(selectedCountry)}</div>
            <div
              className={cn(styles.arrow, {
                [styles.isArrowRotate]: isOpen,
              })}
            >
              <ArrowDownIcon />
            </div>
          </div>
          <input
            ref={phoneInput}
            className={cn(styles.phoneInput)}
            placeholder={getExampleNumber(selectedCountry, examples).formatNational()}
            value={value}
            name={name}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={(e) => onInputChange(e.target.value)}
          />
          {isOpen && (
            <CountriesList
              countriesList={reorderedCountries}
              onSelectCountry={onSelectCountry}
              selectedCountry={selectedCountry}
              onOutsideClick={() => setIsOpen(false)}
              outsideClickIgnoreClass="countries-list-toggle"
            />
          )}
          <InputError
            className={cn(styles.error)}
            isOpen={isShowTooltip}
            errorMessage={serverErrorMessage || validationErrorMessage}
          />
        </div>
      </div>
    </InputWrapper>
  );
};

PhoneInput.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  additionalLabel: PROP_TYPES_TEMPLATES.LABEL,
  error: PROP_TYPES_TEMPLATES.ERROR,
  name: PropTypes.string,
  value: PropTypes.string,
  defaultCountry: PropTypes.string,
  disabled: PropTypes.bool,
  withButton: PropTypes.bool,
  isGrayTheme: PropTypes.bool,
  buttonComponent: PROP_TYPES_TEMPLATES.COMPONENT,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default PhoneInput;
