export function getAdditionalComment(comments, isLoanerVehicleAllowed, isLoanerVehicleNeeded) {
  if (comments || isLoanerVehicleAllowed) {
    const comment = `Additional Comment: ${comments}` + "\n";
    const loanerVehicleLabel = isLoanerVehicleNeeded
      ? "Loaner vehicle is needed."
      : "Loaner vehicle is not needed.";
    return isLoanerVehicleAllowed ? `${comment}${loanerVehicleLabel}` + "\n" : comment;
  }
  return "";
}

export function getCoupon(coupon) {
  if (coupon) {
    return `Coupon:` + "\n" + `${coupon}` + "\n";
  }
  return "";
}

export function getServComments(appointmentDetails, allAvailableServices) {
  let comments = appointmentDetails.services.map((id) => {
    return (
      `Service: ${allAvailableServices.find((el) => el.serviceId === id).title}` +
      "\n" +
      `Comment: ${
        appointmentDetails.servicesComments[id] ? appointmentDetails.servicesComments[id] : " - "
      }` +
      "\n\n"
    );
  });
  return comments.reduce((acc, commentService) => acc + commentService);
}
