import { AppointmentType, SOURCES, VERIFICATION_METHOD_TYPES } from "../constants";
import {
  fetchShopServices,
  fetchShopVehicleMakes,
  fetchShopVehicleModels,
  fetchShopVehicleYears,
  fetchShopDetails,
  getTimeSlots,
  submitAppointment,
  fetchWidgetSettings,
  fetchTenantWidgetSettings,
  fetchShopsDetails,
  fetchWidgetSettingsByShopId,
  decryptPublicToken,
  decryptToken as decryptTokenRequest,
  decryptCampaignToken,
} from "../services/mechanic-api.client";
import {
  getToken,
  getIsTenantWidget,
  getIsPlazaTire,
  getTenantId,
  getCampaignId,
  getMarketingTypeId,
  getFetchSettingsFromPublicApi,
  getShops,
  getSource,
  getCToken,
} from "../services/config.service";
import {
  getVehicleInfo,
  getAllComments,
  getAppointmentTitle,
  getAppointmentAllowedStatuses,
} from "./selectors";
import { addPlusOneToPhone, getOnlyDigits, joinFullName } from "../utils/index";
import { endOfMonth, parseISO, startOfMonth } from "date-fns";
import cloneDeep from 'lodash/cloneDeep';

export const STEP_CHANGED = "STEP_CHANGED";
export const VEHICLE_VALUE_CHANGED = "VEHICLE_VALUE_CHANGED";
export const DATE_CHANGED = "DATE_CHANGED";
export const TIME_CHANGED = "TIME_CHANGED";
export const ADD_SERVICE = "ADD_SERVICE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";

export const APPOINTMENT_TYPE_CHANGED = "APPOINTMENT_TYPE_CHANGED";
export const CONTINUE_TO_APPOINTMENT = "CONTINUE_TO_APPOINTMENT";
export const CONTINUE_TO_CONTACT = "CONTINUE_TO_CONTACT";
export const REQUEST_APPOINTMENT_SUCCESS = "REQUEST_APPOINTMENT_SUCCESS";
export const REDIRECT_TO_SUCCESS_PAGE = "REDIRECT_TO_SUCCESS_PAGE";
export const REQUEST_APPOINTMENT_ERROR = "REQUEST_APPOINTMENT_ERROR";
export const BACK_TO_DETAILS = "BACK_TO_DETAILS";
export const FORM_UPDATED = "FORM_UPDATED";
export const COMMENT_ADDED = "COMMENT_ADDED";

export const SHOP_ID_LOADED = "SHOP_ID_LOADED";
export const SHOP_ID_CHANGED = "SHOP_ID_CHANGED";
export const FETCH_TIME_FOR_MONTH = "FETCH_TIME_FOR_MONTH";
export const MONTH_CHANGED = "MONTH_CHANGED";
export const DATE_RESET = "DATE_RESET";
export const CONTINUE_TO_VEHICLE_AND_SERVICE = "CONTINUE_TO_VEHICLE_AND_SERVICE";
export const BACK_TO_VEHICLE = "BACK_TO_VEHICLE";

export const FETCH_SHOP_DETAILS_PENDING = "FETCH_SHOP_DETAILS_PENDING";
export const FETCH_SHOP_DETAILS_FULFILLED = "FETCH_SHOP_DETAILS_FULFILLED";

export const FETCH_VEHICLE_YEARS_PENDING = "FETCH_VEHICLE_YEARS_PENDING";
export const FETCH_VEHICLE_YEARS_FULFILLED = "FETCH_VEHICLE_YEARS_FULFILLED";

export const FETCH_VEHICLE_MAKES_PENDING = "FETCH_VEHICLE_MAKES_PENDING";
export const FETCH_VEHICLE_MAKES_FULFILLED = "FETCH_VEHICLE_MAKES_FULFILLED";

export const FETCH_VEHICLE_MODELS_PENDING = "FETCH_VEHICLE_MODELS_PENDING";
export const FETCH_VEHICLE_MODELS_FULFILLED = "FETCH_VEHICLE_MODELS_FULFILLED";

export const FETCH_SERVICES_PENDING = "FETCH_SERVICES_PENDING";
export const FETCH_SERVICES_FULFILLED = "FETCH_SERVICES_FULFILLED";

export const FETCH_TIME_SLOTS_PENDING = "FETCH_TIME_SLOTS_PENDING";
export const FETCH_TIME_SLOTS_FULFILLED = "FETCH_TIME_SLOTS_FULFILLED";
export const INIT = "INIT";
export const SHOW_LOCATION = "SHOW_LOCATION";
export const FETCH_ALL_SHOP_DETAILS_PENDING = "FETCH_ALL_SHOP_DETAILS_PENDING";
export const FETCH_ALL_SHOP_DETAILS_FULFILLED = "FETCH_ALL_SHOP_DETAILS_FULFILLED";

export const SHOW_FORM_ERROR = "SHOW_FORM_ERROR";
export const REQUEST_APPOINTMENT_PENDING = "REQUEST_APPOINTMENT_PENDING";
export const REDIRECT_TO_ERROR_PAGE = "REDIRECT_TO_ERROR_PAGE";

export const FETCH_WIDGET_SETTINGS_PENDING = "FETCH_WIDGET_SETTINGS_PENDING";
export const FETCH_WIDGET_SETTINGS_FULFILLED = "FETCH_WIDGET_SETTINGS_FULFILLED ";
export const FETCH_WIDGET_SETTINGS_ERROR = "FETCH_WIDGET_SETTINGS_ERROR";

export const SHOW_REVIEWS = "SHOW_REVIEWS";
export const SHOW_APPOINTMENT = " SHOW_APPOINTMENT";
export const TOGGLE_APPOINTMENT_SUMMARY = "TOGGLE_APPOINTMENT_SUMMARY";
export const SAVE_SELECTED_CUSTOMER = "SAVE_SELECTED_CUSTOMER";
export const SAVE_LOOKUP_CUSTOMER = "SAVE_LOOKUP_CUSTOMER";
export const SAVE_CUSTOMER_DATA_TO_VERIFY = "SAVE_CUSTOMER_DATA_TO_VERIFY";
export const MATCH_CUSTOMERS_FULFILLED = "MATCH_CUSTOMERS_FULFILLED";
export const SET_DISCLAIMER_TEXT_WITH_TOKENS = "SET_DISCLAIMER_TEXT_WITH_TOKENS";
export const SET_VEHICLES_LIST = "SET_VEHICLES_LIST";
export const SET_VEHICLE = "SET_VEHICLE";
export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const SET_VERIFICATION_METHOD = "SET_VERIFICATION_METHOD";
export const SET_GUEST_FLOW = "SET_GUEST_FLOW";
export const HIDE_CONTACT_CONSENT = "HIDE_CONTACT_CONSENT";
export const SET_NEW_VEHICLE_SELECTED = "SET_NEW_VEHICLE_SELECTED";

export const DECRYPT_TOKEN_PENDING = "DECRYPT_TOKEN_PENDING";
export const DECRYPT_TOKEN_FULFILLED = "DECRYPT_TOKEN_FULFILLED ";
export const DECRYPT_TOKEN_ERROR = "DECRYPT_TOKEN_ERROR";

export function decryptToken(token, cToken) {
  const isFetchSettingsFromPublicApi = getFetchSettingsFromPublicApi();
  return async function (dispatch) {
    dispatch({ type: DECRYPT_TOKEN_PENDING });

    try {
      let response;
      if (cToken) {
        response = await decryptCampaignToken(cToken);
      } else {
        response = isFetchSettingsFromPublicApi
          ? await decryptPublicToken(token)
          : await decryptTokenRequest(token);
      }

      dispatch({
        type: DECRYPT_TOKEN_FULFILLED,
        payload: response,
      });
    } catch (e) {
      dispatch({ type: DECRYPT_TOKEN_ERROR });
      console.error(e);
    }
  };
}

export function getWidgetSettings() {
  return async function (dispatch, getState) {
    const token = getToken();
    const cToken = getCToken();
    const source = getSource();
    const isTenantWidget = getIsTenantWidget();
    const isFetchSettingsFromPublicApi = getFetchSettingsFromPublicApi();
    const isPublicSource = source === SOURCES.PUBLIC_VEHICLE || source === SOURCES.CAMPAIGNS;
    dispatch({ type: FETCH_WIDGET_SETTINGS_PENDING });
    try {
      let response = null;
      if (isFetchSettingsFromPublicApi || isPublicSource || cToken) {
        const { shopId } = getState();
        response = await fetchWidgetSettingsByShopId(shopId);
      } else {
        response = isTenantWidget
          ? await fetchTenantWidgetSettings(token)
          : await fetchWidgetSettings(token);
      }
      dispatch({
        type: FETCH_WIDGET_SETTINGS_FULFILLED,
        payload: response,
      });
    } catch (e) {
      dispatch({ type: FETCH_WIDGET_SETTINGS_ERROR });
      console.error(e);
    }
  };
}

export function getShopDetailsForAllAvailableShops(shopIds) {
  return async function (dispatch) {
    const tenantId = getTenantId();
    dispatch({ type: FETCH_ALL_SHOP_DETAILS_PENDING });
    const response = await fetchShopsDetails(shopIds, tenantId);
    dispatch({
      type: FETCH_ALL_SHOP_DETAILS_FULFILLED,
      payload: { shopDetails: response.data.shops },
    });
  };
}

export function setShopId() {
  return function (dispatch) {
    const shops = getShops();
    if (shops?.length > 1) {
      Promise.all([
        dispatch({ type: SHOW_LOCATION }),
        dispatch(getShopDetailsForAllAvailableShops(shops)),
      ]).then(console.log("DONE"));
    }
    if (shops?.length) {
      dispatch({ type: SHOP_ID_LOADED, payload: { shopId: shops[0], shops } });
    }
  };
}

export function changeShopId(shopId) {
  return async function (dispatch, getState) {
    await Promise.all([
      dispatch({
        type: SHOP_ID_CHANGED,
        payload: { shopId },
      }),
    ]).then(dispatch({ type: INIT }));
    const appointmentType = getInitialAppType(getState);
    dispatch(setAppointmentType(appointmentType));
  };
}

export function getShopDetails() {
  return async function (dispatch, getState) {
    const { shopId } = getState();
    dispatch({ type: FETCH_SHOP_DETAILS_PENDING });
    const response = await fetchShopDetails(shopId);

    const {
      shopDetails,
      isWaitingEnabled,
      isDroppingOffEnabled,
      isAutoAcceptEnabled,
      autoOpsIntegrationDetails,
    } = response?.data || {};
    dispatch({
      type: FETCH_SHOP_DETAILS_FULFILLED,
      payload: {
        shopDetails: {
          ...shopDetails,
          isWaitingEnabled,
          isDroppingOffEnabled,
          isAutoAcceptEnabled,
          autoOpsIntegrationDetails,
          name: shopDetails?.locationNumber
            ? `${shopDetails.locationNumber} / ${shopDetails.name}`
            : shopDetails?.name,
        },
      },
    });

    const [isWaitingTypeAllowed, isDropOffTypeAllowed] = [isWaitingEnabled, isDroppingOffEnabled];
    let appointmentType = AppointmentType.Waiting; //default
    if (isDropOffTypeAllowed && !isWaitingTypeAllowed) {
      appointmentType = AppointmentType.Dropoff;
    } else {
      appointmentType = AppointmentType.Waiting;
    }
    dispatch(setAppointmentType(appointmentType));
  };
}

export function setVehicle(vehicle) {
  return async function (dispatch) {
    dispatch({ type: SET_VEHICLE, payload: vehicle });
  };
}

export function setVehicleValue(field, value) {
  return async function (dispatch, getState) {
    dispatch({
      type: VEHICLE_VALUE_CHANGED,
      payload: { field, value },
    });
    if (field === "year") {
      dispatch({ type: FETCH_VEHICLE_MAKES_PENDING });
      const state = getState();
      const result = await fetchShopVehicleMakes(state.shopId, getVehicleInfo(state).year);
      const {
        data: { makes },
      } = result;

      dispatch({ type: FETCH_VEHICLE_MAKES_FULFILLED, payload: { makes } });
    }

    if (field === "make") {
      dispatch({ type: FETCH_VEHICLE_MODELS_PENDING });
      const state = getState();
      const vehicleInfo = getVehicleInfo(state);
      const result = await fetchShopVehicleModels(state.shopId, vehicleInfo.year, vehicleInfo.make);
      const {
        data: { models },
      } = result;
      dispatch({
        type: FETCH_VEHICLE_MODELS_FULFILLED,
        payload: { models },
      });
    }
  };
}

export function addSelectedService(id) {
  return function (dispatch) {
    dispatch({
      type: ADD_SERVICE,
      payload: { id },
    });
  };
}

export function removeSelectedService(id) {
  return function (dispatch) {
    dispatch({
      type: REMOVE_SERVICE,
      payload: { id },
    });
  };
}

export function setAppointmentType(type) {
  return function (dispatch) {
    dispatch({ type: APPOINTMENT_TYPE_CHANGED, payload: { type } });
    dispatch(setMonthAndLoadTimeSlots(new Date()));
    Promise.all([dispatch(fetchVehicleYears()), dispatch(setAvailableServices())]).then(
      console.log("configured"),
    );
  };
}

export function continueToContact() {
  return {
    type: CONTINUE_TO_CONTACT,
  };
}

export function setAvailableServices() {
  return async function (dispatch, getState) {
    dispatch({ type: FETCH_SERVICES_PENDING });
    const state = getState();
    const result = await fetchShopServices(state.shopId);
    const {
      data: { services },
    } = result;

    //TODO: remove, temporary for Plaza Tire
    const isPlazaTire = getIsPlazaTire();
    if (isPlazaTire) {
      const tirePurchaseService = services.find((s) =>
        s.title.toLowerCase().includes("tire purchase"),
      );

      if (tirePurchaseService) {
        services.splice(services.indexOf(tirePurchaseService), 1);
        services.unshift(tirePurchaseService);

        dispatch({
          type: FETCH_SERVICES_FULFILLED,
          payload: { services },
        });

        return;
      }
    }

    dispatch({
      type: FETCH_SERVICES_FULFILLED,
      payload: { services },
    });
  };
}

export function fetchVehicleYears() {
  return async function (dispatch, getState) {
    const { shopId } = getState();
    dispatch({ type: FETCH_VEHICLE_YEARS_PENDING });
    const result = await fetchShopVehicleYears(shopId);
    const {
      data: { years },
    } = result;
    dispatch({ type: FETCH_VEHICLE_YEARS_FULFILLED, payload: { years } });
  };
}

export function setDate(date) {
  return function (dispatch) {
    dispatch(resetDate());
    dispatch({ type: DATE_CHANGED, payload: { date } });
  };
}

export function resetDate() {
  return function (dispatch) {
    dispatch({ type: DATE_RESET });
  };
}

export function setMonthAndLoadTimeSlots(month) {
  return function (dispatch) {
    dispatch(setMonth(month));
    dispatch(loadTimeSlotsForSelectedMonth(month));
    dispatch(resetDate());
  };
}

export function setMonth(month) {
  return function (dispatch) {
    dispatch({ type: MONTH_CHANGED, payload: { month } });
  };
}

export function setContactInfo(data) {
  return function (dispatch) {
    dispatch({ type: SET_CONTACT_INFO, payload: data });
  };
}

export function setVerificationMethod(data) {
  return function (dispatch) {
    dispatch({ type: SET_VERIFICATION_METHOD, payload: data });
  };
}

export function loadTimeSlotsForSelectedMonth(month) {
  const [currentMonth, currentYear] = [month.getMonth() + 1, month.getFullYear()];

  const startDay = startOfMonth(month);
  const dateFrom = `${currentYear} ${currentMonth} ${startDay.getDate()}`;

  const endDay = endOfMonth(month);
  const dateTo = `${endDay.getFullYear()} ${endDay.getMonth() + 1} ${endDay.getDate()}`;

  return async function (dispatch, getState) {
    const { appointmentDetails } = getState();
    dispatch({ type: FETCH_TIME_SLOTS_PENDING });
    const timeSlots = await getTimeSlots(getState().shopId, appointmentDetails.appointmentType, {
      dateFrom,
      dateTo,
    });

    if (timeSlots && timeSlots.AvailableIntervals.length > 0) {
      dispatch({ type: FETCH_TIME_FOR_MONTH, payload: { timeSlots } });
      const { shopOptions } = getState();
      const initDate = parseISO(shopOptions.timeSlots[0].start);
      dispatch(setMonth(initDate));
    }
    dispatch({ type: FETCH_TIME_SLOTS_FULFILLED });
  };
}

export function saveSelectedCustomer(customer) {
  return async function (dispatch) {
    dispatch({ type: SAVE_SELECTED_CUSTOMER, payload: customer });
  };
}

export function saveLookupCustomer(customer) {
  return async function (dispatch) {
    dispatch({ type: SAVE_LOOKUP_CUSTOMER, payload: customer });
  };
}

export function updateLookupCustomer(customer) {
  return async function (dispatch, getState) {
    const { lookupCustomer } = getState();
    const newCustomerObject = { ...cloneDeep(lookupCustomer), ...customer };
    dispatch({ type: SAVE_LOOKUP_CUSTOMER, payload: newCustomerObject });
  };
}


export function saveCustomerDataToVerify(data) {
  return async function (dispatch) {
    dispatch({ type: SAVE_CUSTOMER_DATA_TO_VERIFY, payload: data });
  };
}

export function saveMatchedCustomers(customers) {
  return async function (dispatch) {
    dispatch({ type: MATCH_CUSTOMERS_FULFILLED, payload: customers });
  };
}

export function setDisclaimerTextWithReplacedTokens(text) {
  return async function (dispatch) {
    dispatch({ type: SET_DISCLAIMER_TEXT_WITH_TOKENS, payload: text });
  };
}

export function setVehiclesList(vehicles) {
  return async function (dispatch) {
    dispatch({ type: SET_VEHICLES_LIST, payload: vehicles });
  };
}
export function setGuestFlow(value) {
  return async function (dispatch) {
    dispatch({ type: SET_GUEST_FLOW, payload: value });
  };
}

export function hideContactConsent() {
  return async function (dispatch) {
    dispatch({ type: HIDE_CONTACT_CONSENT });
  };
}

export function setNewVehicleSelected(value) {
  return async function (dispatch) {
    dispatch({ type: SET_NEW_VEHICLE_SELECTED, payload: value });
  };
}

export function setTime(timeSlot) {
  return function (dispatch) {
    delete timeSlot.enabled;
    dispatch({ type: TIME_CHANGED, payload: { timeSlot } });
  };
}

export function addCommentToService(comments) {
  return {
    type: COMMENT_ADDED,
    payload: { comments },
  };
}

function getInitialAppType(getState) {
  const state = getState();
  const [isWaitingTypeAllowed, isDropOffTypeAllowed] = getAppointmentAllowedStatuses(state);

  if (isDropOffTypeAllowed && !isWaitingTypeAllowed) {
    return AppointmentType.Dropoff;
  } else {
    return AppointmentType.Waiting;
  }
}

export function init() {
  return async function (dispatch, getState) {
    await Promise.all([dispatch(setShopId()), dispatch(getShopDetails())]).then(
      dispatch({ type: INIT }),
    );

    const appointmentType = getInitialAppType(getState);
    dispatch(setAppointmentType(appointmentType));
  };
}

export function showReviews() {
  return {
    type: SHOW_REVIEWS,
  };
}

export function requestAppointment(source, isLoanerVehicleAllowed) {
  return async function (dispatch, getState) {
    const { appointmentDetails, shopId, shopDetails, lookupCustomer,
      otpVerificationChannel, isContactConsentShown, campaignId, marketingTypeId } =
      getState();
    const campaignIdFromPublicVehicle = getCampaignId();
    const marketingTypeIdFromPublicVehicle = getMarketingTypeId();
    const campaignIdToSubmit = campaignId || campaignIdFromPublicVehicle || null;
    const marketingTypeIdToSubmit = marketingTypeId || marketingTypeIdFromPublicVehicle || null;
    const customerId = lookupCustomer?.customerId;
    const {
      vehicle: { year, make, model, serviceObjectId },
      servicesComments,
      contactInfo,
    } = appointmentDetails;

    const titleContactInfoData = contactInfo?.firstName ? contactInfo : lookupCustomer
    const title = getAppointmentTitle(getState(), titleContactInfoData);
    const { isLoanerVehicleNeeded } = contactInfo;
    const details = getAllComments(
      getState(),
      contactInfo,
      isLoanerVehicleAllowed,
      isLoanerVehicleNeeded,
    );

    const appointmentType =
      appointmentDetails.appointmentType === AppointmentType.Dropoff ? "DropOff" : "Wait";

    const phoneWithDigits = getOnlyDigits(contactInfo?.phone);
    const formattedPhoneNumber = addPlusOneToPhone(phoneWithDigits);

    const existingCustomerRequestData = {
      otpVerificationChannel,
      customerId: customerId,
      firstName: "y",
      lastName: "x",
      email: null,
      phone: null,
    };

    const newCustomerRequestData = {
      firstName: contactInfo.firstName.trim(),
      lastName: contactInfo.lastName.trim(),
      email: contactInfo.email,
      phone: formattedPhoneNumber,
    };

    const serializedAppointment = {
      title,
      details,
      services: appointmentDetails.services.map((id) => {
        return { serviceId: id, comment: servicesComments[id] || "" };
      }),
      tenantId: shopDetails.tenantId,
      startedAt: appointmentDetails.dateTime.timeSlot.start,
      endedAt: appointmentDetails.dateTime.timeSlot.end,
      type: appointmentType,
      ...(campaignIdToSubmit ? { campaignId: campaignIdToSubmit } : {}),
      ...(marketingTypeIdToSubmit ? { marketingTypeId: marketingTypeIdToSubmit } : {}),
      ...(source ? { source } : {}),
      appointmentCustomer: {
        ...(customerId ? existingCustomerRequestData : newCustomerRequestData),
        emailMarketingStatus: "Eligible",
        textMarketingStatus: "Eligible",
        customerServiceObject: {
          ...(serviceObjectId ? { serviceObjectId } : {}),
          year: Number(year),
          make,
          model,
        },
      },
      consentOptIn: (() => {
        if (customerId) return true;
        if (!isContactConsentShown) return true; // this mean that consent has been selected on previous steps already;
        return contactInfo.consentOptIn;
      })(),
    };

    dispatch({ type: REQUEST_APPOINTMENT_PENDING });
    const response = await submitAppointment(shopId, serializedAppointment);

    if (response.status >= 400) {
      const body = await response.json();
      throw body;
    } else {
      return response;
    }
  };
}
