import { AppointmentType } from "../constants";
import {
  STEER_API_ROOT as steerApiRoot,
  GOOGLE_BOOK_PARTNER_ID as googleBookPartnerId,
  GOOGLE_BOOK_TRACK_URL as googleBookTrackUrl,
} from "./env.service";
import { sendRequest } from "./api.service";

const MAX_TRIES = 2;

export async function decryptToken(token) {
  const result = await sendRequest({
    url: `/api/v1/identity/token-provider/widget/decrypt/${token}`,
    rootUrl: steerApiRoot,
  });
  return result.json();
}

export async function decryptPublicToken(token) {
  const result = await sendRequest({
    url: `/api/v1/identity/token-provider/service-object/decrypt?token=${token}`,
    rootUrl: steerApiRoot,
  });
  return result.json();
}

export async function decryptCampaignToken(token) {
  const result = await sendRequest({
    url: `/api/v1/identity/token-provider/customers/decrypt/${token}`,
    rootUrl: steerApiRoot,
  });
  return result.json();
}

export async function fetchWidgetSettings(token) {
  const result = await sendRequest({ url: `/api/v1/scheduler-widgets/${token}` });
  return result.json();
}

export async function fetchWidgetSettingsByShopId(shopId) {
  const result = await sendRequest({ url: `/api/v1/scheduler-widgets/${shopId}/public-settings` });
  return result.json();
}

export async function fetchTenantWidgetSettings(token) {
  const result = await sendRequest({ url: `/api/v1/tenant-widgets/${token}` });
  return result.json();
}

export async function fetchShopDetails(shopId) {
  const result = await sendRequest({ url: `/api/v1/appointments-widget/shops/${shopId}/metadata` });
  return result.json();
}

export async function fetchShopsDetails(shopIds, tenantId) {
  const result = await sendRequest({
    url: `/api/v1/tenant-widget/tenants/${tenantId}/shops`,
    method: "PATCH",
    body: JSON.stringify({ shopIds }),
  });
  return result.json();
}

export async function fetchShopVehicleYears(shopId) {
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/service-objects/shops/${shopId}/years?applyAppointmentPreferences=true`,
  });
  return result.json();
}

export async function fetchShopVehicleMakes(shopId, year) {
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/service-objects/shops/${shopId}/years/${year}/makes?applyAppointmentPreferences=true`,
  });
  return result.json();
}

export async function fetchShopVehicleModels(shopId, year, makeId) {
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/service-objects/shops/${shopId}/years/${year}/makes/${makeId}/models?applyAppointmentPreferences=true`,
  });
  return result.json();
}

export async function fetchShopServices(shopId) {
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/shops/${shopId}/services`,
  });
  return result.json();
}

export async function matchShopCustomers({ shopId, tenantId, formValues }) {
  const result = await sendRequest({
    url: `/api/v1/shops/${shopId}/customers/match`,
    method: "PATCH",
    body: JSON.stringify({ tenantId, ...formValues }),
  });
  return result.json();
}

export async function lookupShopCustomers({ shopId, tenantId, formValues }) {
  const result = await sendRequest({
    url: `/api/v1/shops/${shopId}/customers/lookup-by-contacts`,
    method: "PATCH",
    body: JSON.stringify({ tenantId, ...formValues }),
  });
  return result.json();
}

export async function sendVerificationCode({ shopId, data }) {
  const result = await sendRequest({
    url: `/api/v1/shops/${shopId}/customers/${data.customerId}/otp-verification/generate`,
    method: "POST",
    body: JSON.stringify(data),
  });
  return result.json();
}

export async function resendVerificationCode({ shopId, requestId, data }) {
  await sendRequest({
    url: `/api/v1/shops/${shopId}/customers/${data.customerId}/otp-verification/${requestId}/regenerate`,
    method: "POST",
    body: JSON.stringify(data),
  });
}

export async function verifyCode({ shopId, data }) {
  const result = await sendRequest({
    url: `/api/v1/shops/${shopId}/customers/${data.customerId}/otp-verification/verify`,
    method: "POST",
    body: JSON.stringify(data),
  });

  return result.json();
}

export async function fetchCustomerVehicles({ customerId, searchQuery = "", takeCount = 100 }) {
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/customers/${customerId}/service-objects?takeCount=${takeCount}&takeFrom=1&searchQuery=${searchQuery}`,
  });

  return result.json();
}

export async function getTimeSlots(shopId, type, { dateFrom, dateTo }) {
  let slots;
  let triesCount = 0;
  while ((!slots || slots.AvailableIntervals.length === 0) && triesCount < MAX_TRIES) {
    triesCount++;
    slots = await loadTimeSlots(shopId, type, { dateFrom, dateTo });
  }

  return slots;
}

export async function loadTimeSlots(shopId, type, { dateFrom, dateTo }) {
  const appointmentType = type === AppointmentType.Dropoff ? "DropOff" : "Wait";
  const params = `?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/shops/${shopId}/time-slots/${appointmentType}${params}`,
  });
  const res = await result.json();
  return {
    AvailableIntervals: res.data.timeSlots.map((slot) => ({
      start: slot.startedAt,
      end: slot.endedAt,
    })),
  };
}

export async function submitAppointment(shopId, appointment) {
  const result = await sendRequest({
    url: `/api/v1/appointments-widget/shops/${shopId}/appointments`,
    method: "post",
    body: JSON.stringify(appointment),
  });
  return result;
}

export async function submitGoogleBookTrackData(rwgToken) {
  const headers = { "content-type": "application/json" };
  const method = "post";
  const body = JSON.stringify({
    conversion_partner_id: googleBookPartnerId,
    rwg_token: rwgToken,
    merchant_changed: 2,
  });
  await fetch(googleBookTrackUrl, { method, body, headers });
}

export async function replaceStringTokens(shopId, text) {
  const result = await sendRequest({
    url: "/api/v1/scheduler-widgets/public-text-setting-preview",
    method: "PATCH",
    body: JSON.stringify({ shopId, text }),
  });
  return result.json();
}
