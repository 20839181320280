import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Controller, useForm } from "react-hook-form";
import Typography from "../../../../components/Typography/Typography";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import styles from "./VerificationCode.scss";
import { DEFAULT_BACKGROUND_COLOR, getBackgroudColor } from "../../../../services/config.service";
import { ResendIcon } from "../../../../svg-icons";
import { TextButton } from "../../../../components/TextButton/TextButton";
import { resendVerificationCode, verifyCode } from "../../../../services/mechanic-api.client";
import { getLookupCustomer, getShopId, getVerificationMethod, saveLookupCustomer, updateLookupCustomer, useRedux } from "../../../../redux";
import Alert from "../../../../components/Alert/Alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { VALIDATION_MESSAGES } from "../../../../constants";
import useAsync from "../../../../hooks/useAsync";
import { WizardContext } from "../../../Wizard/Wizard";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";

const maxAttempts = 3;

const VerificationCode = ({ verificationResendData, store, onVerificationSuccess }) => {
  const { updateCurrentWizardStep, activeStep } = useContext(WizardContext);
  const [state, dispatch] = useRedux(store);
  const shopId = getShopId(state);
  const customer = getLookupCustomer(state);
  const verificationMethod = getVerificationMethod(state);

  const backgroundColor = getBackgroudColor();

  const isAppWhiteBackground =
    backgroundColor.toLowerCase() === DEFAULT_BACKGROUND_COLOR.toLowerCase();

  const [attemptsCount, setAttemptsCount] = useState(0);

  const { control, getValues, formState, setError } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        code: yup
          .string()
          .min(4, "Code must be between 4 and 10 characters")
          .max(10, "Code must be between 4 and 10 characters")
          .required(VALIDATION_MESSAGES.REQUIRED),
      }),
    ),
    mode: "onChange",
  });

  const formValues = getValues();
  const { isValid } = formState;

  const handleResend = async () => {
    if (attemptsCount !== maxAttempts) {
      setAttemptsCount(attemptsCount + 1);
      await resendVerificationCode({ shopId, ...verificationResendData });
    }
  };

  const handleVerify = async () => {
    closeToast();
    const result = await verifyCode({
      shopId,
      data: {
        to: verificationResendData.data.to,
        code: formValues.code,
        customerId: customer?.customerId,
        channel: verificationMethod
      },
    });
    if (result && result?.data?.isVerified) {
      dispatch(updateLookupCustomer(result.data));
      await onVerificationSuccess();
    } else {
      setError("code", { type: "custom", message: "Invalid code. Please try again" });
    }
  };

  const { execute, isLoading, errors, errorStatusCode } = useAsync({
    asyncFunction: handleVerify,
  });

  const { execute: resendCode, isLoading: isResendLoading } = useAsync({
    asyncFunction: handleResend,
  });

  useEffect(() => {
    if (activeStep?.nextButtonClickListener) {
      execute();
    }
  }, [activeStep?.nextButtonClickListener]);

  useEffect(() => {
    updateCurrentWizardStep({
      nextButtonLabel: "Verify Me",
      isLoading,
    });
  }, [isLoading]);

  useEffect(() => {
    updateCurrentWizardStep({
      isValid,
    });
  }, [isValid]);

  const { closeToast } = useErrorHandler({
    formFields: formValues,
    errors,
    errorStatusCode,
    setError,
  });

  return (
    <div className={styles.verificationCode}>
      <Typography fontWeight="bold" fontSize="xl" className={styles.title}>
        Please, Enter the Verification Code
      </Typography>
      <Controller
        control={control}
        name="code"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <TextInput
              label="Enter the code"
              value={value}
              onChange={onChange}
              error={error}
              isGrayTheme={isAppWhiteBackground}
            />
          );
        }}
      />
      <Typography color="tertiary" className={styles.sendDescription}>
        The code was sent
      </Typography>
      <div className={styles.resend}>
        <Typography color="tertiary">Didn't receive a code?</Typography>

        <TextButton
          onClick={resendCode}
          startIcon={<ResendIcon className={cn({ [styles.spinnerIcon]: isResendLoading })} />}
          disabled={attemptsCount === maxAttempts || isResendLoading}
        >
          Resend
        </TextButton>
      </div>

      {attemptsCount === maxAttempts && (
        <Alert
          className={styles.alert}
          title="You have used your resend limit."
          description="Go to the previous step and try again."
        />
      )}
    </div>
  );
};

VerificationCode.propTypes = {
  store: PropTypes.object,
  verificationResendData: PropTypes.object,
  onVerificationSuccess: PropTypes.func,
};

export default VerificationCode;
