import React from "react";
import cn from "classnames";
import styles from "./TextButton.scss";
import { getIsClientTheme } from "../../services/config.service";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import PropTypes from "prop-types";

export const TextButton = ({ className, onClick, children, startIcon, endIcon, disabled }) => {
  return (
    <button
      className={cn(
        styles.button,
        {
          [styles["button--isClientTheme"]]: getIsClientTheme(),
          [styles.disabled]: disabled,
        },
        className,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
};

TextButton.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  startIcon: PROP_TYPES_TEMPLATES.COMPONENT,
  endIcon: PROP_TYPES_TEMPLATES.COMPONENT,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
