import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../../services/analytics.service";
import {
  REDIRECT_TO_ERROR_PAGE,
  REDIRECT_TO_SUCCESS_PAGE,
  REQUEST_APPOINTMENT_ERROR,
  REQUEST_APPOINTMENT_SUCCESS,
  requestAppointment,
} from "../../../redux";
import { getRedirectUrl, getSource } from "../../../services/config.service";
import { SOURCES } from "../../../constants";
import { submitGoogleBookTrackData } from "../../../services/mechanic-api.client";

export const submitAppointment = async ({ isPreview, dispatch, isLoanerVehicleAllowed }) => {
  const source = getSource();
  if (isPreview) {
    dispatch({ type: REDIRECT_TO_SUCCESS_PAGE });
    return;
  }

  await dispatch(requestAppointment(source, isLoanerVehicleAllowed));
};

export const submitSuccessCallback = ({ contactInfo, dispatch, rwgToken }) => {
  const source = getSource();
  // adding values for GA event;
  const contactValues = {
    contactValues: {
      email: contactInfo?.email,
      firstName: contactInfo?.firstName,
      lastName: contactInfo?.lastName,
      phone: contactInfo?.phone,
    },
  };
  registerAnalyticsEvent(CUSTOM_EVENTS.appointment_scheduled, contactValues);
  dispatch({ type: REQUEST_APPOINTMENT_SUCCESS });
  if (getRedirectUrl()) {
    window.parent.location.href = getRedirectUrl();
  } else {
    dispatch({ type: REDIRECT_TO_SUCCESS_PAGE });
  }
  if (source === SOURCES.GOOGLE) {
    submitGoogleBookTrackData(rwgToken);
  }
};

export const submitFailedCallback = ({ error, dispatch }) => {
  registerAnalyticsEvent(CUSTOM_EVENTS.appointment_error);
  dispatch({ type: REQUEST_APPOINTMENT_ERROR });

  if (error.data?.status >= 500) {
    dispatch({ type: REDIRECT_TO_ERROR_PAGE });
  }
};
