import { useState, useEffect } from "react";

export function useRedux(store) {
  const [state, setState] = useState(store.getState());
  useEffect(() => {
    const unsub = store.subscribe(() => {
      setState(store.getState());
    });
    return () => {
      unsub();
    };
  }, []);
  return [state, store.dispatch];
}
