import { useEffect, useMemo, useRef, useState, forwardRef } from "react";
import cn from "classnames";
import styles from "./AddToCalendar.scss";
import makeUrls from "./makeUrls";
import { CalendarIcon } from "../../../../svg-icons";

const useAutoFocus = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const previous = document.activeElement;
    const element = elementRef.current;

    if (element) {
      element.focus();
    }

    if (previous instanceof HTMLElement) {
      return () => previous.focus();
    }

    return undefined;
  }, []);

  return elementRef;
};

const useOpenState = (initialOpen) => {
  const [open, setOpen] = useState(initialOpen);
  const onToggle = () => setOpen((current) => !current);

  useEffect(() => {
    if (open) {
      const onClose = () => setOpen(false);
      document.addEventListener("click", onClose);

      return () => document.removeEventListener("click", onClose);
    }

    return undefined;
  }, [open, setOpen]);

  return [open, onToggle];
};

const Calendar = forwardRef(({ children, filename = false, href }, ref) => (
  <a ref={ref} download={filename} href={href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
));

const Dropdown = ({ filename, onToggle, urls }) => {
  const ref = useAutoFocus();
  const onKeyDown = (event) => {
    if (event.key === "Escape") {
      onToggle();
    }
  };

  return (
    <div className={styles.dropdown} onKeyDown={onKeyDown} role="presentation">
      <Calendar href={urls.ics} filename={filename} ref={ref}>
        Apple Calendar
      </Calendar>
      <Calendar href={urls.google}>Google</Calendar>
      <Calendar href={urls.ics} filename={filename}>
        Outlook
      </Calendar>
      <Calendar href={urls.outlook}>Outlook Web App</Calendar>
      <Calendar href={urls.yahoo}>Yahoo</Calendar>
    </div>
  );
};

const AddToCalendar = ({
  children = "Add to My Calendar",
  event,
  filename = "download",
  open: initialOpen = false,
}) => {
  const [open, onToggle] = useOpenState(initialOpen);
  const urls = useMemo(() => makeUrls(event), [event]);

  return (
    <>
      <div className={styles.container}>
        {event && (
          <button type="button" className={cn(styles.button)} onClick={onToggle}>
            <CalendarIcon className={styles.icon} />
            <div>{children}</div>
          </button>
        )}
        {open && <Dropdown filename={filename} onToggle={onToggle} urls={urls} />}
      </div>
    </>
  );
};

export default AddToCalendar;
