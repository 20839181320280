import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { VALIDATION_MESSAGES, VERIFICATION_METHOD_TYPES } from "./constants";

export const verificationSchema = ({ isGuestFlow, verificationType, isMatchingOnly }) => {
  return yupResolver(
    yup.object().shape({
      ...(!isGuestFlow &&
        verificationType === VERIFICATION_METHOD_TYPES.EMAIL && {
          email: yup
            .string()
            .test({
              name: "Email",
              test: (value, { createError }) => {
                const mailRegExp =
                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^\d<>()[\]\.,;:\s@\"]{2,})$/i;
                if (!mailRegExp.test(value)) {
                  return createError({
                    message: VALIDATION_MESSAGES.INVALID_EMAIL,
                  });
                }
                return true;
              },
            })
            .required(VALIDATION_MESSAGES.REQUIRED),
        }),
      ...(!isGuestFlow &&
        verificationType === VERIFICATION_METHOD_TYPES.PHONE && {
          consentOptIn: isMatchingOnly
            ? yup.boolean()
            : yup.boolean().oneOf([true], VALIDATION_MESSAGES.REQUIRED),
          phoneNumber: yup
            .string()
            .trim()
            .test("len", VALIDATION_MESSAGES.INVALID_PHONE, (phone) =>
              phone ? phone.toString().length === 12 : true,
            )
            .required(VALIDATION_MESSAGES.REQUIRED),
        }),
    }),
  );
};

export const contactSchema = ({ isGuestFlow, isContactConsentShown }) =>
  yupResolver(
    yup.object().shape(
      isGuestFlow
        ? {
            appointmentCustomer: yup.object().shape({
              consentOptIn: isContactConsentShown
                ? yup.boolean().oneOf([true], VALIDATION_MESSAGES.REQUIRED)
                : yup.boolean(),
              firstName: yup.string().trim().required(VALIDATION_MESSAGES.REQUIRED),
              lastName: yup.string().trim().required(VALIDATION_MESSAGES.REQUIRED),
              email: yup
                .string()
                .test({
                  name: "Email",
                  test: (value, { createError }) => {
                    const mailRegExp =
                      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^\d<>()[\]\.,;:\s@\"]{2,})$/i;
                    if (!mailRegExp.test(value)) {
                      return createError({
                        message: VALIDATION_MESSAGES.INVALID_EMAIL,
                      });
                    }
                    return true;
                  },
                })
                .required(VALIDATION_MESSAGES.REQUIRED),
              phone: yup
                .string()
                .trim()
                .test("len", VALIDATION_MESSAGES.INVALID_PHONE, (phone) =>
                  phone ? phone.toString().length === 12 : true,
                )
                .required(VALIDATION_MESSAGES.REQUIRED),
            }),
          }
        : {},
    ),
  );
