import React from "react";
import cn from "classnames";

import styles from "./CharactersCounter.scss";
import Typography from "../../../Typography/Typography";

const CharactersCounter = (props) => {
  const { maxCharactersCount, value, className } = props;
  const charactersCount = value ? value.length : 0;
  const isCountWarning = charactersCount >= maxCharactersCount - 10;
  const isCountDanger = charactersCount >= maxCharactersCount;

  return (
    <Typography
      fontSize="sm"
      fontWeight="bold"
      color="quaternary"
      parentClassName={cn(styles.charactersCounter, className)}
      className={cn({
        [styles.warning]: isCountWarning,
        [styles.danger]: isCountDanger,
      })}
    >
      {`${charactersCount}/${maxCharactersCount}`}
    </Typography>
  );
};

export default CharactersCounter;
