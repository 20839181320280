import ReactTooltip from "react-tooltip";
import cn from "classnames";
import styles from "./ToggleButton.scss";
import PropTypes from "prop-types";
import ElementWrapper from "../../templates/ElementWrapper/ElementWrapper";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import { useScreenSize } from "../../hooks/useScreenSize";

const ToggleButton = ({ title, isSelected, onClick, isEnabled = true, tooltipText, className }) => {
  const { isMobile } = useScreenSize();

  return (
    <>
      <ElementWrapper
        tag="button"
        isSelected={isSelected}
        className={cn(
          className,
          styles.toggleButton,
          { [styles.selected]: isSelected },
          { [styles.disabled]: !isEnabled },
          { [styles.withHighlight]: !isSelected && isMobile },
        )}
        onClick={isEnabled ? onClick : () => false}
        data-tip={tooltipText}
        data-tip-disable={isEnabled}
      >
        <div className={styles.transparentSection}></div>
        {title}
      </ElementWrapper>
      <ReactTooltip
        className={styles.customTooltip}
        backgroundColor="#3E4150"
        effect="float"
        place="top"
      />
    </>
  );
};

ToggleButton.propTypes = {
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isEnabled: PropTypes.bool,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  tooltipText: PROP_TYPES_TEMPLATES.TITLE,
  title: PROP_TYPES_TEMPLATES.TITLE,
};

export default ToggleButton;
