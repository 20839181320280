import React from "react";
import cn from "classnames";
import ElementWrapper from "../../templates/ElementWrapper/ElementWrapper";
import styles from "./SelectCard.scss";
import { CheckIcon, PointIcon } from "../../svg-icons";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../constants";

const SelectCard = ({
  isSelected,
  isRadio = true,
  isSelectable = true,
  children,
  className,
  onSelect,
}) => {
  const handleSelect = () => {
    onSelect && onSelect();
  };

  return (
    <ElementWrapper
      hasHover={isSelectable}
      isSelected={isSelectable && isSelected}
      className={cn(styles.selectCard, className)}
      onClick={isSelectable ? handleSelect : () => {}}
    >
      {isSelectable && isSelected && (
        <div className={styles.selectIcon}>{isRadio ? <PointIcon /> : <CheckIcon />}</div>
      )}
      {children}
    </ElementWrapper>
  );
};

SelectCard.propTypes = {
  isSelected: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isRadio: PropTypes.bool,
  onSelect: PropTypes.func,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default SelectCard;
