import React, { useContext, useEffect } from "react";
import ServicesScene from "../ServicesScene/ServicesScene";
import ElementWrapper from "../../templates/ElementWrapper/ElementWrapper";
import { fetchCustomerVehicles } from "../../services/mechanic-api.client";
import {
  getLookupCustomer,
  getVehicles,
  getVehicleSectionValue,
  setVehicle,
  setVehiclesList,
  useRedux,
} from "../../redux";
import Typography from "../../components/Typography/Typography";
import styles from "./CampaignServicesStep.scss";
import { WizardContext } from "../Wizard/Wizard";
import { STEP_IDS } from "../Wizard/utils/stepsGenerator";
import { scrollToTop } from "../../utils/scrollUtils";
import { TextButton } from "../../components/TextButton/TextButton";

const CampaignServicesStep = ({ store }) => {
  const { changeStep, steps } = useContext(WizardContext);
  const [state, dispatch] = useRedux(store);
  const { customerId, serviceObjectId } = state;
  const selectedVehicle = state.appointmentDetails.vehicle;
  const selectedCustomer = getLookupCustomer(state);
  const vehicles = getVehicles(state);
  const vehicleName = selectedVehicle?.displayName || getVehicleSectionValue(state);
  const customerIdToRequest = selectedCustomer?.customerId || customerId;

  const handleFetchVehicles = async () => {
    const result = await fetchCustomerVehicles({
      customerId: customerIdToRequest,
    });
    const selectedVehicle = result.data.serviceObjects.find(({ id }) => {
      return id === serviceObjectId;
    });
    if (selectedVehicle?.id) {
      dispatch(setVehicle({ ...selectedVehicle, serviceObjectId: selectedVehicle.id }));
    }
    dispatch(setVehiclesList(result.data?.serviceObjects));
  };

  useEffect(() => {
    if (customerIdToRequest && !vehicles.length) {
      handleFetchVehicles();
    }
  }, []);

  const handleGoToVehiclesStep = () => {
    const vehiclesStepIndex = steps.findIndex(({ id }) => id === STEP_IDS.VEHICLE_SELECT);
    changeStep(vehiclesStepIndex);
    scrollToTop();
  };

  return (
    <div>
      <div className={styles.container}>
        <ElementWrapper hasHover={false} className={styles.vehicleSection}>
          <Typography>{vehicleName}</Typography>
          <TextButton onClick={handleGoToVehiclesStep}>Change Vehicle</TextButton>
        </ElementWrapper>
      </div>

      <ServicesScene store={store} />
    </div>
  );
};

export default CampaignServicesStep;
