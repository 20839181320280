import styles from "./SectionSummary.scss";
import ReactTooltip from "react-tooltip";
import { InfoIcon } from "../../../svg-icons";
import List from "./components/List/List";

export default function SectionSummary({ section, value, servicesComments }) {
  const isServices = section === "Service(s)";
  return (
    <div className={styles.sectionSummary}>
      <div className={styles.sectionHeader}>{section}</div>
      <div className={styles.sectionValue}>
        {isServices && <List values={value} servicesComments={servicesComments} />}
        {!isServices && value}
      </div>
    </div>
  );
}
