import getDaysInMonth from "date-fns/getDaysInMonth";
import {
  isAfter,
  isSameDay,
  parseISO,
  subDays,
  isSameHour,
  isSameMinute,
  getDate,
  addMinutes,
} from "date-fns";

export function parseSlotsStartToDate(timeSlots) {
  return timeSlots.map((slot) => ({
    start: slot.start,
    end: slot.end,
    isTimeBlock: false,
    TimeType: 0,
    enabled: true,
  }));
}

export function dateToMonthYear(date) {
  const month = date || new Date();
  return [month.getMonth(), month.getFullYear()];
}

export function getNumberOfDaysInMonth(month) {
  const [currentMonth, currentYear] = dateToMonthYear(month);
  const numberOfDays = getDaysInMonth(new Date(currentYear, currentMonth));
  const thisMonth = Array.from({ length: numberOfDays }, (e, i) => {
    return new Date(currentYear, currentMonth, i + 1);
  });
  return thisMonth;
}

export function getAvailableDays(timeSlots) {
  const parsedDates = timeSlots.map((slot) => parseISO(slot.start));
  const availableDays = [];
  for (const day of parsedDates) {
    if (!availableDays.some((x) => isSameDay(x, day))) {
      availableDays.push(day);
    }
  }
  return availableDays;
}

export function getDisabledDays(availableDays, month) {
  const disabledDays = [];
  const thisMonth = getNumberOfDaysInMonth(month);
  for (const day of thisMonth) {
    if (!availableDays.some((availableDay) => isSameDay(day, availableDay))) {
      disabledDays.push(day);
    }
  }
  return disabledDays;
}

export function allDayForMonth(availableDays, disabledDays) {
  if (!availableDays) return;
  const availableDaysEnabled = availableDays.map((day) => ({
    date: day,
    isEnabled: isAfter(day, subDays(new Date(), 1)),
  }));
  const disabledDaysEnabled = disabledDays.map((day) => ({
    date: day,
    isEnabled: false,
  }));
  return availableDaysEnabled.concat(disabledDaysEnabled).sort((a, b) => a.date - b.date);
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getMonthString(monthId) {
  return monthNames[monthId];
}

export function formatDateString(rawDate) {
  const date = new Date(rawDate);

  return `${getMonthString(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`;
}

export function generateTimeslots(startTime, endTime, timeIntervalInMin) {
  if (startTime > endTime) {
    throw new Error("generateTimeslots: startTime > endTime");
  }
  const timeSlots = [startTime];
  while (true) {
    let prev = timeSlots[timeSlots.length - 1];
    let next = addMinutes(prev, timeIntervalInMin);
    if (next > endTime) break;
    timeSlots.push(next);
  }
  return timeSlots;
}

function createAllPosibleSlotsInRange(date) {
  const [month, year] = dateToMonthYear(date);
  const dateDay = getDate(date);
  const timeIntervalInMin = 60;
  const rangeStart = new Date(year, month, dateDay, 8, 0);
  const rangeEnd = new Date(year, month, dateDay, 17, 0);
  const inRangeSlots = generateTimeslots(rangeStart, rangeEnd, timeIntervalInMin);
  const allPossibleSlotsInRange = inRangeSlots.map((sl) => ({
    start: sl,
    enabled: false,
  }));
  return allPossibleSlotsInRange;
}

export function getDisabledTimeSlots(enabledTimeslots, date) {
  const allPossibleTimeSlotsInRange = createAllPosibleSlotsInRange(date);
  const disabledTimeSlots = [];
  for (const slot of allPossibleTimeSlotsInRange) {
    if (
      !enabledTimeslots.some(
        (enabledSlot) =>
          isSameHour(slot.start, enabledSlot.start) && isSameMinute(slot.start, enabledSlot.start),
      )
    )
      disabledTimeSlots.push(slot);
  }
  return disabledTimeSlots;
}

export function allSlotsForDay(enabledTimeslots) {
  return enabledTimeslots.sort((a, b) => a.start - b.start);
}
