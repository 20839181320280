import React from "react";
import cn from "classnames";

import styles from "./CountryFlag.scss";
import {flagsImage} from "./flagsImageInBase64";

const CountryFlag = (props) => {
    const {countryIso2} = props;
    return (
        <div
            style={{
                backgroundImage: `url(${flagsImage})`,
            }}
            className={cn(styles.CountryFlag, "border-radius-circle", [
                styles[`CountryFlag__${countryIso2.toLowerCase()}`],
            ])}
        />
    );
};

export default CountryFlag;
