import { DAYS_NAME } from "../../../../constants";
import cn from "classnames";
import { formatHoursToAmPm } from "../../../../utils";
import styles from "./WorkSchedule.scss";

export function WorkSchedule({ days }) {
  return (
    <div>
      {days.map((day) => {
        const closed = !day.timeIntervals.length;
        return (
          <div key={day.dayOfWeek} className={styles.day}>
            <div className={styles.dayName}>{DAYS_NAME[day.dayOfWeek]}</div>{" "}
            {!closed && (
              <div className={cn(styles.hours)}>
                {day.timeIntervals.map((slot) => {
                  return (
                    <div key={slot.from}>
                      {formatHoursToAmPm(slot.from)} -&nbsp;
                      {formatHoursToAmPm(slot.to)} <br />
                    </div>
                  );
                })}
              </div>
            )}
            {closed && <div>Closed</div>}
          </div>
        );
      })}
    </div>
  );
}
