const trimmedCountriesName = {
  US: "United States",
  CA: "Canada",
};

// const countriesName = {
//   AD: "Andorra",
//   AE: "United Arab Emirates",
//   AF: "Afghanistan",
//   AG: "Antigua And Barbuda",
//   AI: "Anguilla",
//   AL: "Albania",
//   AM: "Armenia",
//   AO: "Angola",
//   AR: "Argentina",
//   AS: "American Samoa",
//   AT: "Austria",
//   AU: "Australia",
//   AW: "Aruba",
//   AX: "Åland",
//   AZ: "Azerbaijan",
//   BA: "Bosnia And Herzegovina",
//   BB: "Barbados",
//   BD: "Bangladesh",
//   BE: "Belgium",
//   BF: "Burkina Faso",
//   BG: "Bulgaria",
//   BH: "Bahrain",
//   BI: "Burundi",
//   BJ: "Benin",
//   BL: "Saint Barthélemy",
//   BM: "Bermuda",
//   BN: "Brunei Darussalam",
//   BO: "Bolivia",
//   BR: "Brazil",
//   BS: "Bahamas",
//   BT: "Bhutan",
//   BW: "Botswana",
//   BY: "Belarus",
//   BZ: "Belize",
//   CA: "Canada",
//   CC: "Cocos (Keeling) Islands",
//   CD: "Congo (Democratic Republic)",
//   CF: "Central African Republic",
//   CG: "Congo (Republic)",
//   CH: "Switzerland",
//   CI: "Cote D’Ivoire",
//   CK: "Cook Islands",
//   CL: "Chile",
//   CM: "Cameroon",
//   CN: "Republic of China",
//   CO: "Colombia",
//   CR: "Costa Rica",
//   CU: "Cuba",
//   CV: "Cape Verde",
//   CW: "Curaçao",
//   CX: "Christmas Island",
//   CY: "Cyprus",
//   CZ: "Czech Republic",
//   DE: "Germany",
//   DJ: "Djibouti",
//   DK: "Denmark",
//   DM: "Dominica",
//   DO: "Dominican Republic",
//   DZ: "Algeria",
//   EC: "Ecuador",
//   EE: "Estonia",
//   EG: "Egypt",
//   EH: "Western Sahara",
//   ER: "Eritrea",
//   ES: "Spain",
//   ET: "Ethiopia",
//   FI: "Finland",
//   FJ: "Fiji",
//   FK: "Falkland Islands (Malvinas)",
//   FM: "Micronesia, Federated States Of",
//   FO: "Faroe Islands",
//   FR: "France",
//   GA: "Gabon",
//   GB: "United Kingdom",
//   GD: "Grenada",
//   GE: "Georgia",
//   GF: "French Guiana",
//   GG: "Guernsey",
//   GH: "Ghana",
//   GI: "Gibraltar",
//   GL: "Greenland",
//   GM: "Gambia",
//   GN: "Guinea",
//   GP: "Guadeloupe",
//   GQ: "Equatorial Guinea",
//   GR: "Greece",
//   GT: "Guatemala",
//   GU: "Guam",
//   GW: "Guinea-Bissau",
//   GY: "Guyana",
//   HK: "Hong Kong",
//   HN: "Honduras",
//   HR: "Croatia",
//   HT: "Haiti",
//   HU: "Hungary",
//   ID: "Indonesia",
//   IE: "Ireland",
//   IL: "Israel",
//   IM: "Isle of Man",
//   IN: "India",
//   IO: "British Indian Ocean Territory",
//   IQ: "Iraq",
//   IR: "Iran",
//   IS: "Iceland",
//   IT: "Italy",
//   JE: "Jersey",
//   JM: "Jamaica",
//   JO: "Jordan",
//   JP: "Japan",
//   KE: "Kenya",
//   KG: "Kyrgyzstan",
//   KH: "Cambodia",
//   KI: "Kiribati",
//   KM: "Comoros",
//   KN: "Saint Kitts And Nevis",
//   KP: "North Korea",
//   KR: "South Korea",
//   KW: "Kuwait",
//   KY: "Cayman Islands",
//   KZ: "Kazakhstan",
//   LA: "Lao People’s Democratic Republic",
//   LB: "Lebanon",
//   LC: "Saint Lucia",
//   LI: "Liechtenstein",
//   LK: "Sri Lanka",
//   LR: "Liberia",
//   LS: "Lesotho",
//   LT: "Lithuania",
//   LU: "Luxembourg",
//   LV: "Latvia",
//   LY: "Libyan Arab Jamahiriya",
//   MA: "Morocco",
//   MC: "Monaco",
//   MD: "Republic Of Moldova",
//   ME: "Montenegro",
//   MF: "Saint Martin",
//   MG: "Madagascar",
//   MH: "Marshall Islands",
//   MK: "Macedonia",
//   ML: "Mali",
//   MM: "Myanmar",
//   MN: "Mongolia",
//   MO: "Macau",
//   MP: "Northern Mariana Islands",
//   MQ: "Martinique",
//   MR: "Mauritania",
//   MS: "Montserrat",
//   MT: "Malta",
//   MU: "Mauritius",
//   MV: "Maldives",
//   MW: "Malawi",
//   MX: "Mexico",
//   MY: "Malaysia",
//   MZ: "Mozambique",
//   NA: "Namibia",
//   NC: "New Caledonia",
//   NE: "Niger",
//   NF: "Norfolk Island",
//   NG: "Nigeria",
//   NI: "Nicaragua",
//   NL: "Netherlands",
//   NO: "Norway",
//   NP: "Nepal",
//   NR: "Nauru",
//   NU: "Niue",
//   NZ: "New Zealand",
//   OM: "Oman",
//   PA: "Panama",
//   PE: "Peru",
//   PF: "French Polynesia",
//   PG: "Papua New Guinea",
//   PH: "Philippines",
//   PK: "Pakistan",
//   PL: "Poland",
//   PM: "St. Pierre And Miquelon",
//   PR: "Puerto Rico",
//   PS: "Palestine",
//   PT: "Portugal",
//   PW: "Palau",
//   PY: "Paraguay",
//   QA: "Qatar",
//   RE: "Reunion",
//   RO: "Romania",
//   RS: "Serbia",
//   RU: "Russian Federation",
//   RW: "Rwanda",
//   SA: "Saudi Arabia",
//   SB: "Solomon Islands",
//   SC: "Seychelles",
//   SD: "Sudan",
//   SE: "Sweden",
//   SG: "Singapore",
//   SH: "St. Helena",
//   SI: "Slovenia",
//   SJ: "Svalbard And Jan Mayen Islands",
//   SK: "Slovakia (Slovak Republic)",
//   SL: "Sierra Leone",
//   SM: "San Marino",
//   SN: "Senegal",
//   SO: "Somalia",
//   SR: "Suriname",
//   SS: "South Sudan",
//   ST: "Sao Tome And Principe",
//   SV: "El Salvador",
//   SY: "Syrian Arab Republic",
//   SZ: "Swaziland",
//   TC: "Turks And Caicos Islands",
//   TD: "Chad",
//   TG: "Togo",
//   TH: "Thailand",
//   TJ: "Tajikistan",
//   TK: "Tokelau",
//   TL: "East Timor (Timor-Leste)",
//   TM: "Turkmenistan",
//   TN: "Tunisia",
//   TO: "Tonga",
//   TR: "Turkey",
//   TT: "Trinidad And Tobago",
//   TV: "Tuvalu",
//   TW: "Taiwan",
//   TZ: "United Republic Of Tanzania",
//   UA: "Ukraine",
//   UG: "Uganda",
//   US: "United States",
//   UY: "Uruguay",
//   UZ: "Uzbekistan",
//   VA: "Vatican City State (Holy See)",
//   VC: "Saint Vincent And The Grenadines",
//   VE: "Venezuela",
//   VG: "Virgin Islands (British)",
//   VI: "Virgin Islands (U.S.)",
//   VN: "Vietnam",
//   VU: "Vanuatu",
// };

export default trimmedCountriesName;
