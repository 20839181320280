import cn from "classnames";
import { format, isSameMonth, addMonths } from "date-fns";
import ReactPlaceholder from "react-placeholder";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { getAvailableDays, getDisabledDays, allDayForMonth } from "../../services/date-service";
import { TimeSlotsSkeleton, CalendarSkeleton } from "../../components/Skeletons/Skeletons";
import Month from "./components/Month/Month";
import MonthToggle from "./components/MonthToggle/MonthToggle";
import Typography from "../../components/Typography/Typography";
import TimePicker from "./components/TimePicker/TimePicker";
import {
  getAppointmentAllowedStatuses,
  getAppointmentTimeSlot,
  getAvailableSlots,
  getIsLoadedSlotsStatus,
  getSectionState,
  resetDate,
  setAppointmentType,
  setDate,
  setMonthAndLoadTimeSlots,
  setTime,
  useRedux,
} from "../../redux";
import { AppointmentType } from "../../constants";
import { getIsPlazaTire } from "../../services/config.service";
import WaitingTypeSection from "./components/WaitingTypeSection/WaitingTypeSection";
import styles from "./DateTimeScene.scss";
import { useScreenSize } from "../../hooks/useScreenSize";
import ElementWrapper from "../../templates/ElementWrapper/ElementWrapper";
import { WizardContext } from "../Wizard/Wizard";

const DateTimeScene = ({ store }) => {
  const { updateCurrentWizardStep } = useContext(WizardContext);
  const { isMobile } = useScreenSize();
  const [state, dispatch] = useRedux(store);
  // fixme isPlazaTire remove/handle in future
  const isPlazaTire = getIsPlazaTire();
  const date = state.appointmentDetails.dateTime.date;
  const selectedSlot = state.appointmentDetails.dateTime.timeSlot;
  const timeSlots = state.shopOptions.timeSlots;
  const currentMonth = state.appointmentDetails.selectedMonth;
  const appointmentType = state.appointmentDetails.appointmentType;
  const availableSlots = getAvailableSlots(state);
  const areSlotsLoaded = getIsLoadedSlotsStatus(state);
  const timeSlot = getAppointmentTimeSlot(state);
  const [isWaitingTypeAllowed, isDropOffTypeAllowed] = getAppointmentAllowedStatuses(state);
  const [isMovedToNextMonth, setIsMovedToNextMonth] = useState(false);

  const timePickerRef = useRef(null);

  const disabledDays = [];
  let availableDays;

  useEffect(() => {
    if (isPlazaTire) return;
    if (!timeSlots?.length && !isMovedToNextMonth) {
      moveToNextMonth();
      setIsMovedToNextMonth(true);
    }
    // when switching to another month state sets empty array,
    // to avoid bug with this logic we are stopping automatic fetch if this month have any slots;
    if (timeSlots?.length) {
      setIsMovedToNextMonth(true);
    }
  }, [timeSlots]);

  if (timeSlots) {
    availableDays = getAvailableDays(timeSlots);
    const disabledDaysForCurrentMonth = getDisabledDays(availableDays, currentMonth);
    disabledDays.push(...disabledDaysForCurrentMonth);
  }
  const allDays = useMemo(
    () => allDayForMonth(availableDays, disabledDays),
    [availableDays, disabledDays],
  );

  useEffect(() => {
    let footerSummary = "";

    if (appointmentType) {
      if (isPlazaTire) {
        footerSummary = "";
      } else {
        if (appointmentType === AppointmentType.Dropoff) {
          footerSummary = "I will drop off my vehicle;";
        }
        if (appointmentType === AppointmentType.Waiting) {
          footerSummary = "I will wait for my vehicle;";
        }
      }
    } else {
      footerSummary = "Select all required options.";
    }

    if (timeSlot) {
      footerSummary =
        footerSummary + " " + format(new Date(timeSlot.start), "EEEE, MMMM d, h:mm a.");
    }

    updateCurrentWizardStep({
      footerSummary: footerSummary,
      isValid: !!appointmentType && !!timeSlot,
    });
  }, [timeSlot, appointmentType]);

  useEffect(() => {
    if (!isMobile) {
      if (!date && allDays && !!allDays.length) {
        const firstAvailableOption = allDays.find((day) => day.isEnabled);
        if (firstAvailableOption) {
          handleDateChange(firstAvailableOption.date);
        }
      }
    } else {
      if (availableSlots && date) {
        setTimeout(
          () =>
            timePickerRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            }),
          100,
        );
      }
    }
  }, [date, availableSlots, timeSlots]);

  const handleDateChange = (date) => {
    dispatch(setDate(date));
  };
  const handleMonthChange = (month) => {
    dispatch(setMonthAndLoadTimeSlots(month));
    dispatch(resetDate());
  };
  const handleTimeChange = (timeSlot) => {
    dispatch(setTime(timeSlot));
  };

  const handleChangeWaitingType = (type) => {
    dispatch(setAppointmentType(type));
  };

  const moveToNextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);
    handleMonthChange(nextMonth);
  };

  return (
    <div>
      {/*TODO: isPlazaTire - temporary, PlazaTire's request*/}
      {isWaitingTypeAllowed && isDropOffTypeAllowed && !isPlazaTire && (
        <>
          <WaitingTypeSection
            type={appointmentType}
            onChange={handleChangeWaitingType}
            sectionState={getSectionState(state, "dropoff")}
          />
        </>
      )}

      <div className={styles.section}>
        <div className={cn(styles.dateTimeSection)}>
          <div className={styles.header}>
            <Typography fontWeight="bold" fontSize="lg">
              {timeSlots ? "Select Appointment Date and Time" : ""}
            </Typography>
          </div>
          <div className={styles.content}>
            <ReactPlaceholder ready={areSlotsLoaded} customPlaceholder={CalendarSkeleton()}>
              {timeSlots && (
                <ElementWrapper className={styles.calendar} hasHover={false}>
                  <MonthToggle
                    currentMonth={currentMonth}
                    onChange={handleMonthChange}
                    isFirstAvailableMonth={isSameMonth(currentMonth, new Date())}
                  />
                  <Month
                    onDayClick={handleDateChange}
                    availableDays={availableDays}
                    disabledDays={disabledDays}
                    selectedDay={date}
                    month={currentMonth}
                  />
                </ElementWrapper>
              )}
              {!timeSlots && (
                <div className={styles.infoMessage}>{"Date and time selection not available"}</div>
              )}
            </ReactPlaceholder>
            <ReactPlaceholder ready={areSlotsLoaded} customPlaceholder={TimeSlotsSkeleton()}>
              {!!date && !!availableSlots && (
                <TimePicker
                  date={date}
                  timeSlots={availableSlots}
                  selectedSlot={selectedSlot}
                  onClick={handleTimeChange}
                  timePickerRef={timePickerRef}
                  isMobile={isMobile}
                />
              )}
            </ReactPlaceholder>
          </div>
        </div>
      </div>
    </div>
  );
};

DateTimeScene.propTypes = {
  store: PropTypes.object,
};

export default DateTimeScene;
