import cn from "classnames";
import React from "react";
import styles from "./SearchInput.scss";
import { CrossIcon, SearchIcon } from "../../../svg-icons";
import PropTypes from "prop-types";

const SearchInput = ({ placeholder, onChange, value, onCloseClick }) => {
  return (
    <div className={cn(styles.searchInputContainer)}>
      <input
        className={styles.searchInput}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      ></input>
      <SearchIcon />
      {value && <CrossIcon onClick={onCloseClick} />}
    </div>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  onCloseClick: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default SearchInput;
