import React from "react";
import cn from "classnames";

import styles from "../Loader/Loader.scss";
import { PROP_TYPES_TEMPLATES } from "../../constants";

const Loader = ({ className }) => {
  return (
    <div className={cn(className, styles.loaderWrapper)}>
      <div className={cn(styles.Loader, "relative")}>
        <span className={cn(styles.Loader__shape, styles.Loader__shape1)} />
        <span className={cn(styles.Loader__shape, styles.Loader__shape2)} />
        <span className={cn(styles.Loader__shape, styles.Loader__shape3)} />
        <span className={cn(styles.Loader__shape, styles.Loader__shape4)} />
      </div>
      <span className="font-semibold font-sm text-color-tertiary mt-12">Loading...</span>
    </div>
  );
};

Loader.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default Loader;
