import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import imask from "imask";
import cn from "classnames";

import InputWrapper from "../InputWrapper";
import styles from "./TextInput.scss";
import InputError from "../InputError/InputError";
import { PROP_TYPES_TEMPLATES } from "../../../constants";
import useShowInputError from "../../../hooks/useShowInputError";

const TextInput = ({
  className,
  value = "",
  name = "",
  autoComplete,
  id,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  placeholder,
  disabled,
  readOnly,
  error,
  type = "text",
  label,
  mask,
  additionalLabel,
  withButton,
  buttonComponent,
  isCharacterCounter,
  maxCharactersCount = 50,
  labelClassName,
  inputClassName,
  isGrayTheme = false,
}) => {
  const { isShowError, isShowTooltip, setIsShowError, validationErrorMessage, serverErrorMessage } =
    useShowInputError(error);

  const inputEl = useRef(null);
  useEffect(() => {
    if (mask) {
      imask(inputEl.current, {
        mask: mask,
      });
    }
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    if (!value) {
      if (type === "number") {
        onChange(null);
        return;
      }
      onChange("");
      return;
    }
    if (value.length <= maxCharactersCount) {
      onChange(value);
    }
  };

  const handleFocus = () => {
    setIsShowError(false);
    onFocus();
  };

  const handleBlur = () => {
    if (typeof value === "string") {
      onChange(value.trim());
    }

    setIsShowError(true);
    onBlur();
  };

  return (
    <InputWrapper
      className={className}
      label={label}
      value={value}
      onClear={() => onChange("")}
      isError={isShowError && (serverErrorMessage || validationErrorMessage)}
      disabled={disabled}
      additionalLabel={additionalLabel}
      withButton={withButton}
      buttonComponent={buttonComponent}
      isCharacterCounter={isCharacterCounter}
      maxCharactersCount={maxCharactersCount}
      labelClassName={labelClassName}
      closeIconClassName={styles.closeIcon}
    >
      <div className={cn(styles.inputContainer, "relative")}>
        <input
          id={id}
          type={type}
          ref={inputEl}
          value={value}
          name={name}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onInput={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(styles.textInput, inputClassName, {
            [styles.grayTheme]: isGrayTheme,
            [styles.isError]: serverErrorMessage || (isShowError && validationErrorMessage),
            [styles.isDisabled]: disabled,
          })}
        />

        <InputError
          className={cn(styles.error)}
          isOpen={isShowTooltip}
          errorMessage={serverErrorMessage || validationErrorMessage}
        />
      </div>
    </InputWrapper>
  );
};

TextInput.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  additionalLabel: PROP_TYPES_TEMPLATES.LABEL,
  error: PROP_TYPES_TEMPLATES.ERROR,
  serverError: PROP_TYPES_TEMPLATES.ERROR,
  value: PROP_TYPES_TEMPLATES.INPUT_VALUE,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isGrayTheme: PropTypes.bool,
  isCharacterCounter: PropTypes.bool,
  maxCharactersCount: PropTypes.number,
  withButton: PropTypes.bool,
  buttonComponent: PROP_TYPES_TEMPLATES.COMPONENT,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  labelClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  inputClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default TextInput;
