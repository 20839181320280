import { useState } from "react";
import { getInputErrors } from "../utils";

const useShowInputError = (error) => {
  const [isShowError, setIsShowError] = useState(true);

  const { validationErrorMessage, serverErrorMessage } = getInputErrors(error);
  const isShowTooltip = !!serverErrorMessage || !!(isShowError && validationErrorMessage);

  return {
    isShowError,
    isShowTooltip,
    setIsShowError,
    validationErrorMessage,
    serverErrorMessage,
  };
};

export default useShowInputError;
