import React from "react";
import Day from "./components/Day/Day";
import { DAYS, PROP_TYPES_TEMPLATES } from "../../../../constants";
import styles from "./Month.scss";
import { format, isSameDay, isToday } from "date-fns";
import { allDayForMonth } from "../../../../services/date-service";
import PropTypes from "prop-types";

const Month = ({ availableDays, disabledDays, onDayClick, selectedDay, month }) => {
  const allDays = allDayForMonth(availableDays, disabledDays);
  return (
    <div className={styles.month}>
      <div className={styles.name}>{format(month, "MMMM yyyy")}</div>
      <div className={styles.weekDays}>
        {DAYS.map((day, index) => (
          <span key={index} className={styles.dayName}>
            {day}
          </span>
        ))}
      </div>
      <div className={styles.wrapper}>
        {allDays.map((day) => (
          <Day
            key={day.date}
            day={day.date}
            isSelected={isSameDay(day.date, selectedDay)}
            isToday={isToday(day.date)}
            isEnabled={day.isEnabled}
            onClick={() => onDayClick(day.date)}
          />
        ))}
      </div>
    </div>
  );
};

Month.propTypes = {
  availableDays: PropTypes.array,
  disabledDays: PropTypes.array,
  onDayClick: PropTypes.func,
  selectedDay: PROP_TYPES_TEMPLATES.DATE,
  month: PROP_TYPES_TEMPLATES.DATE,
};

export default Month;
