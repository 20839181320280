import { AppointmentType } from "../constants";

export const initialState = {
  step: 1,
  shopId: "",
  tenantId: "",
  shopIds: [],
  customerId: "",
  serviceObjectId: "",
  availableShopIds: [],
  availableShopDetails: [],
  shopDetails: null,
  areShopDetailsLoading: false,
  shopOptions: {
    vehicle: {
      years: [],
      makes: [],
      models: [],
      isLoanerVehicleNeeded: null,
    },
    services: [],
  },
  vehicles: [],
  disclaimerTextWithReplacedTokens: "",
  shouldShowValidationErrors: false,
  isFinished: false,
  isError: false,
  isModelFieldDisabled: true,
  isMakeFieldDisabled: true,
  areMakesLoaded: true,
  areModelsLoaded: true,
  isLoadingAppointmentRequest: false,
  isShowingAppointment: true,
  isGuestFlow: false,
  isNewVehicle: false,
  matchedCustomers: [],
  selectedCustomer: {},
  otpVerificationChannel: "",
  isContactConsentShown: true,

  appointmentDetails: {
    vehicle: {
      year: null,
      make: null,
      model: null,
    },
    services: [],
    servicesComments: {},
    appointmentType: AppointmentType.Unknown,

    dateTime: {},
    selectedMonth: new Date(),
    contactInfo: {
      isLoanerVehicleNeeded: false,
      consentOptIn: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      comments: "",
      coupon: "",
    },
  },
};
