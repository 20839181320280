import React, { useContext, useEffect } from "react";
import styles from "./VehicleSelectScene.scss";
import VehiclesList from "./components/VehiclesList/VehiclesList";
import VehicleSection from "../../templates/VehicleSection/VehicleSection";
import PropTypes from "prop-types";
import { WizardContext } from "../Wizard/Wizard";
import { getNewVehicleSelected, setNewVehicleSelected, setVehicle, useRedux } from "../../redux";

const VehicleSelectScene = ({ store }) => {
  const [state, dispatch] = useRedux(store);
  const isNewVehicleSelected = getNewVehicleSelected(state);
  const { updateCurrentWizardStep, activeStep, initialSteps } = useContext(WizardContext);
  const selectedVehicle = state.appointmentDetails.vehicle;
  const { year = [], make = [], model = [] } = selectedVehicle;
  const isCreationValid = !!year && !!make && !!model;
  const activeStepInitial = initialSteps.find(({ id }) => id === activeStep.id);
  const showBackButtonInitial = activeStepInitial?.showBackButton;

  const handleSelectVehicle = (vehicle) => {
    dispatch(setNewVehicleSelected(false));
    const { id } = vehicle;
    dispatch(setVehicle({ ...vehicle, serviceObjectId: id }));
    updateCurrentWizardStep({
      isValid: true,
    });
  };

  const handleOpenNewVehiclePage = () => {
    dispatch(setNewVehicleSelected(true));
    dispatch(setVehicle({}));
    updateCurrentWizardStep({
      isPreviousStepBlocked: true,
      isValid: false,
    });
  };

  useEffect(() => {
    if (isNewVehicleSelected) {
      updateCurrentWizardStep({
        isValid: isCreationValid,
        showBackButton: true,
      });
    }
  }, [isNewVehicleSelected, isCreationValid]);

  useEffect(() => {
    if (activeStep.backButtonClickListener) {
      if (isNewVehicleSelected) {
        dispatch(setNewVehicleSelected(false));
        updateCurrentWizardStep({
          isPreviousStepBlocked: false,
          isValid: false,
          showBackButton: showBackButtonInitial,
        });
      }
    }
  }, [activeStep.backButtonClickListener]);

  return (
    <div className={styles.vehicleSelectStep}>
      {isNewVehicleSelected ? (
        <VehicleSection store={store} onSelectVehicle={handleSelectVehicle} />
      ) : (
        <VehiclesList
          onOpenNewVehicle={handleOpenNewVehiclePage}
          onSelectVehicle={handleSelectVehicle}
          store={store}
        />
      )}
    </div>
  );
};

VehicleSelectScene.propTypes = {
  store: PropTypes.object,
};

export default VehicleSelectScene;
