import React from "react";
import styles from "./Section.scss";
import cn from "classnames";
import { getWidgetInPopup } from "../../services/config.service";

const Section = ({ children }) => {
  const isWidgetPopup = getWidgetInPopup();
  return (
    <div
      className={cn(styles.section, {
        [styles.popupSection]: isWidgetPopup,
      })}
    >
      {children}
    </div>
  );
};

export default Section;
