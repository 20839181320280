import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "../Typography/Typography.scss";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import Tooltip from "../Tooltip/Tooltip";

const Typography = ({
  parentClassName,
  tooltipClassName,
  className,
  color = "primary",
  fontSize = "md",
  fontWeight = "normal",
  children,
  tag = "div",
  onClick = () => {},
  ellipsis,
  lineClamp = 1,
  showTooltip = true,
  maxWidth,
}) => {
  const Component = tag;
  const ParentTag = tag;
  const typographyRef = useRef(null);
  const [isShowTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    // Moved logic of tooltip showing here to be sure that typographyRef.current is not undefined
    if (ellipsis) {
      typographyRef.current.style.WebkitLineClamp = lineClamp;
      typographyRef.current.style.whiteSpace = lineClamp > 1 ? "pre-wrap" : "normal";
    }
    const isShowTooltip = maxWidth
      ? typographyRef.current?.scrollWidth > maxWidth
      : typographyRef.current?.scrollWidth > typographyRef.current?.clientWidth ||
        typographyRef.current?.scrollHeight > typographyRef.current?.clientHeight;
    setShowTooltip(isShowTooltip);
  }, [maxWidth, children]);

  return (
    <ParentTag className={cn(styles[`${fontSize}--margin`], parentClassName)}>
      <Tooltip
        useHover={isShowTooltip && showTooltip}
        content={isShowTooltip ? children : ""}
        tipContentClassName={cn(styles.tooltipContent, tooltipClassName)}
      >
        <Component
          style={{
            maxWidth,
            ...(ellipsis ? { WebkitLineClamp: lineClamp } : {}),
          }}
          ref={typographyRef}
          onClick={onClick}
          className={cn(styles[color], styles[fontSize], styles[fontWeight], className, {
            [styles.ellipsis]: ellipsis,
          })}
        >
          {children}
        </Component>
      </Tooltip>
    </ParentTag>
  );
};

Typography.defaultProps = {
  children: "",
};

Typography.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  parentClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  tag: PropTypes.string,
  fontWeight: PropTypes.oneOf(["normal", "medium", "semibold", "bold"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "white",
    "warning",
    "error",
    "link",
  ]),
  fontSize: PropTypes.oneOf([
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "xl-2",
    "xl-3",
    "xl-4",
    "xl-5",
    "xl-6",
    "xl-7",
  ]),
  onClick: PropTypes.func,
  lineClamp: PropTypes.number,
};

export default Typography;
