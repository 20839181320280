import React, { useEffect, useRef, useState } from "react";
import {
  isCallRailSwap,
  showGetDirectionsButton,
  showOpeningHours,
  showOverallShopRating,
} from "../../../../../../services/config.service";
import {
  formatPhoneNumberToUsLocale,
  groupWorkingsDays,
  prepareTimeSlots,
  showInGoogleMaps,
} from "../../../../../../utils";
import cn from "classnames";
import styles from "./ShopItem.scss";
import { CalendarIcon2, MapPointIcon, PhoneIcon2, PointIcon } from "../../../../../../svg-icons";
import Typography from "../../../../../../components/Typography/Typography";
import { buildStarsHTML } from "../../../../../../components/star-build";
import Collapse from "./components/Collapse/Collapse";
import GroupedWorkingHours from "./components/GroupedWorkingHours/GroupedWorkingHours";
import PropTypes from "prop-types";
import { useScreenSize } from "../../../../../../hooks/useScreenSize";
import ElementWrapper from "../../../../../../templates/ElementWrapper/ElementWrapper";

const ShopItem = ({ onClick, onViewLocation, onShopHover, onShopFocus, selectedShopId, shop }) => {
  const {
    city,
    state,
    address,
    workTimeSlots,
    zip,
    phone,
    rating,
    shopId: id,
    latitude,
    longitude,
    name,
  } = shop || {};

  const phoneToShow = formatPhoneNumberToUsLocale(phone);
  const areOpeningHoursVisible = showOpeningHours();
  const isRatingVisible = showOverallShopRating();
  const isGetDirectionsButtonVisible = showGetDirectionsButton();
  const days = prepareTimeSlots(workTimeSlots);
  const groupedDays = groupWorkingsDays(days);
  const isSelected = id === selectedShopId;
  const { isMobile } = useScreenSize();
  const refPhoneNumber = useRef(null);
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false);

  useEffect(() => {
    if (isCallRailSwap() && window.CallTrk?.swap) window.CallTrk.swap(refPhoneNumber.current);
  }, []);

  return (
    <ElementWrapper
      className={cn(styles.shopItem)}
      isSelected={isSelected}
      onClick={() => {
        onViewLocation(latitude, longitude);
        onClick(id);
      }}
      tabIndex={0}
      onMouseEnter={() => onShopHover(id)}
      onMouseLeave={() => onShopHover(null)}
      onFocus={() => onShopFocus(id)}
      onBlur={() => onShopFocus(null)}
    >
      <div className={styles.cardContent} id={`shop-${id}`}>
        {isSelected && (
          <div className={styles.checkIcon}>
            <PointIcon />
          </div>
        )}
        <Typography
          ellipsis
          lineClamp={2}
          fontWeight="bold"
          fontSize="xl"
          tooltipClassName={styles.shopNameTooltip}
          className={styles.name}
        >
          {name}
        </Typography>
        {isRatingVisible && (
          <div className={styles.rating}>
            <div className={styles.ratingStars}>{buildStarsHTML(rating)}</div>
            <div>{rating?.toFixed(2)}</div>
          </div>
        )}
        <Typography fontSize="md" className={styles.address} ellipsis lineClamp={2}>
          {address}{" "}
        </Typography>
        <Typography fontSize="md" className={styles.address} ellipsis lineClamp={2}>
          {city}
          {city ? "," : ""} {state} {zip}
        </Typography>

        {!isMobile && (
          <Collapse
            className={styles.moreInfo}
            isOpened={isMoreInfoOpen}
            content={isMoreInfoOpen ? "Less Info" : "More Info"}
            onCollapse={() => setIsMoreInfoOpen(!isMoreInfoOpen)}
          >
            <div className={styles.collapseContent}>
              {isGetDirectionsButtonVisible && (
                <div className={styles.getDirection}>
                  <MapPointIcon />
                  <div
                    className={styles.getDirectionLabel}
                    onClick={() => showInGoogleMaps(lat, lng)}
                  >
                    Get Direction
                  </div>
                </div>
              )}
              <div className={styles.phone}>
                <PhoneIcon2 width={14} height={14} />
                <a className={styles.phoneNumber} ref={refPhoneNumber} href={`tel:${phoneToShow}`}>
                  {phoneToShow}
                </a>
              </div>
              {areOpeningHoursVisible && workTimeSlots?.length > 0 && (
                <div className={styles.workingHours}>
                  <CalendarIcon2 />
                  <div className={styles.groupedDays}>
                    <GroupedWorkingHours groupedDays={groupedDays} />
                  </div>
                </div>
              )}
            </div>
          </Collapse>
        )}
      </div>
    </ElementWrapper>
  );
};

ShopItem.propTypes = {
  onClick: PropTypes.func,
  onViewLocation: PropTypes.func,
  onShopHover: PropTypes.func,
  onShopFocus: PropTypes.func,
  selectedShopId: PropTypes.string,
  shop: PropTypes.object,
};

export default ShopItem;
