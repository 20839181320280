import { WizardStep, EventNames } from "../constants";
import { raiseEventOnce } from "../services/events.service";
import {
  CONTINUE_TO_VEHICLE_AND_SERVICE,
  CONTINUE_TO_CONTACT,
  STEP_CHANGED,
  REQUEST_APPOINTMENT_SUCCESS,
} from "../redux";

export const raiseEventMiddleware = (store) => (next) => (action) => {
  if (
    action.type === CONTINUE_TO_VEHICLE_AND_SERVICE ||
    (action.type === STEP_CHANGED && action.payload.step === WizardStep.Vehicle)
  ) {
    raiseEventOnce(EventNames.DateTime);
  }
  if (
    action.type === CONTINUE_TO_CONTACT ||
    (action.type === STEP_CHANGED && action.payload.step === WizardStep.Contact)
  ) {
    raiseEventOnce(EventNames.VehicleService);
  }
  if (action.type === REQUEST_APPOINTMENT_SUCCESS) {
    raiseEventOnce(EventNames.RequestAppointment);
  }
  let result = next(action);
  return result;
};

// window.addEventListener("message", (evt) => {
//   console.log(evt);
// });
