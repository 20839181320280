// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Alert__alert--Dg066{display:flex;align-items:center;gap:16px;border-radius:6px;border:1px solid #f8b131;background:#fff3dc;padding:16px}", "",{"version":3,"sources":["webpack://./src/components/Alert/Alert.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":[".alert {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  border-radius: 6px;\n  border: 1px solid  #F8B131;\n  background:  #FFF3DC;\n  padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "Alert__alert--Dg066"
};
export default ___CSS_LOADER_EXPORT___;
