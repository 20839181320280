import React from "react";
import cn from "classnames";
import { ArrowBackIcon, ArrowRightIcon, SuccessIcon2, WarningIcon2 } from "../../svg-icons";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { getWidgetInPopup } from "../../services/config.service";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import { useScreenSize } from "../../hooks/useScreenSize";
import styles from "./Footer.scss";

const Footer = ({
  nextButtonLabel = "Next",
  backButtonLabel = "Back",
  backButtonIcon = ArrowBackIcon,
  isSuccessIcon,
  onBackClick,
  onNextClick,
  summary,
  showBackButton,
  showNextButton = true,
  isNextDisabled,
  isLoading,
}) => {
  const isWidgetPopup = getWidgetInPopup();
  const { isMobile } = useScreenSize();
  const BackButtonIcon = backButtonIcon;
  return (
    <div
      className={cn(styles.footer, {
        [styles.popupStyles]: isWidgetPopup,
      })}
    >
      {!isMobile && (
        <div className={styles.message}>
          {summary ? isSuccessIcon ? <SuccessIcon2 /> : <WarningIcon2 /> : null}
          <Typography
            parentClassName={styles.typography}
            className={styles.label}
            fontWeight="bold"
            fontSize="lg"
            ellipsis={true}
          >
            {summary}
          </Typography>
          <div></div>
        </div>
      )}
      <div className={styles.buttons}>
        {showBackButton && (
          <Button
            className={styles.button}
            title={
              <div className={styles.buttonTitle}>
                <BackButtonIcon width={16} height={16} />
                <div>{backButtonLabel}</div>
              </div>
            }
            variant="secondary"
            onClick={onBackClick}
          />
        )}
        {showNextButton && (
          <Button
            className={cn(styles.button, styles.nextButton)}
            title={
              <div className={styles.buttonTitle}>
                <div>{nextButtonLabel}</div>
                <ArrowRightIcon />
              </div>
            }
            onClick={onNextClick}
            isDisabled={isNextDisabled}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};
Footer.propTypes = {
  summary: PROP_TYPES_TEMPLATES.CHILDREN,
  nextButtonLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  isMobile: PropTypes.bool,
  isSuccessIcon: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  showBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

export default Footer;
