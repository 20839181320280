import LocationScene from "../../LocationScene/LocationScene";
import ServicesScene from "../../ServicesScene/ServicesScene";
import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../../services/analytics.service";
import { AppointmentType, SOURCES } from "../../../constants";
import { setAppointmentType, useRedux } from "../../../redux";
import DateTimeScene from "../../DateTimeScene/DateTimeScene";
import ContactScene from "../../ContactScene/ContactScene";
import {
  getEnabledTwoFactorAuthentication,
  getIsPlazaTire,
  getIsTenantWidget,
  getMatchingOnly,
  getSource,
} from "../../../services/config.service";
import { MapViewIcon } from "../../../svg-icons";
import { useScreenSize } from "../../../hooks/useScreenSize";
import Header from "../../LocationScene/components/Header/Header";
import VehicleSelectScene from "../../VehicleSelectScene/VehicleSelectScene";
import CampaignServicesStep from "../../CampaignServicesStep/CampaignServicesStep";
import MainVerificationStep from "../../MainVerificationStep/MainVerificationStep";
import CampaignVerificationStep from "../../CampaignVerificationStep/CampaignVerificationStep";

export const STEP_IDS = {
  LOCATION: "location",
  SERVICE: "service",
  DATE_TIME: "dateTime",
  CONTACT: "contact",
  VERIFICATION: "verification",
  VEHICLE_SELECT: "vehicleSelect",
  GUEST_VERIFICATION: "guestVerification",
  CAMPAIGN_SERVICE: "campaignService",
};

export const VERIFICATION_SUB_STEP_IDS = {
  VERIFICATION_FLOW: "verificationFlow",
  VERIFICATION_TYPE: "verificationType",
  VERIFICATION_CODE: "verificationCode",
  VERIFICATION_PROFILE: "verificationProfile",
};

export const generateSteps = ({ store }) => {
  const isTenantWidget = getIsTenantWidget();
  const source = getSource();
  const [state, dispatch] = useRedux(store);
  // fixme isPlazaTire remove/handle in future
  const isPlazaTire = getIsPlazaTire();
  const isEnabledTwoFactorAuthentication = getEnabledTwoFactorAuthentication();
  const isMatchingOnly = getMatchingOnly();

  const { isMobile } = useScreenSize();

  const locationStep = {
    id: STEP_IDS.LOCATION,
    component: LocationScene,
    header: Header,
    footerSummary: "Please select a location to continue.",
    searchQuery: "",
    isValid: false,
    showBackButton: isMobile,
    showNextButton: true,
    backButtonIcon: MapViewIcon,
    backButtonLabel: "Map View",
    isPreviousStepBlocked: true,
    isCustomHeader: true,
    isMobileListView: true,
    onNext() {
      registerAnalyticsEvent(CUSTOM_EVENTS.location_selected);
    },
  };

  const serviceStep = {
    id: STEP_IDS.SERVICE,
    component: ServicesScene,
    footerSummary: "Please select services to continue.",
    isValid: false,
    showBackButton: isTenantWidget,
    showNextButton: true,
    showShopInfo: true,
    headerWizardPosition: 1,
    onNext() {
      registerAnalyticsEvent(CUSTOM_EVENTS.service_information_fulfilled);
      if (isPlazaTire) {
        let plazaTireAppointmentType = AppointmentType.Waiting;
        const selectedServiceIds = state.appointmentDetails.services;
        const selectedServices = state.shopOptions.services.filter((s) =>
          selectedServiceIds.includes(s.serviceId),
        );
        if (
          selectedServices.find((s) => s.title.toLowerCase().includes("tire purchase"))?.serviceId
        ) {
          plazaTireAppointmentType = AppointmentType.Dropoff;
        }
        dispatch(setAppointmentType(plazaTireAppointmentType));
      }
    },
  };

  const campaignsServiceStep = {
    ...serviceStep,
    id: STEP_IDS.CAMPAIGN_SERVICE,
    component: CampaignServicesStep,
  };

  const dateTimeStep = {
    id: STEP_IDS.DATE_TIME,
    component: DateTimeScene,
    footerSummary: "Select all required options.",
    isValid: false,
    showBackButton: true,
    showNextButton: true,
    showShopInfo: true,
    headerWizardPosition: 2,
    onNext() {
      registerAnalyticsEvent(CUSTOM_EVENTS.appointment_time_selected);
    },
  };

  const verificationStep = {
    id: STEP_IDS.VERIFICATION,
    component: MainVerificationStep,
    isValid: true,
    showBackButton: true,
    showNextButton: true,
    showShopInfo: true,
    isNextStepBlocked: true,
    isPreviousStepBlocked: true,
    backButtonClickListener: null,
    nextButtonClickListener: null,
    headerWizardPosition: 3,
  };

  const campaignVerificationStep = {
    id: STEP_IDS.VERIFICATION,
    component: CampaignVerificationStep,
    isValid: false,
    showBackButton: false,
    showNextButton: true,
    showShopInfo: true,
    isNextStepBlocked: true,
    isPreviousStepBlocked: true,
    backButtonClickListener: null,
    nextButtonClickListener: null,
    headerWizardPosition: 0,
  };

  const vehicleSelectStep = {
    id: STEP_IDS.VEHICLE_SELECT,
    component: VehicleSelectScene,
    isValid: false,
    showBackButton: true,
    showNextButton: true,
    showShopInfo: true,
    shouldBeIgnored: false,
    headerWizardPosition: 3,
    onNext() {
      registerAnalyticsEvent(CUSTOM_EVENTS.vehicle_selected);
    },
  };

  const contactStep = {
    id: STEP_IDS.CONTACT,
    component: ContactScene,
    footerSummary: "Select all required options.",
    isValid: false,
    showBackButton: true,
    showNextButton: true,
    showShopInfo: true,
    showSummary: true,
    headerWizardPosition: 3,
    nextButtonLabel: "Request Appt.",
    isNextStepBlocked: true,
  };

  const getCampaignScenario = () => {
    if (isEnabledTwoFactorAuthentication) {
      return [
        { ...campaignVerificationStep, showMatchFormOnly: true, headerWizardPosition: 1 },
        { ...vehicleSelectStep, showBackButton: false, headerWizardPosition: 1 },
        campaignsServiceStep,
        {
          ...serviceStep,
          shouldBeIgnored: true,
          headerWizardPosition: 1,
        },
        dateTimeStep,
        contactStep,
        {
          ...verificationStep,
          id: STEP_IDS.GUEST_VERIFICATION,
        },
      ];
    }
    return [serviceStep, dateTimeStep, contactStep];
  };

  const getPublicVehicleScenario = () => {
    if (isEnabledTwoFactorAuthentication) {
      return [
        { ...campaignVerificationStep, showMatchFormOnly: true, headerWizardPosition: 1 },
        {
          ...vehicleSelectStep,
          showBackButton: false,
          headerWizardPosition: 1,
        },
        { ...campaignsServiceStep, showBackButton: false, headerWizardPosition: 1 },
        {
          ...serviceStep,
          shouldBeIgnored: true,
          headerWizardPosition: 1,
        },
        dateTimeStep,
        contactStep,
        {
          ...verificationStep,
          id: STEP_IDS.GUEST_VERIFICATION,
        },
      ];
    }

    return [serviceStep, dateTimeStep, contactStep];
  };

  const getMainScenario = () => {
    if (isTenantWidget) {
      return [
        locationStep,
        serviceStep,
        dateTimeStep,
        verificationStep,
        vehicleSelectStep,
        contactStep,
        {
          ...verificationStep,
          id: STEP_IDS.GUEST_VERIFICATION,
        },
      ];
    }

    if (isEnabledTwoFactorAuthentication) {
      return [
        serviceStep,
        dateTimeStep,
        verificationStep,
        vehicleSelectStep,
        contactStep,
        {
          ...verificationStep,
          id: STEP_IDS.GUEST_VERIFICATION,
        },
      ];
    }

    return [serviceStep, dateTimeStep, contactStep];
  };

  const getLookupOnlyScenario = () => {
    return [
      {
        ...verificationStep,
        showMatchFormOnly: true,
        showBackButton: false,
      },
      { ...vehicleSelectStep, showBackButton: true, headerWizardPosition: 1 },
      { ...serviceStep, showBackButton: true },
      dateTimeStep,
      contactStep,
      {
        ...verificationStep,
        isMatchingOnlyLastStep: true,
        id: STEP_IDS.GUEST_VERIFICATION,
      },
    ];
  };

  const scenarios = {
    [SOURCES.CAMPAIGNS]: getCampaignScenario(),
    [SOURCES.PUBLIC_VEHICLE]: getPublicVehicleScenario(),
    [SOURCES.GOOGLE]: getMainScenario(),
    [SOURCES.MAIN]: getMainScenario(),
  };

  if (isMatchingOnly) {
    return getLookupOnlyScenario();
  }

  return source && scenarios.hasOwnProperty(source) ? scenarios[source] : scenarios[SOURCES.MAIN];
};
