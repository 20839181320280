export const CUSTOM_EVENTS = {
  widget_loaded: "widget_loaded",
  location_selected: "location_selected",
  appointment_time_selected: "appointment_time_selected",
  service_information_fulfilled: "service_information_fulfilled",
  contact_information_provided: "contact_information_provided",
  appointment_scheduled: "appointment_scheduled",
  appointment_error: "appointment_error",
  hide_widget: "hide_widget",
  show_widget: "show_widget",
  widget_resize: "widget_resize",
  attachment_open: "attachment_open",
  verification_passed: "verification_passed",
  vehicle_selected: "vehicle_selected",
};

export const CLIENT_EVENTS_NAMES = {
  widget_loaded: "maw-widget-loaded",
  location_selected: "maw-location-selected",
  appointment_time_selected: "maw-appointment-time-selected",
  service_information_fulfilled: "maw-service-information-fulfilled",
  contact_information_provided: "maw-contact-information-provided",
  appointment_scheduled: "maw-appointment-scheduled",
  appointment_error: "maw-appointment-error",
  hide_widget: "maw-widget-hide",
  show_widget: "maw-widget-show",
  widget_resize: "maw-widget-resize",
  attachment_open: "attachment_open",
};

const firedEvents = {
  widget_loaded: false,
  location_selected: false,
  appointment_time_selected: false,
  service_information_fulfilled: false,
  contact_information_provided: false,
  appointment_scheduled: false,
  appointment_error: false,
};

export function registerAnalyticsEvent(eventname, values) {
  if (!firedEvents[eventname]) {
    const eventDetails = {
      event: eventname,
      widgetType: window.steerWidget?.widgetType, // Retrieve widgetType from dataLayer
      parentURL: document.referrer, // Add parent URL to event details
      ...values // custom values
    };

    window?.dataLayer?.push(eventDetails);
    window?.parent?.dataLayer?.push(eventDetails)
    // Fire event for pop-up version of widget
    window?.parent.dispatchEvent(
      new CustomEvent("maw-event", { detail: CLIENT_EVENTS_NAMES[eventname] }),
    );
    firedEvents[eventname] = true;
  }
}

export function registerCustomMawEvent(eventname) {
  window?.dataLayer?.push({ event: eventname });
  window?.parent?.dataLayer?.push({ event: eventname });
  window?.parent.dispatchEvent(
    new CustomEvent("maw-event", { detail: CLIENT_EVENTS_NAMES[eventname] }),
  );
}
