import { lookupShopCustomers, matchShopCustomers } from "../services/mechanic-api.client";
import useAsync from "./useAsync";
import { useErrorHandler } from "./useErrorHandler";

const useLookupCustomer = ({
  noCustomersCb,
  oneCustomerCb,
  multipleCustomersCb,
  isStrictMatch,
  shopId,
  tenantId,
}) => {
  const handleLookup = async (formValues) => {
    closeToast();
    if (isStrictMatch) {
      return await matchShopCustomers({ shopId, tenantId, formValues });
    }
    return await lookupShopCustomers({ shopId, tenantId, formValues });
  };

  const handleLookupSuccess = (response, formValues) => {
    const customersArray = [];

    if (isStrictMatch) {
     if(response.data?.customerId) customersArray.push(response.data);
    } else {
      for (const [key, value] of Object.entries(response.data)) {
        customersArray.push({ ...value, type: key });
      }
    }

    if (!customersArray.length) {
      noCustomersCb(formValues);
      return;
    }

    if (customersArray.length === 1) {
      oneCustomerCb(customersArray);
      return;
    }

    if (customersArray.length === 2) {
      multipleCustomersCb(customersArray);
    }
  };

  const { execute, errors, errorStatusCode, isLoading } = useAsync({
    asyncFunction: handleLookup,
    successFunction: handleLookupSuccess,
  });

  const { closeToast } = useErrorHandler({
    errors,
    errorStatusCode,
  });

  return { onLookupCustomer: execute, isLoading };
};

export default useLookupCustomer;
