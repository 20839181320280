import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import { PROP_TYPES_TEMPLATES } from "../../constants";
import styles from "./Checkbox.scss";

const Checkbox = (props) => {
  const {
    isChecked,
    defaultChecked,
    onChange,
    label,
    isSelective,
    disabled,
    readOnly,
    classNameLabel,
    name = "",
    inputRef,
    value,
    testId = "",
    className,
  } = props;

  return (
    <label className={cn(className, styles.Checkbox__wrapper, { [styles.disabled]: disabled })}>
      <span
        className={cn(styles.Checkbox, {
          [styles["Checkbox--isChecked"]]: isChecked || defaultChecked,
          [styles["Checkbox--isSelective"]]: isSelective,
          [styles.readOnly]: readOnly,
        })}
      >
        <input
          {...(testId ? { "data-testid": testId } : {})}
          value={value}
          defaultChecked={defaultChecked}
          name={name}
          ref={inputRef}
          className={styles.Checkbox__input}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            !disabled && !readOnly && onChange && onChange(e);
          }}
        />
      </span>
      {label && <span className={cn(styles.Checkbox__label, classNameLabel)}>{label}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isChecked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  defaultChecked: PropTypes.bool,
  isSelective: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  classNameLabel: PROP_TYPES_TEMPLATES.LABEL,
  inputRef: PROP_TYPES_TEMPLATES.REF,
  testId: PropTypes.string,
};

export default Checkbox;
