import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import Typography from "../Typography/Typography";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import styles from "../Label/Label.module.scss";

const Label = ({ label, additionalLabel, className, disabled }) => {
  if (!label) return null;

  return (
    <div className={styles.labelWrapper}>
      {label && (
        <Typography
          className={cn(styles.label, className, {
            [styles.label_disabled]: disabled,
          })}
          color="tertiary"
          fontWeight="semibold"
        >
          {label}
        </Typography>
      )}
      {additionalLabel && (
        <Typography className="ml-5" fontSize="sm" color="quaternary">
          ({additionalLabel})
        </Typography>
      )}
    </div>
  );
};

Label.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  label: PROP_TYPES_TEMPLATES.LABEL,
  additionalLabel: PROP_TYPES_TEMPLATES.LABEL,
  disabled: PropTypes.bool,
};

export default Label;
