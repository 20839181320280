import React, { useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./VehicleImage.scss";
import fallBackImage from "../../assets/unknown-vehicle.png";
import { useScreenSize } from "../../hooks/useScreenSize";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import { UnknownCarIcon } from "../../svg-icons";

function VehicleImage({
  vehicle = null,
  className,
  hasBorder = true,
  width = 80,
  height = 80,
  mobileSize = 76,
}) {
  const { isMobile } = useScreenSize();
  const [imageError, setImageError] = useState(false);
  const imageWidth = isMobile ? mobileSize : width;
  const imageHeight = isMobile ? mobileSize : height;

  const onImageError = () => {
    setImageError(true);
  };

  const ifValidImagePath = !imageError && vehicle?.imagePath;

  return (
    <div
      style={{
        width: imageWidth,
        minWidth: imageWidth,
        maxWidth: imageWidth,
        height: imageHeight,
      }}
      className={cn(className, styles.vehicleImage, {
        [styles.withBorder]: hasBorder && ifValidImagePath,
      })}
    >
      {ifValidImagePath ? (
        <img
          className={cn({ [styles.fallBackImage]: !ifValidImagePath })}
          src={vehicle?.imagePath}
          onError={onImageError}
          alt="Customer vehicle"
        />
      ) : (
        <UnknownCarIcon />
      )}
    </div>
  );
}

VehicleImage.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  vehicle: PropTypes.object,
  hasBorder: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  mobileSize: PropTypes.number,
};

export default VehicleImage;
