import { format } from "date-fns";
import { scroller } from "react-scroll";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";
import { getFixedTopBarHeight } from "../services/config.service";
import { ENV_TYPES } from "../constants";
import { ENV_TYPE } from "../services/env.service";

export function formatHoursToAmPm(time) {
  let d = new Date(); // Creates a Date Object using the clients current time

  let [hours, minutes, seconds] = time.split(":"); // Using ES6 destructuring

  d.setHours(+hours); // Set the hours, using implicit type coercion
  d.setMinutes(minutes); // You can pass Number or String. It doesn't really matter
  d.setSeconds(seconds);

  return minutes !== "00" ? format(d, "h:mm aa") : format(d, "h aa");
}

export function getOnlyDigits(str) {
  if (!str) return "";
  return str.replace(/\D/g, "");
}

const DAYS_ORDER = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const divider = ".";

const getNumberOfDay = (timeSlot) =>
  timeSlot.includes(divider) ? parseInt(timeSlot.split(divider)[0]) : 0;

export const getTimeFromTimeSpan = (timeSlot) =>
  timeSlot.includes(divider) ? timeSlot.split(divider)[1] : timeSlot;

export const getBusinessHoursForDay = (timeSlots, numberOfDay) => {
  if (timeSlots && timeSlots.length) {
    const dayTimeSlots = timeSlots.filter((slot) => getNumberOfDay(slot.from) === numberOfDay);
    return {
      dayOfWeek: numberOfDay,
      timeIntervals: dayTimeSlots.map(({ from, to }) => {
        return {
          from: getTimeFromTimeSpan(from),
          to: getTimeFromTimeSpan(to),
        };
      }),
    };
  }

  return {
    dayOfWeek: numberOfDay,
    timeIntervals: [],
  };
};

export const getBusinessHours = (workTimeSlots) => [
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["sunday"]),
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["monday"]),
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["tuesday"]),
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["wednesday"]),
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["thursday"]),
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["friday"]),
  getBusinessHoursForDay(workTimeSlots, DAYS_ORDER["saturday"]),
];

export function prepareTimeSlots(days) {
  return getBusinessHours(days);
}

export function groupWorkingsDays(days) {
  const firstDay = days[0];
  const firstGroup = {
    timeIntervals: firstDay.timeIntervals,
    dayOfWeekFrom: firstDay.dayOfWeek,
    dayOfWeekTo: firstDay.dayOfWeek,
  };
  const grouped = [firstGroup];

  days.forEach((item, i) => {
    if (i === 0) return; //skip first item
    const groupsLastIndex = grouped.length - 1;
    if (isEqual(item.timeIntervals, grouped[groupsLastIndex].timeIntervals)) {
      grouped[groupsLastIndex].dayOfWeekTo = item.dayOfWeek;
    } else {
      const newGroup = {
        timeIntervals: item.timeIntervals,
        dayOfWeekFrom: item.dayOfWeek,
        dayOfWeekTo: item.dayOfWeek,
      };
      grouped.push(newGroup);
    }
  });

  return grouped;
}

export const formatPhoneNumberToUsLocale = (phoneNumberString = "") => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match =
    phoneNumberString.length === 10
      ? cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{3})$/)
      : cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return null;
};

export function formatPhoneNumber(phone) {
  // TODO '.slice(-10)' - hack for different formats. Remove\change when we get rid of our custom MSO widgets:)
  const cleanNum = phone.replace(/\D/g, "").slice(-10);
  const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return match.slice(1).filter(Boolean).join("-");
  }
  return cleanNum;
}

export const addPlusOneToPhone = (phoneNumberString) => {
  if (!phoneNumberString) return;

  let phoneWithPlusOne = phoneNumberString;

  //insert "+" on beginning of number if it is missing;
  if (phoneNumberString && phoneNumberString[0] !== "+") {
    phoneWithPlusOne = `+${phoneNumberString}`;
  }

  //insert "1" after plus if it is missing;
  if (phoneWithPlusOne && phoneWithPlusOne.slice(0, 2) !== "+1") {
    const position = 1;
    const oneChar = "1";
    phoneWithPlusOne = [
      phoneWithPlusOne.slice(0, position),
      oneChar,
      phoneWithPlusOne.slice(position),
    ].join("");
  }

  return phoneWithPlusOne;
};

export const getInputErrors = (error) => {
  const { type, message } = error || {};
  const serverErrorMessage = type === "server" ? message : "";
  const validationErrorMessage = type !== "server" ? message : "";

  return { serverErrorMessage, validationErrorMessage };
};

export const getObjectKeysPath = function (obj = {}, prefix) {
  const isObject = function (x) {
    return Object.prototype.toString.call(x) === "[object Object]";
  };

  const keys = Object.keys(obj);
  prefix = prefix ? prefix + "." : "";
  return keys.reduce(function (result, key) {
    if (isObject(obj[key])) {
      result = result.concat(getObjectKeysPath(obj[key], prefix + key));
    } else {
      result.push(prefix + key);
    }
    return result;
  }, []);
};

export const camelCaseObjectPath = (path) =>
  path
    .split(".")
    .map((path) => path.charAt(0).toLowerCase() + path.slice(1))
    .join(".");

export const transformToFormErrorsFormat = (errors = {}, type) => {
  return Object.keys(errors).reduce((acc, cur) => {
    acc.push({
      type,
      name: cur,
      message: errors[cur].join(),
    });
    return acc;
  }, []);
};

export function showInGoogleMaps(lat, long) {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${long}`;
  window.open(googleMapsUrl, "_blank");
}

export function getJsonAttribute(scriptTag, name, defaultValue) {
  return scriptTag.hasAttribute(name) ? JSON.parse(scriptTag.getAttribute(name)) : defaultValue;
}
export const getEmbeddedIos = (isWidgetPublicPage) => {
  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
  return isIos && !isWidgetPublicPage;
};

export const renderAutoOpsWidget = (apiKey) => {
  const scriptId = "portal-scripts";
  let script = document.getElementById(scriptId);
  if (!script) {
    const src =
      ENV_TYPE === ENV_TYPES.PRODUCTION
        ? "https://portal.autoops.com/portal-scripts.js"
        : "https://portal.integration.autoops.com/portal-scripts-int.js";
    script = document.createElement("script");
    script.src = src;
    script.setAttribute("id", scriptId);
    script.setAttribute("data-api-key", apiKey);
    script.setAttribute("data-embed-mode", true);
    script.setAttribute("data-chat-disabled", true);
    document.body.appendChild(script);
  }
  return <div id="ao-embed-id" />;
};

export const joinFullName = (customer) => {
  const { firstName, lastName, companyName } = customer;
  if (companyName) return companyName;
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (firstName) return firstName;
  if (lastName) return lastName;

  return " ";
};

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function numberWithCommas(x) {
  if (typeof x === "number" && isFinite(x)) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return null;
}
