// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MatchingForm__form--4qRT1{margin-top:8px}.MatchingForm__verificationRadios--P76OT{display:flex;align-items:center;gap:16px;margin-top:24px}.MatchingForm__consentCheckbox--YCiCJ{margin-top:24px}.MatchingForm__title--y0Gny{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/scenes/VerificationScene/components/VerificationFlow/components/MatchingForm/MatchingForm.scss"],"names":[],"mappings":"AAAA,2BACE,cAAA,CAGF,yCACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,eAAA,CAGF,sCACE,eAAA,CAGF,4BACE,iBAAA","sourcesContent":[".form {\n  margin-top: 8px;\n}\n\n.verificationRadios {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-top: 24px;\n}\n\n.consentCheckbox {\n  margin-top: 24px;\n}\n\n.title {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "MatchingForm__form--4qRT1",
	"verificationRadios": "MatchingForm__verificationRadios--P76OT",
	"consentCheckbox": "MatchingForm__consentCheckbox--YCiCJ",
	"title": "MatchingForm__title--y0Gny"
};
export default ___CSS_LOADER_EXPORT___;
