// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VehicleImage__vehicleImage--AjDp7{position:relative;display:flex;align-items:center;justify-content:center}.VehicleImage__vehicleImage--AjDp7 img{object-fit:cover;width:100%;height:100%;border-radius:50%}.VehicleImage__vehicleImage--AjDp7 img.VehicleImage__fallBackImage--MaTmi{object-fit:contain}.VehicleImage__withBorder--gUwip{border:2px solid #e5e8f0;border-radius:50%}", "",{"version":3,"sources":["webpack://./src/components/VehicleImage/VehicleImage.scss"],"names":[],"mappings":"AAAA,mCACE,iBAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,uCACE,gBAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CAEA,0EACE,kBAAA,CAKN,iCACE,wBAAA,CACA,iBAAA","sourcesContent":[".vehicleImage {\n  position: relative;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n\n    &.fallBackImage{\n      object-fit: contain;\n    }\n  }\n}\n\n.withBorder {\n  border: 2px solid #e5e8f0;\n  border-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vehicleImage": "VehicleImage__vehicleImage--AjDp7",
	"fallBackImage": "VehicleImage__fallBackImage--MaTmi",
	"withBorder": "VehicleImage__withBorder--gUwip"
};
export default ___CSS_LOADER_EXPORT___;
