import { useEffect } from "react";
import { camelCaseObjectPath, getObjectKeysPath } from "../utils";
import { useErrorToast } from "./useErrorToast";
import useSetErrorsIntoForm from "./useSetErrorsIntoForm";

export const useErrorHandler = ({
  formFields,
  errors = {},
  setError,
  isModal,
  isScrollToField,
  errorStatusCode,
}) => {
  const formErrors = {};
  const toastErrors = {};
  const formFieldsKeysPath = getObjectKeysPath(formFields);
  const formFieldsCamelCasedKeys = formFieldsKeysPath.map(camelCaseObjectPath);

  const toastType = errorStatusCode >= 400 && errorStatusCode < 500 ? "warning" : "error";

  useEffect(() => {
    Object.entries(errors).forEach(([key, value]) => {
      const camelCaseKey = camelCaseObjectPath(key);

      if (formFieldsCamelCasedKeys.includes(camelCaseKey)) {
        formErrors[camelCaseKey] = value;
      } else {
        toastErrors[key] = value;
      }
    });
  }, [errors]);

  useSetErrorsIntoForm(
    {
      errors: formErrors,
      setError,
      isModal,
    },
    isScrollToField,
  );

  const { closeToast } = useErrorToast({
    errors: toastErrors,
    type: toastType,
    errorStatusCode,
  });

  return { closeToast };
};
