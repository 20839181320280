import styles from "./star-build.scss";
import cn from "classnames";
import {
  FullStarIcon,
  EmptyStarIcon,
  HalfStarIcon4zero5,
  HalfStarIcon3zero5,
  HalfStarIcon2zero5,
  HalfStarIcon1zero5,
  HalfStarIcon05,
} from "../svg-icons";

export function buildStarsHTML(rating) {
  let numOfFullStars = 0;
  let numOfHalfStars = 0;
  let numOfEmptyStars = 0;
  const roundedRating = Math.floor(rating);

  const partialRatingAmount = rating - roundedRating;
  if (partialRatingAmount < 0.2) {
    numOfFullStars = roundedRating;
    numOfEmptyStars = 5 - roundedRating;
  } else if (partialRatingAmount > 0.8) {
    numOfFullStars = roundedRating + 1;
    numOfEmptyStars = 5 - roundedRating - 1;
  } else {
    numOfFullStars = roundedRating;
    numOfEmptyStars = 5 - roundedRating - 1;
    numOfHalfStars = 1;
  }

  return (
    <>
      <FullStars count={numOfFullStars} rating={rating} />{" "}
      <HalfStars count={numOfHalfStars} rating={rating} /> <EmptyStars count={numOfEmptyStars} />{" "}
    </>
  );
}

function FullStars({ count, rating }) {
  const colorOfIcon = starColor(rating);
  let fullStars = [];

  for (let i = 0; i < count; i++) {
    fullStars.push(
      <div key={i}>
        <FullStarIcon className={cn(styles.star, styles[colorOfIcon])} />
      </div>,
    );
  }
  return fullStars;
}

function HalfStars({ count, rating }) {
  let halfStars = [];

  for (let i = 0; i < count; i++) {
    halfStars.push(<div key={i}>{halfStar(rating)}</div>);
  }

  return halfStars;
}

function EmptyStars({ count }) {
  let emptyStars = [];

  for (let i = 0; i < count; i++) {
    emptyStars.push(
      <div key={i}>
        <EmptyStarIcon className={styles.star} />
      </div>,
    );
  }

  return emptyStars;
}

function halfStar(rating) {
  if (rating < 1) {
    return <HalfStarIcon05 className={styles.star} />;
  } else if (rating > 1 && rating < 2) {
    return <HalfStarIcon1zero5 className={styles.star} />;
  } else if (rating > 2 && rating < 3) {
    return <HalfStarIcon2zero5 className={styles.star} />;
  } else if (rating > 3 && rating < 4) {
    return <HalfStarIcon3zero5 className={styles.star} />;
  } else if (rating > 4 && rating < 5) {
    return <HalfStarIcon4zero5 className={styles.star} />;
  }
  return;
}

function starColor(rating) {
  let colorStyle;

  if (rating <= 1) {
    colorStyle = `starFull1`;
  } else if (rating > 1 && rating <= 2) {
    colorStyle = `starFull2`;
  } else if (rating > 2 && rating <= 3) {
    colorStyle = `starFull3`;
  } else if (rating > 3 && rating <= 4) {
    colorStyle = `starFull4`;
  } else if (rating > 4) {
    colorStyle = `starFull5`;
  }

  return colorStyle;
}
