import React from "react";
import cn from "classnames";
import ClosePopupButton from "../../components/ClosePopupButton/ClosePopupButton";
import WizardStepper from "../WizardStepper/WizardStepper";
import { getWidgetInPopup } from "../../services/config.service";
import styles from "./Header.scss";

const Header = () => {
  const isWidgetPopup = getWidgetInPopup();

  return (
    <div
      className={cn(styles.header, { [styles.headerPopup]: isWidgetPopup })}
      id={isWidgetPopup ? "popup-header" : "header"}
    >
      <div className={cn(styles.flexCenter, styles.justifyBetween)}>
        <h1 className={styles.title}>Schedule Appointment</h1>
        {isWidgetPopup && <ClosePopupButton className={styles.mobileCloseButton} />}
      </div>
      <div className={styles.flexCenter}>
        <WizardStepper />
        {isWidgetPopup && <ClosePopupButton className={styles.desktopCloseButton} />}
      </div>
    </div>
  );
};

export default Header;
