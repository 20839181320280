import React from "react";
import cn from "classnames";
import styles from "./ConsentCheckbox.scss";
import Checkbox from "../../components/CheckBox/CheckBox";
import Typography from "../../components/Typography/Typography";
import { getDisclaimerTextWithReplacedTokens, useRedux } from "../../redux";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../constants";

const ConsentCheckbox = ({ onChange, value, store, className }) => {
  const [state] = useRedux(store);

  const disclaimerTextWithReplacedTokens = getDisclaimerTextWithReplacedTokens(state);

  return (
    <div className={cn(styles.consentCheckbox, className)}>
      <Checkbox
        value={value}
        isChecked={value}
        onChange={(e) => {
          e.stopPropagation();
          onChange(e.target.checked);
        }}
      />
      <div className={styles.disclaimer}>
        {disclaimerTextWithReplacedTokens}{" "}
        <Typography color="link" tag="span">
          <a
            href="https://steercrm.com/terms-and-conditions"
            target="_blank"
            className={styles.link}
          >
            Terms and Conditions
          </a>
        </Typography>
        <span className={styles.dividerLine}></span>
        <Typography color="link" tag="span">
          <a href="https://steercrm.com/privacy-policy" target="_blank" className={styles.link}>
            Privacy Policy
          </a>
        </Typography>
      </div>
    </div>
  );
};

ConsentCheckbox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  store: PropTypes.object,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default ConsentCheckbox;
