import React, { useContext, useState } from "react";
import cn from "classnames";
import styles from "./Header.scss";
import { SearchIcon } from "../../../../svg-icons";
import ClosePopupButton from "../../../../components/ClosePopupButton/ClosePopupButton";
import SearchInput from "../../../../components/Inputs/Searchinput/SearchInput";
import { getWidgetInPopup } from "../../../../services/config.service";
import PropTypes from "prop-types";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import { WizardContext } from "../../../Wizard/Wizard";

const Header = () => {
  const { updateCurrentWizardStep, activeStep } = useContext(WizardContext);
  const isWidgetPopup = getWidgetInPopup();
  const { isMobile } = useScreenSize();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const handleSearch = (value) => {
    updateCurrentWizardStep({
      searchQuery: value,
    });
  };

  return (
    <div
      className={cn(styles.header, { [styles.popupHeader]: isWidgetPopup })}
      id={isWidgetPopup ? "popup-header" : "header"}
    >
      {!isSearchOpen && <div className={styles.title}>Select Shop Location</div>}

      {isMobile && !isSearchOpen ? (
        <div className={styles.flexCenter}>
          <SearchIcon onClick={() => setIsSearchOpen(true)} height={19} width={19} />
          {isWidgetPopup && <ClosePopupButton />}
        </div>
      ) : (
        <div className={cn(styles.flexCenter, styles.mobileFullWidth)}>
          <SearchInput
            isMobile={isMobile}
            placeholder="Search..."
            value={activeStep.searchQuery}
            onCloseClick={() => {
              setIsSearchOpen(false);
              handleSearch("");
            }}
            onChange={(evt) => {
              handleSearch(evt.target.value);
            }}
          />
          {isWidgetPopup && <ClosePopupButton />}
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  activeStep: PropTypes.object,
  updateCurrentWizardStep: PropTypes.func,
};

export default Header;
