import React, { useContext, useEffect } from "react";
import VerificationScene from "../VerificationScene/VerificationScene";
import { STEP_IDS, VERIFICATION_SUB_STEP_IDS } from "../Wizard/utils/stepsGenerator";
import {
  getCustomerId,
  getLookupCustomer,
  getShopId,
  getTenantId,
  hideContactConsent,
  saveMatchedCustomers,
  saveLookupCustomer,
  setGuestFlow,
  useRedux,
} from "../../redux";
import { WizardContext } from "../Wizard/Wizard";
import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../services/analytics.service";
import useLookupCustomer from "../../hooks/useLookupCustomer";
import { getSource } from "../../services/config.service";
import { SOURCES } from "../../constants";

const CampaignVerificationStep = ({ store }) => {
  const [state, dispatch] = useRedux(store);
  const shopId = getShopId(state);
  const tenantId = getTenantId(state);
  const customerId = getCustomerId(state);
  const selectedCustomer = getLookupCustomer(state);
  const source = getSource();
  const isPublicVehicle = source === SOURCES.PUBLIC_VEHICLE;

  const { activeStep, updateCurrentWizardStep, next, updateWizardStepById } =
    useContext(WizardContext);
  const { activeSubStepIndex = 0, backButtonClickListener } = activeStep;

  useEffect(() => {
    if (backButtonClickListener) {
      handlePreviousSubStep();
    }
  }, [backButtonClickListener]);

  const handleNextSubStep = () => {
    updateCurrentWizardStep({
      isValid: false,
      nextButtonClickListener: null,
      activeSubStepIndex: activeSubStepIndex + 1,
    });
  };

  const handlePreviousSubStep = () => {
    updateCurrentWizardStep({
      backButtonClickListener: null,
      isLoading: false,
      activeSubStepIndex: activeSubStepIndex - 1,
    });
    if (activeSubStepIndex === 1) {
      updateCurrentWizardStep({
        showBackButton: false,
      });
    }
  };

  const { onLookupCustomer, isLoading } = useLookupCustomer({
    tenantId,
    shopId,
    noCustomersCb: (formValues) => {
      const { consentOptIn } = formValues;
      updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
        shouldBeIgnored: true,
      });
      updateWizardStepById(STEP_IDS.VERIFICATION, {
        shouldBeIgnored: true,
      });
      updateWizardStepById(STEP_IDS.CAMPAIGN_SERVICE, {
        shouldBeIgnored: true,
      });
      updateWizardStepById(STEP_IDS.SERVICE, {
        shouldBeIgnored: false,
        showBackButton: false,
      });
      dispatch(setGuestFlow(true));
      // Show/hide consent checkbox on last contact step if consent checkbox was clicked on 2fa step
      if (consentOptIn) {
        dispatch(hideContactConsent());
      }
      next();
    },

    oneCustomerCb: (customersArray) => {
      dispatch(saveLookupCustomer(customersArray[0]));
      updateCurrentWizardStep({
        showBackButton: true,
        subSteps: [
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_FLOW,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE,
        ],
      });
      if (customerId) {
        if (customersArray[0].customerId === customerId) {
          if (isPublicVehicle) {
            updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
              shouldBeIgnored: true,
            });
          }
          next();
        } else {
          if (isPublicVehicle) {
            updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
              shouldBeIgnored: false,
            });
          }
          handleNextSubStep();
        }
        return;
      }

      handleNextSubStep();
    },
    multipleCustomersCb: (customersArray) => {
      dispatch(saveMatchedCustomers(customersArray));
      updateCurrentWizardStep({
        showBackButton: true,
        subSteps: [
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_FLOW,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_PROFILE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE,
        ],
      });
      handleNextSubStep();
    },
  });

  const handleProfileNext = () => {
    if (customerId === selectedCustomer.customerId) {
      updateCurrentWizardStep({
        shouldBeIgnored: true,
      });
      if (isPublicVehicle) {
        updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
          shouldBeIgnored: true,
        });
      }
      next();
      return;
    }
    if (isPublicVehicle) {
      updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
        shouldBeIgnored: false,
      });
    }
    handleNextSubStep();
  };

  useEffect(() => {
    updateCurrentWizardStep({ isLoading });
  }, [isLoading]);

  const handleVerificationSuccess = async () => {
    registerAnalyticsEvent(CUSTOM_EVENTS.verification_passed);
    updateWizardStepById(STEP_IDS.CONTACT, {
      isValid: true,
    });
    updateCurrentWizardStep({
      shouldBeIgnored: true,
    });
    next();
  };

  return (
    <div>
      <VerificationScene
        store={store}
        onLookupCustomer={onLookupCustomer}
        onVerificationSuccess={handleVerificationSuccess}
        onNextSubStep={handleNextSubStep}
        onPreviousSubStep={handlePreviousSubStep}
        onProfileNext={handleProfileNext}
      />
    </div>
  );
};

export default CampaignVerificationStep;
