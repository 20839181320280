import React from "react";
import cn from "classnames";
import styles from "./VehicleSection.scss";
import Select from "../../components/Select/Select";
import Typography from "../../components/Typography/Typography";
import {
  getAreMakesLoaded,
  getAreModelsLoaded,
  getIsMakeFieldDisabled,
  getIsModelFieldDisabled,
  setVehicleValue,
  useRedux,
} from "../../redux";
import { DEFAULT_BACKGROUND_COLOR, getBackgroudColor } from "../../services/config.service";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import PropTypes from "prop-types";

const VehicleSection = ({ store, isShowAdditionalInfo = false, className }) => {
  const [state, dispatch] = useRedux(store);
  const { vehicle } = state.shopOptions;
  const selectedVehicle = state.appointmentDetails.vehicle;

  const areModelsLoaded = getAreModelsLoaded(state);
  const areMakesLoaded = getAreMakesLoaded(state);
  const areModelFieldDisabled = getIsModelFieldDisabled(state);
  const areMakeFieldDisabled = getIsMakeFieldDisabled(state);

  const backgroundColor = getBackgroudColor();
  const isAppWhiteBackground =
    backgroundColor.toLowerCase() === DEFAULT_BACKGROUND_COLOR.toLowerCase();

  const { years = [], makes = [], models = [] } = vehicle;
  const sortedYears = years.slice().sort((a, b) => b.value - a.value);

  const handleChange = (field, value) => dispatch(setVehicleValue(field, value));

  return (
    <div className={cn(styles.vehicleSection, className)}>
      <Typography fontSize="xl" fontWeight="bold">
        Tell Us About Your Vehicle
      </Typography>
      <div className="container">
        <div className={styles.vehicles}>
          <Select
            label="Year"
            options={sortedYears}
            value={selectedVehicle.year}
            onChange={({ value }) => handleChange("year", value)}
            isLoaded={true}
            isDisabled={false}
            isGrayTheme={isAppWhiteBackground}
          />
          <Select
            label="Make"
            options={makes}
            value={selectedVehicle.make}
            onChange={({ value }) => handleChange("make", value)}
            isLoaded={areMakesLoaded}
            isDisabled={areMakeFieldDisabled}
            tooltipText="Select year first"
            isGrayTheme={isAppWhiteBackground}
          />
          <Select
            label="Model"
            options={models}
            value={selectedVehicle.model}
            onChange={({ value }) => handleChange("model", value)}
            isLoaded={areModelsLoaded}
            isDisabled={areModelFieldDisabled}
            tooltipText="Select make first"
            isGrayTheme={isAppWhiteBackground}
          />
        </div>
        {isShowAdditionalInfo && (
          <div className={styles.additionalInfo}>
            <Typography fontSize="xl" fontWeight="bold">
              Additional Info (optional)
            </Typography>
            <div className={styles.additionalInputs}>
              <TextInput label="License plate" isGrayTheme={isAppWhiteBackground} />{" "}
              <TextInput label="VIN code" isGrayTheme={isAppWhiteBackground} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

VehicleSection.propTypes = {
  store: PropTypes.object,
  isShowAdditionalInfo: PropTypes.bool,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default VehicleSection;
