import React from "react";
import cn from "classnames";
import styles from "./Day.module.scss";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../../../../../constants";

const Day = ({ day, isSelected, isToday, isEnabled, onClick }) => {
  return (
    <>
      <span
        className={cn(styles.day, {
          [styles.today]: isToday,
          [styles.selectedDay]: isSelected,
          [styles.disabledDay]: !isEnabled,
        })}
        onClick={isEnabled ? onClick : () => false}
        data-tip="There is no availability on this date"
        data-tip-disable={isEnabled}
        style={{
          marginLeft: day.getDate() === 1 ? day.getDay() * 14.28 + "%" : 0,
        }}
      >
        <span>
          <span className={styles.transparentSection}></span>
          {format(day, "d")}
        </span>
      </span>
      <ReactTooltip className={styles.customTooltip} backgroundColor="#3E4150" effect="float" />
    </>
  );
};

Day.propTypes = {
  isSelected: PropTypes.bool,
  isToday: PropTypes.bool,
  isEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  day: PROP_TYPES_TEMPLATES.DATE,
};

export default Day;
