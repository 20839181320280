import React, { useContext, useEffect } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import styles from "./ContactScene.scss";
import VehicleSection from "../../templates/VehicleSection/VehicleSection";
import ContactForm from "./components/ContactForm/ContactForm";
import {
  getContactInfo,
  getContactStepValue,
  getIsContactConsentShown,
  getIsGuestFlow,
  getSelectedCustomer,
  getShopId,
  getTenantId,
  saveSelectedCustomer,
  saveLookupCustomer,
  setContactInfo,
  setVerificationMethod,
  useRedux,
} from "../../redux";
import {
  getEnabledTwoFactorAuthentication,
  getIsLoanerVehicleAllow,
  getIsPlazaTire,
  getIsPreview,
  getMatchingOnly,
  getRwgToken,
  getWidgetInPopup,
} from "../../services/config.service";
import { useForm, useWatch } from "react-hook-form";
import { contactSchema } from "../../formSchemas";
import useAsync from "../../hooks/useAsync";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { WizardContext } from "../Wizard/Wizard";
import { STEP_IDS, VERIFICATION_SUB_STEP_IDS } from "../Wizard/utils/stepsGenerator";
import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../services/analytics.service";
import {
  submitFailedCallback,
  submitSuccessCallback,
  submitAppointment,
} from "../Wizard/utils/utils";
import { AppointmentType } from "../../constants";
import useLookupCustomer from "../../hooks/useLookupCustomer";

const ContactScene = ({ store }) => {
  const { updateCurrentWizardStep, activeStep, updateWizardStepById, next } =
    useContext(WizardContext);
  const [state, dispatch] = useRedux(store);
  const contactInfo = getContactInfo(state);
  const isContactConsentShown = getIsContactConsentShown(state);
  const isGuestFlow = getIsGuestFlow(state);
  const shopId = getShopId(state);
  const tenantId = getTenantId(state);
  const selectedCustomer = getSelectedCustomer(state);
  const isWidgetPopup = getWidgetInPopup();
  const rwgToken = getRwgToken();
  const isEnabledTwoFactorAuthentication = getEnabledTwoFactorAuthentication();
  const isPreview = getIsPreview();
  const isPlazaTire = getIsPlazaTire();
  const isMatchingOnly = getMatchingOnly();

  const appointmentType = state.appointmentDetails.appointmentType;
  const isLoanerVehicleAllowed =
    getIsLoanerVehicleAllow() && appointmentType === AppointmentType.Dropoff && !isPlazaTire;

  const { control, formState, getValues, setError, reset } = useForm({
    defaultValues: {
      appointmentCustomer: {
        consentOptIn: false,
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        comments: "",
        coupon: "",
        isLoanerVehicleNeeded: false,
      },
    },
    resolver: contactSchema({ isGuestFlow, isContactConsentShown }),
    mode: "onChange",
  });
  const { isValid } = formState;
  const formValues = getValues();
  const formContactInfo = useWatch({ control, name: "appointmentCustomer" });

  const {
    execute: submitHandler,
    errors,
    isLoading,
    errorStatusCode,
  } = useAsync({
    asyncFunction: async () => {
      await submitAppointment({ isPreview, isLoanerVehicleAllowed, dispatch });
    },
    successFunction: () => {
      submitSuccessCallback({ dispatch, contactInfo: formContactInfo, rwgToken });
    },
    failedFunction: (error) => {
      submitFailedCallback({ error, dispatch });
    },
  });

  const { closeToast } = useErrorHandler({
    formFields: isGuestFlow ? formValues : {},
    setError,
    isScrollToField: false,
    isModal: false,
    errors,
    errorStatusCode,
  });

  const { onLookupCustomer, isLoading: isMatchingLoading } = useLookupCustomer({
    tenantId,
    shopId,
    isStrictMatch: true,
    noCustomersCb: async () => {
      await submitHandler();
    },
    oneCustomerCb: async (customersArray) => {
      updateWizardStepById(STEP_IDS.GUEST_VERIFICATION, {
        subSteps: [
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE,
        ],
      });
      dispatch(setVerificationMethod(""));
      dispatch(saveLookupCustomer(customersArray[0]));
      dispatch(
        saveSelectedCustomer({
          firstName: formValues.appointmentCustomer.firstName,
          lastName: formValues.appointmentCustomer.lastName,
          phone: formValues.appointmentCustomer.phone,
          email: formValues.appointmentCustomer.email,
          primaryPhoneNumber: formValues.appointmentCustomer.phone,
          primaryEmail: formValues.appointmentCustomer.email,
        }),
      );
      if (isMatchingOnly) {
        await submitHandler();
        return;
      }
      next();
    },
  });

  useEffect(() => {
    reset({ appointmentCustomer: contactInfo });
  }, []);

  useEffect(() => {
    dispatch(setContactInfo(formContactInfo));
  }, [...Object.values(formContactInfo)]);

  useEffect(() => {
    updateCurrentWizardStep({
      isLoading: isLoading || isMatchingLoading,
    });
  }, [isLoading, isMatchingLoading]);

  useEffect(() => {
    if (activeStep?.nextButtonClickListener && isValid) {
      (async () => {
        registerAnalyticsEvent(CUSTOM_EVENTS.contact_information_provided);
        closeToast();
        isGuestFlow && (isEnabledTwoFactorAuthentication || isMatchingOnly)
          ? await onLookupCustomer({
              phoneNumber: formValues.appointmentCustomer.phone,
              email: formValues.appointmentCustomer.email,
              lastName: formValues.appointmentCustomer.lastName,
            })
          : await submitHandler();
      })();
    }
  }, [activeStep?.nextButtonClickListener]);

  useEffect(() => {
    updateCurrentWizardStep({
      footerSummary: isValid
        ? getContactStepValue(
            state,
            isGuestFlow ? formValues.appointmentCustomer : selectedCustomer,
            isValid,
          )
        : "Select all required options.",
      isValid: !!isValid,
    });
  }, [isValid, isGuestFlow]);

  return (
    <div
      className={cn(styles.contactInfo, {
        [styles.contactInfoPopup]: isWidgetPopup,
      })}
    >
      <>
        {isGuestFlow && <VehicleSection store={store} className={styles.vehicleSection} />}
        <div className={styles.contactInfo}>
          <div className={styles.contactInfoContainer}>
            <ContactForm control={control} store={store} isShowMainInfo={isGuestFlow} />
          </div>
        </div>
      </>
    </div>
  );
};

ContactScene.propTypes = {
  store: PropTypes.object,
};

export default ContactScene;
