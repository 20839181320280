import cn from "classnames";
import { useEffect, useRef, useState } from "react";

import { PhoneIcon2 } from "../../svg-icons";
import { formatPhoneNumberToUsLocale, prepareTimeSlots } from "../../utils";
import { getWidgetInPopup, isCallRailSwap } from "../../services/config.service";
import styles from "./ShopContacts.scss";
import Collapse from "../../components/Collapse/Collapse";
import { WorkSchedule } from "./components/WorkSchedule/WorkSchedule";
import Skeleton from "./components/Skeleton/Skeleton";
import { getAreShopDetailsLoaded, getShopDetailsValue, isOneShopOnly, useRedux } from "../../redux";
import { useScreenSize } from "../../hooks/useScreenSize";
import { STEP_IDS } from "../Wizard/utils/stepsGenerator";
import Typography from "../../components/Typography/Typography";

export default function ShopContacts({ store, steps, onChangeStep }) {
  const [state] = useRedux(store);
  const isOneShop = isOneShopOnly(state);
  const shopDetails = getShopDetailsValue(state);
  const areShopDetailsLoaded = getAreShopDetailsLoaded(state);
  const isWidgetPopup = getWidgetInPopup();
  const { name, phone, city, address1, stateAbbr, zipCode, workTimeSlots } = shopDetails || {};
  const timeSlots = workTimeSlots && prepareTimeSlots(workTimeSlots);
  const phoneNumber = formatPhoneNumberToUsLocale(phone);
  const refPhoneNumber = useRef(null);
  const [isShopDetailsOpen, setIsShopDetailsOpen] = useState(true);
  const { isLargePC } = useScreenSize();

  useEffect(() => {
    if (areShopDetailsLoaded && isCallRailSwap() && window.CallTrk && phone) {
      window.CallTrk.swap(refPhoneNumber.current);
    }
  }, [areShopDetailsLoaded]);

  const handleShowLocationPage = () => {
    const locationIndex = steps.findIndex(({ id }) => id === STEP_IDS.LOCATION);
    onChangeStep(locationIndex);
  };

  const handleToggle = () => {
    setIsShopDetailsOpen(!isShopDetailsOpen);
  };

  return (
    <div className={cn(styles.shopContacts)}>
      {shopDetails ? (
        <Collapse
          title="Shop Information and Help"
          onClick={handleToggle}
          isOpened={isShopDetailsOpen}
          isDefaultTheme={!isWidgetPopup}
          enableCollapse={!isLargePC}
        >
          <div className={cn(styles.shop)}>
            <div className={styles.leftSection}>
              {phone && (
                <div className={styles.box}>
                  <Typography fontWeight="bold" fontSize="lg">
                    Have a question?
                  </Typography>
                  <div className={styles.info}>Give us a call, we're here to help you.</div>
                  <div className={styles.phone}>
                    <PhoneIcon2 className={styles.iconPhone} width={20} height={20} />
                    <a
                      ref={refPhoneNumber}
                      href={`tel:${phoneNumber}`}
                      className={styles.phoneNumber}
                    >
                      {phoneNumber}
                    </a>
                  </div>
                </div>
              )}
              <div className={styles.box}>
                <div className={styles.header}>
                  <Typography fontWeight="bold" fontSize="lg">
                    Selected Location
                  </Typography>
                  {!isOneShop && (
                    <div className={styles.changeShopLink} onClick={handleShowLocationPage}>
                      Change
                    </div>
                  )}
                </div>
                <div className={styles.location}>
                  <div className={styles.shopName}>{name}</div>
                  <div>{address1}</div>
                  <div>
                    {" "}
                    {city}
                    {city ? "," : ""} {stateAbbr} {zipCode}
                  </div>
                </div>
              </div>
            </div>

            <div className={cn(styles.box, styles.resetBorder)}>
              <div className={styles.header}>
                <Typography fontWeight="bold" fontSize="lg">
                  Opening Hours
                </Typography>
              </div>
              {timeSlots && <WorkSchedule days={timeSlots} />}
            </div>
          </div>
        </Collapse>
      ) : (
        <Skeleton />
      )}
    </div>
  );
}
