import React, { useCallback, useEffect, useState } from "react";
import {
  AcHeatingIcon,
  BrakeIcon,
  CheckIcon,
  CommentIcon,
  EngineIcon,
  GeneralMaintenanceIcon,
  OilIcon,
  PenIcon,
  PlayVideoIcon,
  RepairCarIcon,
  SaveIcon,
  StateInspectionIcon,
  SteeringIcon,
  TireIcon,
} from "../../../../svg-icons";
import styles from "./ServiceItem.scss";
import cn from "classnames";
import { getIsClientTheme } from "../../../../services/config.service";
import Typography from "../../../../components/Typography/Typography";
import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../../../services/analytics.service";
import TextArea from "../../../../components/Inputs/TextArea/TextArea";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../../../constants";
import ElementWrapper from "../../../../templates/ElementWrapper/ElementWrapper";
import { useScreenSize } from "../../../../hooks/useScreenSize";

const ServiceItem = ({
  id,
  name,
  description,
  isSelected,
  onChange,
  onSelect,
  onDeselect,
  serviceComment,
  videoUrl,
}) => {
  const { windowWidth } = useScreenSize();
  const [isEditComment, setIsEditComment] = useState(false);

  function toggleService(id) {
    if (isSelected) {
      onDeselect(id);
    } else {
      onSelect(id);
    }
  }

  const getServiceIcon = (name) => {
    if (!name) {
      return;
    }
    const nameLoweCase = name.toLowerCase();
    if (nameLoweCase.includes("oil")) {
      return OilIcon;
    }
    if (nameLoweCase.includes("heating")) {
      return AcHeatingIcon;
    }
    if (nameLoweCase.includes("steering")) {
      return SteeringIcon;
    }
    if (nameLoweCase.includes("engine")) {
      return EngineIcon;
    }
    if (nameLoweCase.includes("brake")) {
      return BrakeIcon;
    }
    if (nameLoweCase.includes("tire")) {
      return TireIcon;
    }
    if (nameLoweCase.includes("general")) {
      return GeneralMaintenanceIcon;
    }
    if (nameLoweCase.includes("state")) {
      return StateInspectionIcon;
    }

    return RepairCarIcon;
  };
  const ItemIcon = getServiceIcon(name);

  const [showAboutShortLabel, setShowAboutShortLabel] = useState(true);

  const updateShowAboutShortLabel = useCallback(() => {
    const app = document.getElementById("mechanic-advisor-embed-v2-root");
    if (app) {
      const showAboutShortLabel =
        (app.offsetWidth < 1200 && app.offsetWidth >= 1024) || app.offsetWidth < 768;
      setShowAboutShortLabel(showAboutShortLabel);
    }
  }, []);

  useEffect(() => {
    updateShowAboutShortLabel();
    window.addEventListener("resize", updateShowAboutShortLabel);
    return () => {
      window.removeEventListener("resize", updateShowAboutShortLabel);
    };
  }, []);

  return (
    <div className={styles.serviceItemWrapper} onClick={() => !isEditComment && toggleService(id)}>
      <ElementWrapper
        isSelected={isSelected}
        className={cn(styles.serviceItem, {
          [styles["serviceItem--isClientTheme"]]: getIsClientTheme(),
          [styles.selectedService]: isSelected,
          [styles.isEditComment]: isEditComment,
          [styles["selectedService--isClientTheme"]]: isSelected && getIsClientTheme(),
        })}
      >
        <div className={styles.content}>
          {isSelected && (
            <div className={styles.checkIcon}>
              <CheckIcon />
            </div>
          )}
          {!isEditComment && (
            <>
              {ItemIcon && <ItemIcon />}
              <div className={cn(styles.nameAndComment, { [styles.withVideo]: videoUrl })}>
                <Typography fontWeight="bold" ellipsis>
                  {description}
                </Typography>
                <Typography className={styles.comment} ellipsis lineClamp={2} color="tertiary">
                  {serviceComment}
                </Typography>
                <div className={styles.cardButtons}>
                  {videoUrl && (
                      <Typography
                        ellipsis
                        fontWeight="bold"
                        showTooltip={false}
                        className={styles.aboutService}
                        onClick={(e) => {
                          e.stopPropagation();
                          registerAnalyticsEvent(CUSTOM_EVENTS.attachment_open);
                          window.open(videoUrl, "_blank").focus();
                        }}
                      >
                        <PlayVideoIcon />
                        {showAboutShortLabel ? "About" : "About the Service"}
                      </Typography>
                  )}
                  <Typography
                    ellipsis
                    fontWeight="bold"
                    showTooltip={false}
                    className={styles.addComment}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditComment(!isEditComment);
                    }}
                  >
                    {serviceComment ? (
                      <>
                        <PenIcon />
                        Edit Comment
                      </>
                    ) : (
                      <>
                        <CommentIcon />
                        Add Comment
                      </>
                    )}
                  </Typography>
                </div>
              </div>
            </>
          )}
          {isEditComment && (
            <>
              <TextArea
                characterCounterClassName={styles.characterCounterClass}
                value={serviceComment}
                name={name}
                placeholder={"Write your comment here..."}
                isCharacterCounter
                onChange={(value) => onChange({ id, value })}
                rows={windowWidth < 768 ? 3 : 2}
                className={styles.comment}
                maxCharactersCount={350}
              />
              <Typography
                fontWeight="bold"
                showTooltip={false}
                className={cn(styles.addComment, styles.saveButton)}
                onClick={() => {
                  setIsEditComment(!isEditComment);
                  if (!isSelected) {
                    toggleService(id);
                  }
                }}
              >
                <>
                  <SaveIcon />
                  Save
                </>
              </Typography>
            </>
          )}
        </div>
      </ElementWrapper>
    </div>
  );
};

ServiceItem.propTypes = {
  id: PropTypes.string,
  serviceComment: PropTypes.string,
  videoUrl: PropTypes.string,
  name: PROP_TYPES_TEMPLATES.LABEL,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default ServiceItem;
