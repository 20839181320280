import { useEffect } from "react";

import { transformToFormErrorsFormat } from "../utils";
import { scrollToElement } from "../utils/scrollUtils";

const useSetErrorsIntoForm = (
  { errors, setError, type = "server", isModal = false },
  isScrollToField = false,
) => {
  useEffect(() => {
    const formErrors = transformToFormErrorsFormat(errors, type);

    formErrors.forEach(({ name, type, message }) =>
      setError(name, {
        type,
        message,
      }),
    );

    const [error] = formErrors;
    if (isScrollToField && error) {
      scrollToElement(error.name, isModal ? "modal" : null);
    }
  }, [errors]);
};

export default useSetErrorsIntoForm;
