import ReactTooltip from "react-tooltip";
import cn from "classnames";
import styles from "./List.scss";
import { InfoIcon } from "../../../../../svg-icons";

const List = ({ values, servicesComments }) => {
  return values.map((service) => (
    <li key={service.title} className={cn({ [styles.resetListStyles]: values.length === 1 })}>
      {service.title}
      {servicesComments[service.serviceId] && (
        <span className={styles.icon}>
          <span data-tip data-for={`${service.title}`}>
            <InfoIcon className={styles.infoIcon} />
          </span>
          <ReactTooltip
            id={`${service.title}`}
            place="top"
            className={styles.tooltip}
            multiline={true}
            backgroundColor="#3E4150"
          >
            <p className={styles.tooltipText}>Comments:</p>
            <p className={styles.tooltipText}>{`"${servicesComments[service.serviceId]}"`}</p>
          </ReactTooltip>
        </span>
      )}
    </li>
  ));
};

export default List;
