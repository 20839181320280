// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioButton__radioButton--iqc1n{display:flex;align-items:center;gap:8px}.RadioButton__default--Ml5aA{width:20px;height:20px;border-radius:50%;background-color:#fff;border:1px solid #d9dfe8;box-sizing:border-box}.RadioButton__active--djlmI rect:nth-child(1){stroke:var(--main-brand-color)}.RadioButton__active--djlmI rect:nth-child(2){fill:var(--main-brand-color)}", "",{"version":3,"sources":["webpack://./src/components/RadioButton/RadioButton.scss"],"names":[],"mappings":"AAAA,iCACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,6BACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,qBAAA,CACA,wBAAA,CACA,qBAAA,CAKE,8CACE,8BAAA,CAEF,8CACE,4BAAA","sourcesContent":[".radioButton {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.default {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: #fff;\n  border: 1px solid #D9DFE8;\n  box-sizing: border-box;\n}\n\n.active {\n  rect {\n    &:nth-child(1) {\n      stroke: var(--main-brand-color)\n    }\n    &:nth-child(2) {\n      fill: var(--main-brand-color)\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButton": "RadioButton__radioButton--iqc1n",
	"default": "RadioButton__default--Ml5aA",
	"active": "RadioButton__active--djlmI"
};
export default ___CSS_LOADER_EXPORT___;
