import React from "react";

import { YellowAttentionIcon } from "../../../svg-icons";
import Tooltip from "../../Tooltip/Tooltip";

const InputError = ({ isOpen, errorMessage, className }) => (
  <div className={className}>
    {isOpen && (
      <Tooltip
        useHover
        isOpen={isOpen}
        closingTime={10000}
        content={<span style={{ whiteSpace: "nowrap" }}>{errorMessage}</span>}
      >
        <YellowAttentionIcon />
      </Tooltip>
    )}
  </div>
);

export default InputError;
