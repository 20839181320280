import styles from "./Spinner.scss";
import cn from "classnames";
import { getWidgetInPopup } from "../../services/config.service";

export default function Spinner() {
  const isWidgetPopup = getWidgetInPopup();

  return (
    <div
      className={cn(styles.SpinnerWrapper, {
        [styles.popupSpinnerWrapper]: isWidgetPopup,
      })}
    >
      <div className={styles.LoaderWrapper}>
        <div className={cn(styles.circle, styles.circleLight)}></div>
        <div className={cn(styles.circle, styles.circleMedium)}></div>
        <div className={cn(styles.circle, styles.circleMedium)}></div>
        <div className={cn(styles.circle, styles.circleDark)}></div>
      </div>
      <div className={styles.Text}>Loading...</div>
    </div>
  );
}
