import cn from "classnames";
import PropTypes from "prop-types";
import styles from "./Collapse.scss";
import { CircleArrowIcon } from "../../svg-icons";
import { getWidgetInPopup } from "../../services/config.service";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import ElementWrapper from "../../templates/ElementWrapper/ElementWrapper";

export default function Collapse({
  className,
  isDefaultTheme,
  title,
  isOpened,
  onClick,
  children,
  enableCollapse = true,
}) {
  const isWidgetPopup = getWidgetInPopup();

  return (
    <ElementWrapper
      hasHover={false}
      isDefaultTheme={isDefaultTheme}
      className={cn(styles.collapse, {
        [styles.collapsePopup]: isWidgetPopup,
      })}
    >
      {enableCollapse && (
        <div className={cn(styles.title, className, { [styles.titleExpanded]: isOpened })}>
          {title}
          <CircleArrowIcon
            onClick={onClick}
            className={cn(styles.arrowIcon, {
              [styles.arrowRotated]: !isOpened,
            })}
          />
        </div>
      )}

      <div className={cn(styles.content, { [styles.collapsed]: enableCollapse && !isOpened })}>
        {enableCollapse ? (isOpened ? children : null) : children}
      </div>
    </ElementWrapper>
  );
}

Collapse.propTypes = {
  title: PROP_TYPES_TEMPLATES.LABEL,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  isOpened: PropTypes.bool,
  isDefaultTheme: PropTypes.bool,
  onClick: PropTypes.func,
};
