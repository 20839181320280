// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Collapse__toggler--OvHNN{height:16px;display:flex;align-items:center;font-size:14px;cursor:pointer;color:var(--main-brand-color);font-weight:600;width:fit-content}.Collapse__toggler--OvHNN:hover{opacity:.8}.Collapse__content--56-6G{overflow:auto}.Collapse__icon--y1kCZ{height:25px;margin-left:7px}.Collapse__icon--y1kCZ path{fill:var(--main-brand-color);opacity:.9}.Collapse__arrow--fSLMP{transition:all .3s;min-width:22px;width:22px}.Collapse__arrowRotated--XvAnt{transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./src/scenes/LocationScene/components/ShopList/components/ShopItem/components/Collapse/Collapse.scss"],"names":[],"mappings":"AAAA,0BACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,cAAA,CACA,6BAAA,CACA,eAAA,CACA,iBAAA,CAEA,gCACE,UAAA,CAIJ,0BACE,aAAA,CAGF,uBACE,WAAA,CACA,eAAA,CACA,4BACE,4BAAA,CACA,UAAA,CAIJ,wBACE,kBAAA,CACA,cAAA,CACA,UAAA,CAGF,+BACE,wBAAA","sourcesContent":[".toggler {\n  height: 16px;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  cursor: pointer;\n  color: var(--main-brand-color);\n  font-weight: 600;\n  width: fit-content;\n\n  &:hover {\n    opacity: 0.8;\n  }\n}\n\n.content {\n  overflow: auto;\n}\n\n.icon{\n  height: 25px;\n  margin-left: 7px;\n  path{\n    fill: var(--main-brand-color);\n    opacity: 0.9;\n  }\n}\n\n.arrow {\n  transition: all 0.3s;\n  min-width: 22px;\n  width: 22px;\n}\n\n.arrowRotated {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggler": "Collapse__toggler--OvHNN",
	"content": "Collapse__content--56-6G",
	"icon": "Collapse__icon--y1kCZ",
	"arrow": "Collapse__arrow--fSLMP",
	"arrowRotated": "Collapse__arrowRotated--XvAnt"
};
export default ___CSS_LOADER_EXPORT___;
