// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Label-module__labelWrapper--VSijV{display:flex;align-items:center;gap:5px;margin-bottom:8px}.Label-module__label--rtoF0{font-size:14px;font-weight:600;color:#747a8b}.Label-module__label_disabled--dtW-v{color:#adb1c9}", "",{"version":3,"sources":["webpack://./src/components/Label/Label.module.scss"],"names":[],"mappings":"AAAA,mCACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CAGF,4BACE,cAAA,CACA,eAAA,CACA,aAAA,CAEA,qCACE,aAAA","sourcesContent":[".labelWrapper {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  margin-bottom: 8px;\n}\n\n.label {\n  font-size: 14px;\n  font-weight: 600;\n  color: #747a8b;\n\n  &_disabled {\n    color: #adb1c9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelWrapper": "Label-module__labelWrapper--VSijV",
	"label": "Label-module__label--rtoF0",
	"label_disabled": "Label-module__label_disabled--dtW-v"
};
export default ___CSS_LOADER_EXPORT___;
