import React, { useContext, useEffect } from "react";
import cn from "classnames";
import ReactPlaceholder from "react-placeholder";
import PropTypes from "prop-types";

import { getIsWidgetPublicPage, getWidgetInPopup } from "../../services/config.service";
import Typography from "../../components/Typography/Typography";
import { ServicesSkeleton } from "../../components/Skeletons/Skeletons";

import ServiceItem from "./components/ServiceItem/ServiceItem";
import {
  addCommentToService,
  addSelectedService,
  getIsLoadedServicesStatus,
  removeSelectedService,
  useRedux,
} from "../../redux";
import styles from "./ServicesScene.scss";
import { WizardContext } from "../Wizard/Wizard";

const ServicesScene = ({ store }) => {
  const { updateCurrentWizardStep } = useContext(WizardContext);
  const isWidgetPopup = getWidgetInPopup();
  const [state, dispatch] = useRedux(store);
  const isWidgetPublicPage = getIsWidgetPublicPage();

  const services = state.shopOptions.services;
  const selectedServices = state.appointmentDetails.services;
  const servicesComments = state.appointmentDetails.servicesComments;
  const areServicesLoaded = getIsLoadedServicesStatus(state);
  let selectedServicesLabel = state.shopOptions.services
    ?.filter((s) => state.appointmentDetails.services.includes(s.serviceId))
    .map((s) => s.title)
    .join(", ");
  selectedServicesLabel += selectedServicesLabel ? "." : "";

  useEffect(() => {
    updateCurrentWizardStep({
      footerSummary: selectedServicesLabel || "Please select services to continue.",
      isValid: !!selectedServicesLabel,
    });
  }, [selectedServicesLabel]);

  const handleSelect = (id) => dispatch(addSelectedService(id));

  const handleDeselect = (id) => {
    dispatch(removeSelectedService(id));
  };

  const handleChange = (commentForServices) => {
    dispatch(addCommentToService(commentForServices));
  };

  return (
    <div>
      <div className={cn(styles.container, { [styles.popupStyles]: isWidgetPopup })}>
        <ReactPlaceholder ready={areServicesLoaded} customPlaceholder={ServicesSkeleton()}>
          <div className={cn(styles.servicesSection, {
            [styles.servicesSectionHeight]: isWidgetPublicPage
          })}>
            <Typography fontWeight="bold" fontSize="xl">
              Select Your Services
            </Typography>
            <div className={styles.serviceList}>
              {services.map((service) => (
                <ServiceItem
                  id={service.serviceId}
                  key={service.serviceId}
                  name={service.title}
                  description={service.title}
                  videoUrl={service.attachment?.videoUrl}
                  isSelected={selectedServices.some((id) => id === service.serviceId)}
                  onDeselect={handleDeselect}
                  onSelect={handleSelect}
                  onChange={handleChange}
                  serviceComment={servicesComments[service.serviceId]}
                />
              ))}
            </div>
          </div>
        </ReactPlaceholder>
      </div>
    </div>
  );
};

ServicesScene.propTypes = {
  store: PropTypes.object,
};

export default ServicesScene;
