import { getFixedTopBarHeight } from "../services/config.service";
import { scroller } from "react-scroll";

export const scrollToElement = (elementName, containerId) => {
  scroller.scrollTo(elementName, {
    smooth: true,
    offset: -150,
    containerId,
  });
};

export const onScroll = () => {
  const scrollableElement = document.getElementById("wizard");

  if (scrollableElement) {
    const isBottom =
      scrollableElement.scrollHeight ===
      scrollableElement.scrollTop + scrollableElement.clientHeight;
    const isTop = scrollableElement.scrollTop === 0;

    if (isBottom || isTop) {
      scrollableElement.style.overflowY = "hidden";
    }
  }
};

export const scrollToTop = (behavior = "smooth") => {
  const scrollableElement = document.getElementById("wizard");

  if (scrollableElement) {
    const pageScroll = window.parent.scrollY;
    const frameOffsetTop = window.frameElement
      ? window.frameElement.getBoundingClientRect().top + pageScroll
      : 0;
    const fixedTopBarHeight = getFixedTopBarHeight();
    setTimeout(() => { // macrotask added and delay to allow container to fully initialise and then scroll it;
      scrollableElement.scrollTo({ top: 2, behavior });
    }, 50);
    if (fixedTopBarHeight) {
      const wizard = window.document.getElementById("wizardStepper");
      const wizardOffsetTop = wizard.offsetTop;
      const totalWizardOffset = frameOffsetTop + wizardOffsetTop - fixedTopBarHeight - 30;

      if (pageScroll > totalWizardOffset) {
        window.parent.scrollTo({ top: totalWizardOffset, behavior });
      }
    } else {
      if (pageScroll > frameOffsetTop) {
        window.parent.scrollTo({ top: frameOffsetTop, behavior });
      }
    }
  }
};

export const onScrollAreaTouchStart = (isEmbeddedIOS) => {
  const scrollableElement = document.getElementById("wizard");
  if (isEmbeddedIOS && scrollableElement) {
    const isBottom =
      scrollableElement.scrollHeight ===
      scrollableElement.scrollTop + scrollableElement.clientHeight;
    const isTop = scrollableElement.scrollTop === 0;
    if (isBottom) {
      scrollableElement.scrollTo({ top: scrollableElement.scrollTop - 1, behavior: "smooth" });
    }

    if (isTop) {
      scrollableElement.scrollTo({ top: scrollableElement.scrollTop + 1, behavior: "smooth" });
    }

    scrollableElement.style.overflowY = "auto";
  }
};
