import React from "react";
import cn from "classnames";
import { toast } from "react-toastify";

import { useScreenSize } from "../../hooks/useScreenSize";
import Typography from "../Typography/Typography";
import { TOAST_LABELS, TOAST_TYPES } from "../../constants";
import {
  CloseIcon,
  ErrorToastIcon,
  InfoToastIcon,
  SuccessToastIcon,
  WarningToastIcon,
} from "../../svg-icons";
import styles from "./Toast.scss";

export const ICONS = {
  [TOAST_TYPES.SUCCESS]: SuccessToastIcon,
  [TOAST_TYPES.ERROR]: ErrorToastIcon,
  [TOAST_TYPES.WARNING]: WarningToastIcon,
  [TOAST_TYPES.INFO]: InfoToastIcon,
};

const Toast = (props) => {
  const { style, type, message, header, closeToast, onClose } = props;
  const Icon = ICONS[type];
  const toastHeader = header || TOAST_LABELS[type];
  const { isMobile } = useScreenSize();

  return (
    <div
      style={style}
      className={cn(styles.Toast, "flex flex-col justify-center", {
        [styles.success]: type === TOAST_TYPES.SUCCESS,
        [styles.error]: type === TOAST_TYPES.ERROR,
        [styles.warning]: type === TOAST_TYPES.WARNING,
        [styles.info]: type === TOAST_TYPES.INFO,
      })}
    >
      {type && <Icon className={styles.icon} />}
      <div className={styles.toastContent}>
        <Typography className={styles.title} fontSize={"xl"} fontWeight={"bold"}>
          {toastHeader}
        </Typography>
        <Typography
          className={styles.message}
          fontSize={"sm"}
          fontWeight={isMobile ? "semibold" : "bold"}
        >
          {message}
        </Typography>
        <CloseIcon
          className={styles.close}
          onClick={() => {
            closeToast();
            onClose && onClose();
          }}
        />
      </div>
    </div>
  );
};

export { Toast };
export const showToast = ({ type, message, header, autoClose, toastId, onClose }) => {
  toast(<Toast type={type} message={message} header={header} onClose={onClose} />, {
    autoClose,
    toastId,
    draggable: false,
    delay: 300,
  });
};
