import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Typography from "../../../../components/Typography/Typography";
import styles from "./VerificationFlow.scss";
import { verificationSchema } from "../../../../formSchemas";
import SelectCard from "../../../../components/SelectCard/SelectCard";
import { VERIFICATION_METHOD_TYPES } from "../../../../constants";
import {
  getIsGuestFlow,
  saveCustomerDataToVerify,
  saveLookupCustomer,
  setGuestFlow,
  useRedux,
} from "../../../../redux";
import { WizardContext } from "../../../Wizard/Wizard";
import MatchingForm from "./components/MatchingForm/MatchingForm";
import { STEP_IDS } from "../../../Wizard/utils/stepsGenerator";
import { getMatchingOnly } from "../../../../services/config.service";

const VerificationFlow = ({ onNextSubStep, store, onLookupCustomer }) => {
  const { updateCurrentWizardStep, activeStep, updateWizardStepById } = useContext(WizardContext);
  const { showMatchFormOnly, nextButtonClickListener } = activeStep;
  const [state, dispatch] = useRedux(store);
  const isGuestFlow = getIsGuestFlow(state);
  const [verificationType, setVerificationType] = useState(VERIFICATION_METHOD_TYPES.PHONE);
  const isMatchingOnly = getMatchingOnly();

  const { control, formState, setError, clearErrors, resetField, trigger, getValues } = useForm({
    resolver: verificationSchema({ isGuestFlow, verificationType, isMatchingOnly }),
    defaultValues: { consentOptIn: false },
    mode: "onChange",
  });

  const formValues = getValues();
  const { isValid, isDirty } = formState;

  const handleMatch = async () => {
    await onLookupCustomer(formValues);
    dispatch(saveCustomerDataToVerify(formValues));
  };

  useEffect(() => {
    dispatch(saveLookupCustomer({})); // in case user come back, wipe data to avoid two source of truth
  }, []);

  useEffect(() => {
    if (nextButtonClickListener) {
      if (isGuestFlow) {
        onNextSubStep();
        return;
      }
      handleMatch();
    }
  }, [nextButtonClickListener]);

  useEffect(() => {
    if (isGuestFlow) {
      updateCurrentWizardStep({
        isValid: true,
        isNextStepBlocked: false,
        shouldBeIgnored: !isMatchingOnly,
      });
      return;
    }

    updateCurrentWizardStep({
      isValid: isDirty && isValid,
      shouldBeIgnored: false,
      isNextStepBlocked: true,
    });
  }, [isGuestFlow, isValid]);

  const handleProceedAsGuest = () => {
    updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
      shouldBeIgnored: true,
    });
    clearErrors();
    dispatch(setGuestFlow(true));
  };

  const handleProceedAsExisting = () => {
    dispatch(setGuestFlow(false));
  };

  const handleChangeVerificationType = (type) => {
    setVerificationType(type);
    resetField("phoneNumber");
    resetField("email");
  };

  return (
    <div className={styles.verificationFlow}>
      <SelectCard
        isSelectable={!showMatchFormOnly}
        isSelected={!isGuestFlow}
        onSelect={handleProceedAsExisting}
        className={styles.existingFlow}
      >
        <MatchingForm
          control={control}
          onChangeVerificationType={handleChangeVerificationType}
          verificationType={verificationType}
          store={store}
        />
      </SelectCard>
      {!showMatchFormOnly && (
        <SelectCard isSelected={isGuestFlow} onSelect={handleProceedAsGuest}>
          <Typography fontWeight="bold" fontSize="xl">
            Proceed as Guest
          </Typography>
          <Typography color="tertiary">
            Select this option if this is your first visit. We'll walk you through the process.
          </Typography>
        </SelectCard>
      )}
    </div>
  );
};

VerificationFlow.propTypes = {
  onNextSubStep: PropTypes.func,
  onLookupCustomer: PropTypes.func,
  store: PropTypes.object,
};

export default VerificationFlow;
