import { useCallback, useEffect, useState } from "react";

const useWizardPopupHeight = () => {
  const footerHeight = 76;
  const popupPaddings = 100;
  const modalHeight = 824;
  const [popupWizardHeight, setPopupWizardHeight] = useState(modalHeight);
  const headerElement = document.getElementById("popup-header");
  const headerHeight = headerElement?.offsetHeight;

  const handleUpdateWizardHeight = (windowHeight, windowWidth, headerHeight) => {
    if (windowWidth <= 767) {
      setPopupWizardHeight(windowHeight - footerHeight - headerHeight);
    } else {
      if (windowHeight <= 900) {
        setPopupWizardHeight(windowHeight - footerHeight - headerHeight - popupPaddings);
      } else {
        setPopupWizardHeight(modalHeight - footerHeight - headerHeight);
      }
    }
  };

  const handleResize = () => {
    handleUpdateWizardHeight(window.parent.innerHeight, window.parent.innerWidth, headerHeight);
  };

  useEffect(() => {
    window.parent.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [headerHeight]);

  useEffect(() => {
    handleResize();
  }, [headerHeight]);

  return popupWizardHeight;
};

export default useWizardPopupHeight;
