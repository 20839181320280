import styles from "./Summary.scss";
import cn from "classnames";
import { getWidgetInPopup, showAppointmentSummary } from "../../services/config.service";
import SectionSummary from "./SectionSummary/SectionSummary";
import Collapse from "../../components/Collapse/Collapse";
import { getShopDetailsValue, getSummary, isOneShopOnly, useRedux } from "../../redux";
import { useState } from "react";
import { scrollToTop } from "../../utils/scrollUtils";
import { useScreenSize } from "../../hooks/useScreenSize";
import { STEP_IDS } from "../Wizard/utils/stepsGenerator";

export default function Summary({ store, steps, onChangeStep }) {
  const isWidgetPopup = getWidgetInPopup();
  const [state] = useRedux(store);
  const summary = getSummary(state);
  const isOneShop = isOneShopOnly(state);
  const [isSummaryOpen, setIsSummaryOpen] = useState(true);
  const { isLargePC } = useScreenSize();
  const shopDetails = getShopDetailsValue(state);

  const { vehicle, service, dateTime, dropoffType, servicesComments, location } = summary;
  const { name } = shopDetails;

  const handleLocationChange = () => {
    const locationIndex = steps.findIndex(({ id }) => id === STEP_IDS.LOCATION);
    onChangeStep(locationIndex);
  };

  const handleDateTimeChange = async () => {
    const dateTimeStepIndex = steps.findIndex(({ id }) => id === STEP_IDS.DATE_TIME);
    await onChangeStep(dateTimeStepIndex);
    scrollToTop();
  };

  const handleServiceChange = async () => {
    const serviceStepIndex = steps.findIndex(({ id }) => id === STEP_IDS.SERVICE);
    await onChangeStep(serviceStepIndex);
    scrollToTop();
  };

  const handleToggle = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  return (
    <div>
      <Collapse
        title="Appointment Summary"
        onClick={handleToggle}
        isOpened={isSummaryOpen}
        isDefaultTheme={!isWidgetPopup}
        enableCollapse={!isLargePC}
      >
        <div className={cn(styles.summary)}>
          <div className={styles.summaryList}>
            <div className={styles.wrapper}>
              <SectionSummary section="Location" value={name} />
              {!isOneShop && (
                <div className={styles.changeBtn} onClick={handleLocationChange}>
                  Change
                </div>
              )}
            </div>
            <div className={styles.wrapper}>
              {showAppointmentSummary() && (
                <SectionSummary section="Type of Appointment" value={dropoffType} />
              )}
              <SectionSummary section="Date and Time" value={dateTime} />
              <div className={styles.changeBtn} onClick={handleDateTimeChange}>
                Change
              </div>
            </div>
            <div className={styles.wrapper}>
              {vehicle && <SectionSummary section="Vehicle" value={vehicle} />}
              <SectionSummary
                section="Service(s)"
                value={service}
                servicesComments={servicesComments}
              />
              <div className={styles.changeBtn} onClick={handleServiceChange}>
                Change
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
