import { getJsonAttribute } from "../utils/index";

const DEFAULT_COLOR = "#2E3131";
export const DEFAULT_BACKGROUND_COLOR = "#ffffff";
const TRANSPARENT_BACKGROUND_BORDER_CARD_COLOR = "#F6F7F9";
const DEFAULT_WIDGET_MAX_HEIGHT = 1300;

let config = {
  shops: [],
};
if (document.body) {
  init();
} else {
  window.addEventListener("load", init);
}

function getScriptTag() {
  const script =
    document.getElementById("mechanic-advisor-embed-v2") ||
    document.getElementById("mechanic-advisor-modal-v1");
  if (script) {
    return script;
  }
}

function init() {
  const scriptTag = getScriptTag();
  config.token = scriptTag.getAttribute("token") || "";
  config.ctoken =
    scriptTag.getAttribute("ctoken") !== "null" ? scriptTag.getAttribute("ctoken") : "";
  config.shopId = scriptTag.getAttribute("shopId") || "";
  config.source = scriptTag.getAttribute("origin") || "";
  config.rwgToken = scriptTag.getAttribute("rwgToken") || "";
  config.isCallRailSwap = scriptTag.getAttribute("useCallRailSwap") || false;
  config.showHeader = scriptTag.getAttribute("showHeader") || false;
  config.isWidgetPublicPage = scriptTag.getAttribute("isWidgetPublicPage") || false;
  config.isWidgetInPopup = getJsonAttribute(scriptTag, "popup-widget", false);
  config.isClosePopupOnEsc = getJsonAttribute(scriptTag, "close-on-esc", true);
  config.isTenantWidget = getJsonAttribute(scriptTag, "isTenantWidget", false);
  config.isMatchingOnly = getJsonAttribute(scriptTag, "customerLookupOnly", false);
  config.backgroundColor = scriptTag.getAttribute("backgroundColor") || DEFAULT_BACKGROUND_COLOR;
  config.brandColor = scriptTag.getAttribute("brandColor") || DEFAULT_COLOR;
  config.isPreview = getJsonAttribute(scriptTag, "isPreview", false);
  config.fetchSettingsFromPublicApi = getJsonAttribute(
    scriptTag,
    "fetchSettingsFromPublicApi",
    false,
  );
}

function initClusterId(clusterId) {
  config.clusterId = clusterId;
}

function initSettings(widgetInfo) {
  if (!config.fetchSettingsFromPublicApi) {
    config.shops = widgetInfo.shopIds;
    config.tenantId = widgetInfo.tenantId;
  }

  const { settings } = widgetInfo;
  //TODO: remove, temporary for Plaza Tire
  config.isPlazaTire = settings.isPlazaTire ? JSON.parse(settings.isPlazaTire) : false;

  config.brandColor = settings.brandColor || DEFAULT_COLOR;
  config.backgroundColor = settings.backgroundColor || DEFAULT_BACKGROUND_COLOR;
  config.widgetMaxHeight = settings.widgetMaxHeight || DEFAULT_WIDGET_MAX_HEIGHT;
  config.disclaimerText = settings.disclaimerText;
  config.successPageText = settings.successPageText;

  if (config.backgroundColor === "transparent") {
    config.cardBorder = TRANSPARENT_BACKGROUND_BORDER_CARD_COLOR;
  } else {
    config.cardBorder = `transparent`;
  }
  config.redirectUrl = settings.redirectUrl;
  config.calendar = settings.calendar;

  config.fixedTopBarHeight = settings.fixedTopBarHeight;

  config.showOpeningHours = !!settings.showOpeningHours;
  config.showGetDirectionsButton = !!settings.showGetDirectionsButton;
  config.showOverallShopRating = !!settings.showOverallShopRating;
  config.showShopInfo = !!settings.showShopInfo;
  config.enableTwoFactorAuthentication =
    settings.enableTwoFactorAuthentication !== undefined
      ? !!settings.enableTwoFactorAuthentication
      : false;
  config.allowLoanerVehicle = !!settings.allowLoanerVehicle;
  config.showCouponCode = !!settings.showCouponCode;
  config.showAppointmentSummary = !!settings.showSummary;
  config.isClientTheme = true;
  config.showAddToCalendar =
    settings.showAddToCalendar !== undefined ? settings.showAddToCalendar : true;

  config.tabs = {
    scheduler: settings.showScheduler,
    reviews: settings.showReviews,
  };

  config.checkboxes = settings.checkboxes || [];
  config.locations = settings.locations || [];
  config.termsAndConditionsUrl = settings.termsAndConditionsURL || "";
  config.settingsInitialised = true;
}

function initSettingsFromScriptOrDefault() {
  const scriptTag = getScriptTag();
  const url = new URL(document.location.href);
  const shopId = url.searchParams.get("shop");
  if (shopId) {
    config.shops.push(shopId);
  } else {
    config.shops = (scriptTag.getAttribute("shop") || "").split(",");
  }

  //TODO: remove, temporary for Plaza Tire
  config.isPlazaTire = getJsonAttribute(scriptTag, "isPlazaTire", false);
  config.clusterId = scriptTag.getAttribute("clusterId");
  config.tenantId = scriptTag.getAttribute("tenantId");
  config.campaignId = scriptTag.getAttribute("campaignId");
  config.marketingTypeId = scriptTag.getAttribute("marketingTypeId");
  config.isPreview = getJsonAttribute(scriptTag, "isPreview", false);
  config.isTenantWidget = getJsonAttribute(scriptTag, "isTenantWidget", false);
  config.fetchSettingsFromPublicApi = getJsonAttribute(
    scriptTag,
    "fetchSettingsFromPublicApi",
    false,
  );
  config.brandColor = scriptTag.getAttribute("brandColor") || DEFAULT_COLOR;
  config.backgroundColor = scriptTag.getAttribute("backgroundColor") || DEFAULT_BACKGROUND_COLOR;
  config.widgetMaxHeight = scriptTag.getAttribute("widgetMaxHeight") || DEFAULT_WIDGET_MAX_HEIGHT;
  config.disclaimerText = scriptTag.getAttribute("disclaimerText");
  config.successPageText = scriptTag.getAttribute("successPageText");

  if (config.backgroundColor === "transparent") {
    config.cardBorder = TRANSPARENT_BACKGROUND_BORDER_CARD_COLOR;
  } else {
    config.cardBorder = `transparent`;
  }
  config.redirectUrl = scriptTag.getAttribute("redirectUrl");
  config.calendar = scriptTag.getAttribute("calendar");

  config.fixedTopBarHeight = scriptTag.getAttribute("fixedTopBarHeight");

  config.showOpeningHours = getJsonAttribute(scriptTag, "showOpeningHours", false);
  config.showOverallShopRating = getJsonAttribute(scriptTag, "showOverallShopRating", false);
  config.showGetDirectionsButton = getJsonAttribute(scriptTag, "showGetDirectionsButton", false);
  config.showShopInfo = getJsonAttribute(scriptTag, "showShopInfo", false);
  config.enableTwoFactorAuthentication = getJsonAttribute(
    scriptTag,
    "enableTwoFactorAuthentication",
    false,
  );
  config.allowLoanerVehicle = getJsonAttribute(scriptTag, "allowLoanerVehicle", false);
  config.showCouponCode = getJsonAttribute(scriptTag, "showCouponCode", false);
  config.showAppointmentSummary = getJsonAttribute(scriptTag, "showSummary", false);
  config.isClientTheme = getJsonAttribute(scriptTag, "isClientTheme", false);
  config.showAddToCalendar = getJsonAttribute(scriptTag, "showAddToCalendar", true);

  config.tabs = {
    scheduler: getJsonAttribute(scriptTag, "showScheduler", false),
    reviews: getJsonAttribute(scriptTag, "showReviews", false),
  };

  config.checkboxes = getJsonAttribute(scriptTag, "checkboxes", []);
  config.locations = getJsonAttribute(scriptTag, "locations", []);
  config.termsAndConditionsUrl = scriptTag.getAttribute("termsAndConditionsURL") || "";

  config.isCallRailSwap = getJsonAttribute(scriptTag, "useCallRailSwap", false);
  config.settingsInitialised = true;
}

function getToken() {
  return config.token;
}

function getCToken() {
  return config.ctoken;
}

function getIsTenantWidget() {
  return config.isTenantWidget;
}

function getClusterId() {
  return config.clusterId;
}

function getShops() {
  return config.shops;
}

function getEnabledTwoFactorAuthentication() {
  return config.enableTwoFactorAuthentication;
}

function setEnabledTwoFactorAuthentication(value) {
  config.enableTwoFactorAuthentication = value;
}

function getTenantId() {
  return config.tenantId;
}

function getMarketingTypeId() {
  return config.marketingTypeId;
}

function getCampaignId() {
  return config.campaignId;
}

function getIsPlazaTire() {
  return config.isPlazaTire;
}

function getBrandColor() {
  return config.brandColor;
}

function getBackgroudColor() {
  return config.backgroundColor;
}

function getDisclaimerText() {
  return config.disclaimerText;
}

function getSuccessPageText() {
  return config.successPageText;
}

function getBorderColorForTransparentBackground() {
  return config.cardBorder;
}

function getRedirectUrl() {
  return config.redirectUrl;
}

function getIsClientTheme() {
  return config.isClientTheme;
}

function getFixedTopBarHeight() {
  return config.fixedTopBarHeight;
}

function showShopInfo() {
  return config.showShopInfo;
}

function getIsLoanerVehicleAllow() {
  return config.allowLoanerVehicle;
}

function showCouponCode() {
  return config.showCouponCode;
}
function getMatchingOnly() {
  return config.isMatchingOnly;
}

function showAppointmentSummary() {
  return config.showAppointmentSummary;
}

function showAddToCalendar() {
  return config.showAddToCalendar;
}

function showOpeningHours() {
  return config.showOpeningHours;
}

function showGetDirectionsButton() {
  return config.showGetDirectionsButton;
}

function showOverallShopRating() {
  return config.showOverallShopRating;
}

function isCallRailSwap() {
  return config.isCallRailSwap;
}

function getIsPreview() {
  return config.isPreview;
}

function getShowHeader() {
  return config.showHeader;
}

function getIsWidgetPublicPage() {
  return config.isWidgetPublicPage;
}

function getFetchSettingsFromPublicApi() {
  return config.fetchSettingsFromPublicApi;
}

function getSource() {
  return config.source;
}

function getRwgToken() {
  return config.rwgToken;
}

function getWidgetInPopup() {
  return config.isWidgetInPopup;
}

function getClosePopupOnEsc() {
  return config.isClosePopupOnEsc;
}
function getSettingsInitialised() {
  return config.settingsInitialised;
}

export {
  getShops,
  getBrandColor,
  getBackgroudColor,
  getDisclaimerText,
  getSuccessPageText,
  getBorderColorForTransparentBackground,
  getRedirectUrl,
  getIsClientTheme,
  getFixedTopBarHeight,
  showShopInfo,
  getIsLoanerVehicleAllow,
  showCouponCode,
  showAppointmentSummary,
  showAddToCalendar,
  initSettings,
  getClusterId,
  getToken,
  getCToken,
  getIsTenantWidget,
  initClusterId,
  initSettingsFromScriptOrDefault,
  getIsPlazaTire,
  isCallRailSwap,
  showOpeningHours,
  showOverallShopRating,
  showGetDirectionsButton,
  getTenantId,
  getIsPreview,
  getShowHeader,
  getIsWidgetPublicPage,
  getFetchSettingsFromPublicApi,
  getSource,
  getRwgToken,
  getWidgetInPopup,
  getClosePopupOnEsc,
  getEnabledTwoFactorAuthentication,
  getMatchingOnly,
  getSettingsInitialised,
  setEnabledTwoFactorAuthentication,
  getMarketingTypeId,
  getCampaignId,
};
