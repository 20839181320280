import React from "react";
import cn from "classnames";
import { ArrowDownIcon } from "../../../../../../../../svg-icons";

import styles from "./Collapse.scss";
import PropTypes from "prop-types";
import { PROP_TYPES_TEMPLATES } from "../../../../../../../../constants";

const Collapse = ({
  isOpened,
  disabled,
  content,
  children,
  onCollapse,
  className,
  collapseArrowClassName,
  collapseArrowOpenedClassName,
  contentContainerClassName,
}) => (
  <div className={className}>
    <div
      className={cn(styles.toggler)}
      onClick={(e) => {
        if (!disabled) {
          onCollapse(e);
        }
      }}
    >
      <div className={cn(contentContainerClassName, { [styles.disabled]: disabled })}>
        {content}
      </div>
      <div className={styles.icon}>
        <ArrowDownIcon
          width="20"
          height="24"
          className={cn(styles.arrow, collapseArrowClassName, {
            [styles.arrowRotated]: isOpened,
            [collapseArrowOpenedClassName]: isOpened,
          })}
        />
      </div>
    </div>
    <div
      className={cn({ [styles.content]: !isOpened })}
      style={{
        maxHeight: isOpened ? "100%" : 0,
      }}
    >
      {children}
    </div>
  </div>
);

Collapse.propTypes = {
  onCollapse: PropTypes.func,
  isOpened: PropTypes.bool,
  content: PROP_TYPES_TEMPLATES.CHILDREN,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  collapseArrowClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  collapseArrowOpenedClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  contentContainerClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default Collapse;
