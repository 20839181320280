export const cityStateStyles = {
  fontSize: "18px",
  fontWeight: "bold",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const addressStyles = {
  fontSize: "14px",
  color: "#747A8B",
  lineHeight: "24px",
  display: "-webkit-box",
  webkitLineClamp: "2",
  webkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const shopNameStyles = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};
