const scriptId = "pendo-script";
export const initPendo = () => {
  const isDev = process.env.NODE_ENV === "development";
  const isScriptInstalled = document.getElementById(scriptId);
  if (!isScriptInstalled && !isDev) {
    try {
      const scriptElm = document.createElement("script");
      scriptElm.setAttribute("type", "text/javascript");
      scriptElm.setAttribute("id", scriptId);
      scriptElm.innerHTML = `(function (apiKey) {
            (function (p, e, n, d, o) {
                var v, w, x, y, z;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
                for (w = 0, x = v.length; w < x; ++w) (function (m) {
                    o[m] = o[m] || function () {
                        o._qm === v[0] ? 'unshift' : 'push';
                    };
                })(v[w]);
                y = e.createElement(n);
                y.async = !0;
                y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo');
        })('2f247a4e-051d-490d-55aa-8412466e7ddb');`;
      document.head.appendChild(scriptElm);
      if (window.pendo) {
        try {
          window.pendo.initialize({ visitor: {} });
          console.log("Pendo anon tracking script initialized");
        } catch (e) {
          console.error(e);
        }
      }
      return true;
    } catch (e) {
      console.error(e);
    }
  }

  return false;
};
