import { API_ROOT as apiRoot } from "./env.service";
import { getClusterId } from "./config.service";

export async function sendRequest({ url, method = "get", body, headers, rootUrl = apiRoot }) {
  const requestHeader = {
    ...headers,
    "x-cluster-id": getClusterId(),
    "content-type": "application/json",
  };
  let result;

  try {
    result = await fetch(rootUrl + url, { method, body, headers: requestHeader });
  } catch (e) {
    console.error(`Request ${url} failed`);
    throw e;
  }
  return result;
}
