import {
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  getPhoneCode,
  parsePhoneNumber,
} from "libphonenumber-js";

export function generateRawCountries(countiesList) {
  return Object.entries(countiesList).map(([iso2, name]) => ({
    name,
    iso2,
    countryCode: getCountryCallingCode(iso2),
  }));
}

export function parseNumberData(phoneNumber) {
  if (phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

    return {
      country: parsedPhoneNumber.country,
      phoneNumber: parsedPhoneNumber.format("NATIONAL"),
    };
  }

  return {
    country: null,
    phoneNumber: "",
  };
}

export const formatPhoneNumberToNational = (value, selectedCountry) => {
  const startFormatting = /(.?\d){4,}/.test(value);
  return startFormatting ? formatIncompletePhoneNumber(value, selectedCountry) : value;
};

export const getPhoneWithCountryCode = (phone, selectedCountry) => {
  if (phone && phone[0] !== "+") {
    return `+${getPhoneCode(selectedCountry)}${phone}`;
  }
  return phone;
};

export const keepCursor = (element, newValue) => {
  const isCursorAtTheEnd = element.value.length === element.selectionStart;
  const cursor = isCursorAtTheEnd ? newValue.length : element.selectionStart;

  window.requestAnimationFrame(() => {
    element.selectionStart = cursor;
    element.selectionEnd = cursor;
  });
};
