import React, { useContext, useEffect } from "react";
import VerificationScene from "../VerificationScene/VerificationScene";
import { STEP_IDS, VERIFICATION_SUB_STEP_IDS } from "../Wizard/utils/stepsGenerator";
import {
  getIsGuestFlow,
  getSelectedCustomer,
  getShopId,
  getTenantId,
  hideContactConsent,
  saveMatchedCustomers,
  saveLookupCustomer,
  setGuestFlow,
  useRedux,
} from "../../redux";
import { WizardContext } from "../Wizard/Wizard";
import { CUSTOM_EVENTS, registerAnalyticsEvent } from "../../services/analytics.service";
import useAsync from "../../hooks/useAsync";
import {
  submitAppointment,
  submitFailedCallback,
  submitSuccessCallback,
} from "../Wizard/utils/utils";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import {
  getIsLoanerVehicleAllow,
  getIsPlazaTire,
  getIsPreview,
  getMatchingOnly,
  getRwgToken,
} from "../../services/config.service";
import { AppointmentType } from "../../constants";
import useLookupCustomer from "../../hooks/useLookupCustomer";

const MainVerificationStep = ({ store }) => {
  const [state, dispatch] = useRedux(store);
  const isGuestFlow = getIsGuestFlow(state);
  const selectedCustomer = getSelectedCustomer(state);
  const shopId = getShopId(state);
  const tenantId = getTenantId(state);
  const isPreview = getIsPreview();
  const isPlazaTire = getIsPlazaTire();
  const rwgToken = getRwgToken();
  const isMatchingOnly = getMatchingOnly();

  const appointmentType = state.appointmentDetails.appointmentType;
  const isLoanerVehicleAllowed =
    getIsLoanerVehicleAllow() && appointmentType === AppointmentType.Dropoff && !isPlazaTire;
  const { activeStep, updateCurrentWizardStep, back, next, updateWizardStepById, initialSteps } =
    useContext(WizardContext);
  const {
    activeSubStepIndex = 0,
    startSubStepIndex = 0,
    backButtonClickListener,
    isMatchingOnlyLastStep,
  } = activeStep;
  const activeStepInitial = initialSteps.find(({ id }) => id === activeStep.id);
  const showBackButtonInitial = activeStepInitial?.showBackButton;

  // Reset isGuestFlow since user can navigate back to lookup form (San Auto hack)
  useEffect(() => {
    if (isMatchingOnly) {
      dispatch(setGuestFlow(false));
    }
  }, []);

  useEffect(() => {
    if (backButtonClickListener) {
      handlePreviousSubStep();
    }
  }, [backButtonClickListener]);

  const handleNextSubStep = () => {
    updateCurrentWizardStep({
      isValid: false,
      nextButtonClickListener: null,
      activeSubStepIndex: activeSubStepIndex + 1,
    });
  };

  const handlePreviousSubStep = () => {
    const isPreviousPage = activeSubStepIndex === startSubStepIndex;
    updateCurrentWizardStep({
      backButtonClickListener: null,
      isLoading: false,
      activeSubStepIndex: isPreviousPage ? startSubStepIndex : activeSubStepIndex - 1,
    });
    if (isPreviousPage) {
      back();
    }
    if (activeSubStepIndex === 1) {
      updateCurrentWizardStep({
        showBackButton: showBackButtonInitial,
      });
    }
  };

  const { onLookupCustomer, isLoading } = useLookupCustomer({
    tenantId,
    shopId,
    noCustomersCb: (formValues) => {
      const { consentOptIn } = formValues;
      updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
        shouldBeIgnored: true,
      });

      updateWizardStepById(STEP_IDS.VERIFICATION, {
        shouldBeIgnored: true,
      });

      dispatch(setGuestFlow(true));
      // Show/hide consent checkbox on last contact step if consent checkbox was clicked on 2fa step
      if (consentOptIn) {
        dispatch(hideContactConsent());
      }
      next();
    },

    oneCustomerCb: (customersArray) => {
      dispatch(saveLookupCustomer(customersArray[0]));
      if (isMatchingOnly) {
        updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
          shouldBeIgnored: false,
        });
        next();
        return;
      }
      updateCurrentWizardStep({
        showBackButton: true,
        subSteps: [
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_FLOW,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE,
        ],
      });
      handleNextSubStep();
    },
    multipleCustomersCb: (customersArray) => {
      dispatch(saveMatchedCustomers(customersArray));
      if (isMatchingOnly) {
        updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
          shouldBeIgnored: false,
        });
      }
      updateCurrentWizardStep({
        showBackButton: true,
        subSteps: [
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_FLOW,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_PROFILE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_TYPE,
          VERIFICATION_SUB_STEP_IDS.VERIFICATION_CODE,
        ],
      });
      handleNextSubStep();
    },
  });

  useEffect(() => {
    updateCurrentWizardStep({ isLoading });
  }, [isLoading]);

  const handleVerificationSuccess = async () => {
    registerAnalyticsEvent(CUSTOM_EVENTS.verification_passed);
    if (isGuestFlow) {
      closeToast();
      await submitHandler();
      return;
    }
    if (!isGuestFlow) {
      updateWizardStepById(STEP_IDS.CONTACT, {
        isValid: true,
      });
      updateCurrentWizardStep({
        shouldBeIgnored: true,
      });
      next();
    }
  };

  const handleProfileNext = async () => {
    if (isMatchingOnlyLastStep) {
      await submitHandler();
    }
    if (isMatchingOnly) {
      next();
      return;
    }
    handleNextSubStep();
  };

  const {
    execute: submitHandler,
    errors,
    errorStatusCode,
  } = useAsync({
    asyncFunction: () => submitAppointment({ isPreview, isLoanerVehicleAllowed, dispatch }),
    successFunction: () => {
      submitSuccessCallback({ dispatch, contactInfo: selectedCustomer, rwgToken });
    },
    failedFunction: (error) => {
      submitFailedCallback({ error, dispatch });
    },
  });

  const { closeToast } = useErrorHandler({
    isScrollToField: false,
    isModal: false,
    errors,
    errorStatusCode,
  });

  return (
    <div>
      <VerificationScene
        store={store}
        onLookupCustomer={onLookupCustomer}
        onVerificationSuccess={handleVerificationSuccess}
        onNextSubStep={handleNextSubStep}
        onPreviousSubStep={handlePreviousSubStep}
        onProfileNext={handleProfileNext}
      />
    </div>
  );
};

export default MainVerificationStep;
