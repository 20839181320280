import { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { scrollToTop } from "../utils/scrollUtils";
import { PROP_TYPES_TEMPLATES } from "../constants";

const firstStep = 0;
export const useMultistepForm = ({ initialSteps }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(firstStep);
  const historyRef = useRef([firstStep]);
  const [steps, setSteps] = useState(initialSteps);
  const activeStep = steps[activeStepIndex];
  const ActiveStepComponent = steps[activeStepIndex].component;

  const next = useCallback(() => {
    scrollToTop();

    setSteps((prevState) => {
      const nextPossibleIndex = prevState.findIndex(
        ({ shouldBeIgnored }, index) => index > activeStepIndex && !shouldBeIgnored,
      );
      historyRef.current.push(nextPossibleIndex);
      setActiveStepIndex(nextPossibleIndex);
      updateCurrentWizardStep({
        backButtonClickListener: null,
        nextButtonClickListener: null,
      });
      return prevState;
    });
  }, [activeStep, activeStepIndex, steps]);

  const back = useCallback(() => {
    scrollToTop();

    updateCurrentWizardStep({
      backButtonClickListener: null,
      nextButtonClickListener: null,
    });
    const prevPossibleIndex = steps.findLastIndex(
      ({ shouldBeIgnored }, index) => index < activeStepIndex && !shouldBeIgnored,
    );

    setActiveStepIndex(prevPossibleIndex);
  }, [steps]);

  const changeStep = useCallback(
    (index) => {
      scrollToTop();
      setActiveStepIndex(index);
    },
    [steps],
  );

  const updateWizardStepById = useCallback(
    (stepId, data) => {
      setSteps((prevState) => {
        const stepIndex = prevState.findIndex(({ id }) => stepId === id);
        const stepsCopy = [...prevState];
        stepsCopy[stepIndex] = { ...stepsCopy[stepIndex], ...data };
        return stepsCopy;
      });
    },
    [steps, activeStepIndex, activeStep],
  );

  const updateCurrentWizardStep = useCallback(
    (data) => {
      setSteps((prevState) => {
        const stepsCopy = [...prevState];
        stepsCopy[activeStepIndex] = { ...stepsCopy[activeStepIndex], ...data };
        return stepsCopy;
      });
    },
    [steps, activeStepIndex, activeStep],
  );

  return {
    ActiveStepComponent,
    activeStep,
    activeStepIndex,
    next,
    back,
    updateCurrentWizardStep,
    changeStep,
    steps,
    updateWizardStepById,
  };
};

const stepSchema = {
  id: PropTypes.string, // id for interaction with the step
  component: PROP_TYPES_TEMPLATES.COMPONENT, // Component that should be shown when step is active
  isCustomHeader: PropTypes.bool, // Indicates if general header should be replaced with step local one
  header: PROP_TYPES_TEMPLATES.COMPONENT, // Custom step header component
  showBackButton: PropTypes.bool, // Indicates if back button should be shown
  showShopInfo: PropTypes.bool, // Indicates if shop info section should be shown for current step
  showSummary: PropTypes.bool, // Indicates if summary section should be shown for current step
  footerSummary: PropTypes.string,
  nextButtonLabel: PropTypes.bool,
  backButtonLabel: PropTypes.bool,
  // --- Start of Navigation props ---
  isValid: PropTypes.bool, // Indicates if next button can be active
  isNextStepBlocked: PropTypes.bool, // Indicates if user can navigate to the next step. Used for inner step navigation
  isPreviousStepBlocked: PropTypes.bool, // Indicates if user can navigate to the previous step. Used for inner step navigation
  shouldBeIgnored: PropTypes.bool, // Indicates if user can navigate to this step using next/back buttons
  backButtonClickListener: PropTypes.string, // Listener to navigate inside step or execute inner logic
  nextButtonClickListener: PropTypes.string, // Listener to navigate inside step or execute inner logic
  // --- End of Navigation props ---
};

useMultistepForm.propTypes = {
  initialSteps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape(stepSchema))),
};
