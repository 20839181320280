import React from "react";
import cn from "classnames";

import styles from "./ErrorPage.scss";
import ClosePopupButton from "../../components/ClosePopupButton/ClosePopupButton";
import { getWidgetInPopup } from "../../services/config.service";
import { STEP_IDS } from "../Wizard/utils/stepsGenerator";

const ErrorPage = ({ onChangeStep, steps }) => {
  const isWidgetPopup = getWidgetInPopup();

  const handleShowContactStep = () => {
    const locationIndex = steps.findIndex(({ id }) => id === STEP_IDS.CONTACT);
    onChangeStep(locationIndex);
  };

  return (
    <div className={cn(styles.Page, { [styles.popupStyles]: isWidgetPopup })}>
      {isWidgetPopup && (
        <div className={styles.closePopupIconWrapper}>
          <ClosePopupButton />
        </div>
      )}
      <div className={cn(styles.Page__content, { [styles.contentPopupStyles]: isWidgetPopup })}>
        <div className={cn(styles.Page__subtitle)}>Something went wrong...</div>
        <div className={styles.Page__description}>
          We are checking the possible failures.
          <br />
          Please try again or contact support.
        </div>
        <div className={styles.actionButtons}>
          <button onClick={handleShowContactStep}>Try Again</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
