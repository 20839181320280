// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectProfile__list--dApbv{margin-top:24px;display:flex;align-items:center;gap:16px}.SelectProfile__card--AURt3{max-width:350px;width:100%;display:flex;flex-direction:column;gap:8px}.SelectProfile__infoItem---D12E{display:flex;align-items:center;gap:8px;border-bottom:1px solid #d9dfe8;padding-bottom:8px}.SelectProfile__infoItem---D12E:last-child{border-bottom:none;padding-bottom:0}", "",{"version":3,"sources":["webpack://./src/scenes/VerificationScene/components/SelectProfile/SelectProfile.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,4BACE,eAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,gCACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,+BAAA,CACA,kBAAA,CAEA,2CACE,kBAAA,CACA,gBAAA","sourcesContent":[".list {\n  margin-top: 24px;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.card {\n  max-width: 350px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.infoItem {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  border-bottom: 1px solid #D9DFE8;\n  padding-bottom: 8px;\n\n  &:last-child {\n    border-bottom: none;\n    padding-bottom: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "SelectProfile__list--dApbv",
	"card": "SelectProfile__card--AURt3",
	"infoItem": "SelectProfile__infoItem---D12E"
};
export default ___CSS_LOADER_EXPORT___;
