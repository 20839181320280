import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { TextButton } from "../../components/TextButton/TextButton";
import { OpenLinkIcon } from "../../svg-icons";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import TextArea from "../../components/Inputs/TextArea/TextArea";
import PhoneInput from "../../components/Inputs/PhoneInput/PhoneInput";
import Collapse from "../../components/Collapse/Collapse";
import Spinner from "../../components/Spinner/Spinner";
import Loader from "../../components/Loader";
import Checkbox from "../../components/CheckBox/CheckBox";
import ClosePopupButton from "../../components/ClosePopupButton/ClosePopupButton";
import CompanyAvatar from "../CompanyAvatar/CompanyAvatar";
import Typography from "../../components/Typography/Typography";
import Label from "../../components/Label/Label";
import { useRedux } from "../../redux";
import Select from "../../components/Select/Select";
import styles from "./ComponentsRepresentation.scss";

const ComponentsRepresentation = ({ store }) => {
  const [inputValue, setInputValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [isOpenedToggle, onOpenedToggle] = useState(true);
  const [isChecked, onCheck] = useState(true);
  const [selected, setSelected] = useState(null);
  const [state, dispatch] = useRedux(store);
  const { vehicle } = state.shopOptions;
  const sortedYears = vehicle.years.slice().sort((a, b) => b.value - a.value);

  return (
    <div>
      <div className={styles.row}>
        <CompanyAvatar />
        <CompanyAvatar hasBorder={true} />
      </div>
      <div className={styles.row}>
        <Button title={"Button"} onClick={() => {}} isDisabled={false} />
        <Button title={"Button"} onClick={() => {}} isDisabled={true} />
        <Button title={"Button"} onClick={() => {}} isLoading={true} />
        <TextButton startIcon={<OpenLinkIcon />} onClick={() => {}}>
          Text link button
        </TextButton>
        <ClosePopupButton />
      </div>
      <div className={styles.row}>
        TextInput
        <TextInput
          label="Coupon code"
          additionalLabel="optional"
          isCharacterCounter
          maxLength={150}
          withButton
          value={inputValue}
          onChange={setInputValue}
          buttonComponent={
            <TextButton startIcon={<OpenLinkIcon />} onClick={() => {}}>
              With button
            </TextButton>
          }
        />
        <TextInput
          label="Coupon code"
          additionalLabel="optional"
          error={{ message: "Error" }}
          value={inputValue}
          onChange={setInputValue}
        />
        <TextInput error={{ message: "Error" }} value={inputValue} onChange={setInputValue} />{" "}
        <TextInput
          error={{ message: "Error" }}
          value={inputValue}
          onChange={setInputValue}
          disabled
        />
        TextArea
        <TextArea
          label="Additional comments"
          additionalLabel="optional"
          isCharacterCounter
          value={inputValue}
          onChange={setInputValue}
          rows={3}
          maxCharactersCount={350}
        />
        <TextArea
          label="Additional comments"
          additionalLabel="optional"
          isCharacterCounter
          value={inputValue}
          onChange={setInputValue}
          rows={3}
          maxCharactersCount={350}
          error={{ message: "Error" }}
          withButton={true}
          buttonComponent={
            <TextButton startIcon={<OpenLinkIcon />} onClick={() => {}}>
              With button
            </TextButton>
          }
        />
        <TextArea isCharacterCounter value={inputValue} onChange={setInputValue} disabled />
        <PhoneInput label="Mobile number" value={phoneValue} onChange={setPhoneValue} />
        <PhoneInput
          label="With button"
          value={phoneValue}
          onChange={setPhoneValue}
          withButton={true}
          buttonComponent={
            <TextButton startIcon={<OpenLinkIcon />} onClick={() => {}}>
              With button
            </TextButton>
          }
        />
        <PhoneInput value={phoneValue} onChange={setPhoneValue} error={{ message: "Error" }} />
        <PhoneInput label="Disabled" value={phoneValue} onChange={setPhoneValue} disabled={true} />
        <Select options={sortedYears} isLoaded={true} onChange={setSelected} value={selected} />
        <Select options={sortedYears} onChange={setSelected} />
        <Select
          options={sortedYears}
          tooltipText="text"
          isDisabled={true}
          onChange={setSelected}
          isLoaded={true}
        />
        <Checkbox
          label="Checkbox"
          value={isChecked}
          isChecked={isChecked}
          onChange={(e) => {
            onCheck(e.target.checked);
          }}
        />
      </div>
      <div>
        <Typography className="mb-20" fontWeight="bold" fontSize="xl" tag="div">
          Typography
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="md" tag="div">
          Primary color
        </Typography>
        <Typography className="mr-20" color="secondary" fontWeight="bold" fontSize="md" tag="div">
          Secondary color
        </Typography>
        <Typography className="mr-20" color="tertiary" fontWeight="bold" fontSize="md" tag="div">
          Tertiary color
        </Typography>
        <Typography className="mr-20" color="quaternary" fontWeight="bold" fontSize="md" tag="div">
          Quaternary color
        </Typography>
        <Typography className="mr-20" color="warning" fontWeight="bold" fontSize="md" tag="div">
          Warning color
        </Typography>
        <Typography className="mr-20" color="error" fontWeight="bold" fontSize="md" tag="div">
          Error color
        </Typography>
        <Typography className="mr-20" color="link" fontWeight="bold" fontSize="md" tag="div">
          Link color
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xs" tag="div">
          xs
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="sm" tag="div">
          sm
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="md" tag="div">
          md
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="lg" tag="div">
          lg
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl" tag="div">
          xl
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl-2" tag="div">
          xl-2
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl-3" tag="div">
          xl-3
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl-4" tag="div">
          xl-4
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl-5" tag="div">
          xl-5
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl-6" tag="div">
          xl-6
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="xl-7" tag="div">
          xl-7
        </Typography>

        <Typography className="mr-20" color="primary" fontWeight="normal" fontSize="md" tag="div">
          Font Weight Normal
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="medium" fontSize="md" tag="div">
          Font Weight Medium
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="semibold" fontSize="md" tag="div">
          Font Weight Semibold
        </Typography>
        <Typography className="mr-20" color="primary" fontWeight="bold" fontSize="md" tag="div">
          Font Weight Bold
        </Typography>
      </div>
      <div>
        <Label label="label" additionalLabel="additional Label" />
      </div>
      <div className={styles.row}>
        <Collapse
          title="Collapse"
          onClick={() => onOpenedToggle(!isOpenedToggle)}
          isOpened={isOpenedToggle}
        />
      </div>
      <div className={styles.row}>
        Spinner
        <Spinner />
        Loader
        <Loader />
      </div>
    </div>
  );
};

export default ComponentsRepresentation;
