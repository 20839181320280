// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConsentCheckbox__consentCheckbox--6qexk{display:flex;align-items:flex-start;gap:8px}.ConsentCheckbox__link--I88Gq{all:unset;cursor:pointer}.ConsentCheckbox__dividerLine--3zqzy{background:#a4adbb;display:inline-block;height:14px;width:1px;margin:0 5px -2px}.ConsentCheckbox__disclaimer--aXLRM{font-family:Lato,sans-serif;line-height:20px;font-size:14px;color:#707d8f;white-space:pre-line}", "",{"version":3,"sources":["webpack://./src/templates/ConsentCheckbox/ConsentCheckbox.scss"],"names":[],"mappings":"AAAA,yCACE,YAAA,CACA,sBAAA,CACA,OAAA,CAGF,8BACE,SAAA,CACA,cAAA,CAGF,qCACE,kBAAA,CACA,oBAAA,CACA,WAAA,CACA,SAAA,CACA,iBAAA,CAGF,oCACE,2BAAA,CACA,gBAAA,CACA,cAAA,CACA,aAAA,CACA,oBAAA","sourcesContent":[".consentCheckbox {\n  display: flex;\n  align-items: flex-start;\n  gap: 8px;\n}\n\n.link {\n  all: unset;\n  cursor: pointer;\n}\n\n.dividerLine {\n  background: #a4adbb;\n  display: inline-block;\n  height: 14px;\n  width: 1px;\n  margin: 0 5px -2px;\n}\n\n.disclaimer {\n  font-family: Lato, sans-serif;\n  line-height: 20px;\n  font-size: 14px;\n  color: #707D8F;\n  white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consentCheckbox": "ConsentCheckbox__consentCheckbox--6qexk",
	"link": "ConsentCheckbox__link--I88Gq",
	"dividerLine": "ConsentCheckbox__dividerLine--3zqzy",
	"disclaimer": "ConsentCheckbox__disclaimer--aXLRM"
};
export default ___CSS_LOADER_EXPORT___;
