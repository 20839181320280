import React from "react";
import cn from "classnames";
import { CrossIcon } from "../../svg-icons";
import styles from "./ClosePopupButton.scss";
import { CUSTOM_EVENTS, registerCustomMawEvent } from "../../services/analytics.service";
import { PROP_TYPES_TEMPLATES } from "../../constants";

const ClosePopupButton = ({ className }) => {
  const handleCloseModal = () => {
    registerCustomMawEvent(CUSTOM_EVENTS.hide_widget);
  };

  return (
    <div className={cn(styles.crossWrapper, className)} onClick={handleCloseModal}>
      <CrossIcon />
    </div>
  );
};

ClosePopupButton.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default ClosePopupButton;
