const AppSwitch = (props) => {
  const { test, children } = props;

  // if only one child is passed to AppSwitch children will be single node instead of array
  let options = Array.isArray(children) ? children : [children];

  const matchedCase = options.find((child) => child.props.value === test);

  if (matchedCase) return matchedCase;

  const defaultCase = options.find((child) => child.props.default);
  if (defaultCase) return defaultCase;

  return null;
};

export default AppSwitch;
