import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

import { PROP_TYPES_TEMPLATES } from "../../../constants";
import useShowInputError from "../../../hooks/useShowInputError";
import InputError from "../InputError/InputError";
import InputWrapper from "../InputWrapper";
import styles from "./TextArea.scss";

const TextArea = ({
  children,
  className,
  fieldClasses,
  rows,
  value,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onClick = () => {},
  placeholder,
  disabled,
  error,
  label,
  additionalLabel,
  withButton,
  maxCharactersCount = 2000,
  isCharacterCounter,
  buttonComponent,
  withInformationBadge,
  informationBadgeComponent,
  characterCounterClassName,
  isGrayTheme = false,
}) => {
  const { isShowError, isShowTooltip, setIsShowError, validationErrorMessage, serverErrorMessage } =
    useShowInputError(error);

  const handleChange = (value) => {
    if (value.length <= maxCharactersCount) {
      onChange(value);
    }
  };

  const handleFocus = () => {
    setIsShowError(false);
    onFocus();
  };

  const handleBlur = () => {
    if (value) {
      onChange(value.trim());
    }
    setIsShowError(true);
    onBlur();
  };

  return (
    <InputWrapper
      className={className}
      label={label}
      disabled={disabled}
      additionalLabel={additionalLabel}
      withButton={withButton}
      isCharacterCounter={isCharacterCounter}
      buttonComponent={buttonComponent}
      withInformationBadge={withInformationBadge}
      informationBadgeComponent={informationBadgeComponent}
      maxCharactersCount={maxCharactersCount}
      characterCounterClassName={characterCounterClassName}
      value={value}
    >
      <div className={cn(styles.textField, fieldClasses)}>
        <textarea
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={onClick}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(styles.textarea, {
            [styles.grayTheme]: isGrayTheme,
            [styles.isError]: serverErrorMessage || (isShowError && validationErrorMessage),
            [styles.isDisabled]: disabled,
          })}
          rows={rows}
        />
        {children}
      </div>

      <InputError
        isOpen={isShowTooltip}
        errorMessage={serverErrorMessage || validationErrorMessage}
        className={styles.error}
      />
    </InputWrapper>
  );
};

TextArea.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  additionalLabel: PROP_TYPES_TEMPLATES.LABEL,
  error: PROP_TYPES_TEMPLATES.ERROR,
  serverError: PROP_TYPES_TEMPLATES.ERROR,
  value: PropTypes.string,
  maxCharactersCount: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isCharacterCounter: PropTypes.bool,
  rows: PropTypes.number,
  withButton: PropTypes.bool,
  isGrayTheme: PropTypes.bool,
  buttonComponent: PROP_TYPES_TEMPLATES.COMPONENT,
  withInformationBadge: PropTypes.bool,
  informationBadgeComponent: PROP_TYPES_TEMPLATES.COMPONENT,
  onChange: PropTypes.func.isRequired,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
};

export default TextArea;
