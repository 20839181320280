import PropTypes from "prop-types";
import { getIsPlazaTire } from "../../../../services/config.service";
import styles from "./WaitingTypeSection.scss";
import { AppointmentType } from "../../../../constants";
import Typography from "../../../../components/Typography/Typography";
import ToggleButton from "../../../../components/ToggleButton/ToggleButton";

const WaitingTypeSection = ({ type, onChange }) => {
  const isPlazaTire = getIsPlazaTire();
  const headerTitle = isPlazaTire
    ? "Do You Want To Schedule a Tire Purchase or Another Service?"
    : "Do You Wish to Wait During the Repair or Drop Off Your Vehicle?";

  return (
    <div className={styles.dropOffContainer}>
      <Typography fontSize="xl" fontWeight="bold">
        {headerTitle}
      </Typography>

      {isPlazaTire ? (
        <div className={styles.buttons}>
          <ToggleButton
            className={styles.typeButton}
            title={"Tire Purchase"}
            isSelected={type === AppointmentType.Dropoff}
            onClick={() => onChange(AppointmentType.Dropoff)}
          />
          <ToggleButton
            className={styles.typeButton}
            title={"Other Services"}
            isSelected={type === AppointmentType.Waiting}
            onClick={() => onChange(AppointmentType.Waiting)}
          />
        </div>
      ) : (
        <div className={styles.buttons}>
          <ToggleButton
            className={styles.typeButton}
            title={"I Will Wait for My Vehicle"}
            isSelected={type === AppointmentType.Waiting}
            onClick={() => onChange(AppointmentType.Waiting)}
          />
          <ToggleButton
            className={styles.typeButton}
            title={"I Will Drop Off My Vehicle"}
            isSelected={type === AppointmentType.Dropoff}
            onClick={() => onChange(AppointmentType.Dropoff)}
          />
        </div>
      )}
    </div>
  );
};

WaitingTypeSection.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
};

export default WaitingTypeSection;
