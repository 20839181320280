import React, { useState } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import style from "./Select.scss";
import { ArrowIcon, LoadingIcon } from "../../svg-icons";
import { PROP_TYPES_TEMPLATES } from "../../constants";
import PropTypes from "prop-types";

export default function Select({
  label,
  placeholder,
  options,
  value,
  onChange,
  isLoaded,
  isDisabled,
  tooltipText,
  className,
  isGrayTheme = false,
}) {
  const [isOpened, setIsOpened] = useState(false);
  function toggleOpen() {
    isOpened ? setIsOpened(false) : setIsOpened(true);
  }

  const getPlaceholder = () => {
    if (isLoaded && !isDisabled) {
      return placeholder;
    }
    if (isDisabled) {
      return " ";
    }
    return "Loading...";
  };

  return (
    <div className={classNames(style.selectGroup, className)}>
      <div className={style.label}>{label}</div>
      <div className={cn(style.selectWrapper, { [style.wrapperDisabled]: isDisabled })}>
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsOpened(false);
          }}
        >
          <input
            value={value || ""}
            onChange={() => {}}
            autoComplete="off"
            readOnly={true}
            placeholder={getPlaceholder()}
            data-tip={tooltipText}
            data-tip-disable={!isDisabled}
            className={classNames(style.select, {
              [style.focused]: isOpened,
              [style.loading]: !isLoaded,
              [style.disabled]: isDisabled,
              [style.grayTheme]: isGrayTheme,
            })}
            onClick={() => !isDisabled && toggleOpen()}
          />
        </OutsideClickHandler>
        <ReactTooltip
          className={style.customTooltip}
          backgroundColor="#3E4150"
          effect="float"
          place="top"
        />
        {isLoaded && (
          <ArrowIcon
            className={cn(style.arrowIcon, {
              [style.arrowIconRotated]: isOpened,
            })}
          />
        )}
        {!isLoaded && <LoadingIcon className={style.loadingIcon} />}
        <div className={isOpened ? style.options : style.hidden}>
          {options.map((opt) => (
            <div
              className={style.option}
              key={opt.value}
              data-value={opt.value}
              onClick={() => {
                onChange(opt);
                setIsOpened(false);
              }}
            >
              <div>{opt.label}</div>
              <div>{opt.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Select.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isGrayTheme: PropTypes.bool,
  tooltipText: PROP_TYPES_TEMPLATES.COMPONENT,
  onChange: PropTypes.func.isRequired,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};
