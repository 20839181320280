import styles from "./Skeletons.module.scss";
import { RectShape } from "react-placeholder/lib/placeholders";
import cn from "classnames";
import { divIcon } from "leaflet";

function CalendarSkeleton() {
  return (
    <RectShape className={cn(styles.basic, styles.calendar)} style={{ width: 289, height: 260 }} />
  );
}

const BlockRepeat = ({ count, width, height, className, marginRight }) => {
  let blocks = [];
  for (let i = 0; i < count; i++) {
    blocks.push(
      <RectShape
        className={cn(styles.basic, className)}
        key={i}
        style={{ width: width, height: height, marginRight: marginRight }}
      />,
    );
  }
  return blocks;
};

function TimeSlotsSkeleton() {
  return (
    <div className={styles.timeslots}>
      <BlockRepeat count={8} width={94} height={45} className={styles.slot} />
    </div>
  );
}

function ButtonSkeleton() {
  return <RectShape style={{ width: 62, height: 12 }} className={cn(styles.basic)} />;
}

function LocationSkeleton() {
  let blocks = [];
  for (let i = 0; i < 3; i++) {
    blocks.push(<RectShape className={cn(styles.basic, styles.location)} key={i} />);
  }
  return <div className={styles.locations}>{blocks}</div>;
}

function TitleLocationsSkeleton() {
  return (
    <RectShape
      className={cn(styles.basic, styles.locationsTitle)}
      style={{ width: 137, height: 22 }}
    />
  );
}

function SearchLocationsSkeleton() {
  return <RectShape className={cn(styles.basic, styles.locationsSearch)} />;
}

function SearchButtonSkeleton() {
  return (
    <RectShape
      className={cn(styles.basic, styles.searchButton)}
      style={{ width: 16, height: 16, borderRadius: 8, marginRight: 0 }}
    />
  );
}

function TitleReviewsSkeleton() {
  return (
    <RectShape
      className={cn(styles.basic, styles.reviewsTitle)}
      style={{ width: 158, height: 22 }}
    />
  );
}

function ChangeLocationSkeleton() {
  return (
    <RectShape
      className={cn(styles.basic, styles.reviewsTitle)}
      style={{ width: 122, height: 17, marginRight: 0 }}
    />
  );
}

function TotalRatingSkeleton() {
  return (
    <RectShape
      className={cn(styles.basic, styles.reviewsTotal)}
      style={{ width: 180, height: 38 }}
    />
  );
}

function ReviewsNumTextSkeleton() {
  return (
    <RectShape className={cn(styles.basic, styles.numText)} style={{ width: 217, height: 16 }} />
  );
}

function ReviewLineSkeleton({ width }) {
  return (
    <div className={styles.reviewLine}>
      <RectShape
        className={cn(styles.basic)}
        style={{ width: width, height: 16, borderRadius: 19 }}
      />
      <div className={styles.rightPart}>
        <RectShape
          className={cn(styles.basic, styles.ratingFilledLine)}
          style={{ height: 6, borderRadius: 11, marginRight: 15 }}
        />
        <RectShape
          className={cn(styles.basic)}
          style={{ width: 24, height: 18, marginRight: 0, borderRadius: 9 }}
        />
      </div>
    </div>
  );
}

function ReviewLinesSkeleton() {
  const titleWidth = [55, 34, 52, 93, 29];
  const lines = [];
  for (let i = 0; i < titleWidth.length; i++) {
    lines.push(<ReviewLineSkeleton width={titleWidth[i]} key={i} />);
  }

  return lines;
}

function ReviewSkeleton() {
  return (
    <div className={styles.reviewItem}>
      <div className={styles.reviewHeader}>
        <div className={styles.headerHalf}>
          <RectShape
            className={cn(styles.basic)}
            style={{
              width: 35,
              height: 35,
              borderRadius: 17.5,
              marginRight: 20,
            }}
          />
          <div className={styles.headerText}>
            <RectShape
              className={cn(styles.basic)}
              style={{
                width: 122,
                height: 15,
                borderRadius: 11,
                marginBottom: 10,
              }}
            />
            <RectShape
              className={cn(styles.basic)}
              style={{ width: 68, height: 10, borderRadius: 11 }}
            />
          </div>
        </div>
        <div className={styles.headerHalf}>
          <RectShape
            className={cn(styles.basic)}
            style={{
              width: 122,
              height: 20,
              borderRadius: 11,
              marginRight: 20,
            }}
          />
          <RectShape
            className={cn(styles.basic)}
            style={{
              width: 30,
              height: 30,
              borderRadius: 15,
              marginRight: 0,
            }}
          />
        </div>
      </div>
      <div className={styles.reviewBody}>
        <RectShape className={cn(styles.basic, styles.bodyLine)} />
        <RectShape className={cn(styles.basic, styles.bodyLine)} />
        <RectShape className={cn(styles.basic, styles.bodyLine)} />
      </div>
    </div>
  );
}

function ReviewsSkeleton() {
  let times = 3;
  const reviews = [];
  for (let i = 0; i < times; i++) {
    reviews.push(<ReviewSkeleton key={i} />);
  }

  return reviews;
}

function ServicesSkeleton() {
  return (
    <div className={styles.servicesContainer}>
      <RectShape
        className={cn(styles.basic, styles.servicesTitle)}
        style={{ width: 160, height: 24 }}
      />
      <div className={styles.services}>
        <BlockRepeat count={8} className={styles.service} />
      </div>
    </div>
  );
}

function VehiclesSkeleton() {
  return (
    <div className={styles.vehiclesWrapper}>
      {new Array(6).fill("").map((_, index) => {
        return (
          <div className={styles.vehiclesContainer} key={index}>
            <div className={styles.vehicleAvatar}></div>
            <div className={styles.vehiclesLinesContainer}>
              <RectShape
                className={cn(styles.vehicleLine)}
                style={{ width: "100%", maxWidth: 250, height: 12 }}
              />
              <RectShape className={cn(styles.vehicleLine)} style={{ width: 170, height: 12 }} />
              <RectShape className={cn(styles.vehicleLine)} style={{ width: 114, height: 12 }} />
              <RectShape className={cn(styles.vehicleLine)} style={{ width: 138, height: 12 }} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function ProfileSkeleton() {
  return (
    <div className={styles.vehiclesWrapper}>
      <div className={styles.vehiclesLinesContainer}>
        <RectShape
          className={cn(styles.vehicleLine)}
          style={{ width: "100%", maxWidth: 100, height: 12 }}
        />
        <RectShape className={cn(styles.vehicleLine)} style={{ width: 170, height: 12 }} />
        <RectShape className={cn(styles.vehicleLine)} style={{ width: 114, height: 12 }} />
        <RectShape className={cn(styles.vehicleLine)} style={{ width: 138, height: 12 }} />
      </div>
    </div>
  );
}

export {
  TimeSlotsSkeleton,
  CalendarSkeleton,
  ButtonSkeleton,
  LocationSkeleton,
  TitleLocationsSkeleton,
  SearchLocationsSkeleton,
  SearchButtonSkeleton,
  TitleReviewsSkeleton,
  ChangeLocationSkeleton,
  TotalRatingSkeleton,
  ReviewsNumTextSkeleton,
  ReviewLinesSkeleton,
  ReviewsSkeleton,
  ServicesSkeleton,
  VehiclesSkeleton,
  ProfileSkeleton,
};
