import styles from "./LocationScene.scss";
import React, { useState, useEffect, useContext } from "react";

import "leaflet/dist/leaflet.css";
import cn from "classnames";
import { getWidgetInPopup, setEnabledTwoFactorAuthentication } from "../../services/config.service";
import ShopList from "./components/ShopList/ShopList";
import Map from "./components/Map/Map";
import PropTypes from "prop-types";
import {
  changeShopId,
  getAllShopLocations,
  getShopDetails,
  saveLookupCustomer,
  saveSelectedCustomer,
  setVehicle,
  setVerificationMethod,
  useRedux,
} from "../../redux";
import { useScreenSize } from "../../hooks/useScreenSize";
import { ListViewIcon, MapViewIcon } from "../../svg-icons";
import { WizardContext } from "../Wizard/Wizard";
import { fetchWidgetSettingsByShopId } from "../../services/mechanic-api.client";
import { STEP_IDS } from "../Wizard/utils/stepsGenerator";

const LocationScene = ({ store, msoLocations, onSelectMsoShop }) => {
  const { updateCurrentWizardStep, activeStep, updateWizardStepById } = useContext(WizardContext);
  const [state, dispatch] = useRedux(store);
  const { isMobile } = useScreenSize();
  const locationsList = msoLocations || getAllShopLocations(state);

  const isWidgetPopup = getWidgetInPopup();
  const [map, setMap] = useState(null);
  const [url, setUrl] = useState(null);
  const [hoveredShop, setHoveredShop] = useState(null);
  const [focusedShop, setFocusedShop] = useState(null);
  const [selectedShopId, setSelectedShopId] = useState(null);
  const { isMobileListView } = activeStep;

  const selectedLocation = locationsList.find((l) => l.shopId === selectedShopId);
  const isMapShown = (url && !isMobile) || (url && !isMobileListView && isMobile);

  useEffect(() => {
    updateCurrentWizardStep({
      showBackButton: isMobile,
    });
  }, [isMobile]);

  useEffect(() => {
    if (activeStep?.backButtonClickListener) {
      updateCurrentWizardStep({
        isMobileListView: !isMobileListView,
        backButtonLabel: isMobileListView ? "List View" : "Map View",
        backButtonIcon: isMobileListView ? ListViewIcon : MapViewIcon,
      });
    }
  }, [activeStep?.backButtonClickListener]);

  useEffect(() => {
    const { name, address = "", city = "", state = "", zip = "" } = selectedLocation || {};
    updateCurrentWizardStep({
      footerSummary: selectedShopId
        ? `   ${name}, ${address}
                ${address ? "," : ""} ${city}
                ${city && (state || zip) ? "," : ""} ${state} ${zip}`
        : "Please select a location to continue.",
      isValid: !!selectedShopId,
    });
  }, [selectedShopId]);

  // setting stadia map url or fallback url with free map if stadia is not available
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          "https://tiles.stadiamaps.com/tiles/osm_bright/12/1240/1517.png",
        );
        if (response.ok) {
          setUrl(`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png`);
        } else {
          setUrl(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`);
        }
      } catch (error) {
        setUrl(`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`);
      }
    })();
  }, []);

  useEffect(() => {
    if (isMobile && map) {
      setMap(null);
    }
  }, [isMobile, isMobileListView]);

  const handleOnSetView = (latitude, longitude) => {
    if (map) {
      map.flyTo([latitude, longitude], 14, {
        duration: 2,
      });
    }
  };

  const handleShopHover = (shopId) => {
    setHoveredShop(shopId);
  };

  const handleShopFocus = (shopId) => {
    setFocusedShop(shopId);
  };

  const updateVerificationSettings = async (shopId) => {
    const shopSettings = await fetchWidgetSettingsByShopId(shopId);
    const { enableTwoFactorAuthentication } = shopSettings.data?.settings;
    setEnabledTwoFactorAuthentication(enableTwoFactorAuthentication);

    // Reset previous 2fa state
    dispatch(setVerificationMethod(""));
    dispatch(saveLookupCustomer({}));
    dispatch(saveSelectedCustomer({}));
    dispatch(setVehicle({ year: null, make: null, model: null }));

    updateWizardStepById(STEP_IDS.VERIFICATION, {
      shouldBeIgnored: !enableTwoFactorAuthentication,
      activeSubStepIndex: 0,
    });
    updateWizardStepById(STEP_IDS.VEHICLE_SELECT, {
      shouldBeIgnored: !enableTwoFactorAuthentication,
    });
  };

  const handleSelectLocation = (shopId) => {
    if (shopId !== selectedShopId) {
      dispatch(changeShopId(shopId));
      dispatch(getShopDetails());
      setSelectedShopId(shopId);
      map?.closePopup();

      onSelectMsoShop && onSelectMsoShop(shopId);
      updateVerificationSettings(shopId);
    }
  };

  const handleClickMapLocation = (id) => {
    handleSelectLocation(id);
    const element = document.getElementById(`shop-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div
      className={cn(styles.locations, {
        [styles.popupLocations]: isWidgetPopup,
      })}
    >
      <div
        className={cn(styles.locationContainer, {
          [styles.popupLocationContainer]: isWidgetPopup,
        })}
      >
        <div
          className={cn(styles.leftSide, {
            [styles.mobileList]: isMobileListView,
            [styles.leftSideMapView]: !isMobileListView && isMobile,
          })}
        >
          <ShopList
            shops={locationsList}
            searchQuery={activeStep.searchQuery}
            onViewLocation={handleOnSetView}
            onSelectLocation={handleSelectLocation}
            onShopHover={handleShopHover}
            onShopFocus={handleShopFocus}
            isMobileListView={isMobileListView}
            selectedShopId={selectedShopId}
          />
        </div>

        {isMapShown && (
          <Map
            markers={locationsList}
            onClick={handleClickMapLocation}
            whenCreated={setMap}
            map={map}
            url={url}
            hoveredShop={hoveredShop}
            focusedShop={focusedShop}
          />
        )}
      </div>
    </div>
  );
};

LocationScene.propTypes = {
  store: PropTypes.object,
  activeStep: PropTypes.object,
  msoLocations: PropTypes.array,
  updateCurrentWizardStep: PropTypes.func,
  onSelectMsoShop: PropTypes.func,
};

export default LocationScene;
